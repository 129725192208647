import {User} from "../../../../primitives/icons";
import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function MarketingNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavGroup title="Usuarios" route="/users" icon={<User />}>
				<NavLink to="/users/admins" label="Influencers" />
			</NavGroup>
		</div>
	);
}
