import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface Content {
	id: string;
	serieid: string;
	serie_title: string;
	title: string;
	slugs: string;
	order: number;
	ranking: number;
	new: boolean;
	published: boolean;
	membership_type: number;
	content_type: number;
	short_description: string;
	long_description: string;
	video_id: string;
	duration: string;
	likes: number;
	favorite: number;
	lowres_image: string;
	square_image: string;
	rectangular_image: string;
	audit_created: string;
	audit_modified: string;
	release_date: string | null;
	whitelist_release_date: string | null;
	estreno: boolean;
	premiere_type: number;
	display_release_date: boolean;
}
export interface GetContentsParams {
	page_size?: number;
	page?: number;
	searchby?: undefined | "title" | "short_description" | "long_description" | "id" | "serieid" | "content_title";
	searchvalue?: string;
	published?: boolean;
	estreno?: boolean;
	new?: boolean;
	content_type?: string;
}

export interface GetContentsResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Content[];
}

export async function getSerieContent(token: string, params?: GetContentsParams): Promise<AxiosResponse<GetContentsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_content_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertContentSchema = z.object(
	{
		seriesid: z.string().nonempty(),
		title: z.string().nonempty("El titulo es requerido"),
		order: z.number(),
		estreno: z.boolean(),
		new: z.boolean(),
		published: z.boolean(),
		membership_type: z.number(),
		content_type: z.number(),
		short_description: z.string(),
		long_description: z.string(),
		video_id: z.string(),
		duration: z.string(),
		lowres_image: z.string(),
		square_image: z.string(),
		rectangular_image: z.string(),
		release_date: z.string().nullable(),
		whitelist_release_date: z.string().nullable(),
		premiere_type: z.number(),
		slugs: z
			.string()
			.nonempty("El slug es requerido")
			.refine(
				val => {
					const slugs = val.split(",");
					const slugsSet = new Set(slugs);
					return slugsSet.size === slugs.length;
				},
				{message: "No puedes repetir slug"},
			),
		display_release_date: z.boolean(),
	},
	{required_error: "Este campo es requerido."},
);
export type InsertContentType = z.infer<typeof InsertContentSchema>;

export async function insertContent(token: string, data: InsertContentType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_content_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadContentImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_content_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}

export async function editContent(token: string, data: InsertContentType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_content_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface OrderContentType {
	ordered_data: {
		id: string;
		order: number;
	}[];
}

export async function orderContent(token: string, data: OrderContentType) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.put("/order_content_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function deleteContent(token: string, data: {contentid: string}) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_content_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export interface StopLiveParams {
	contentId: string;
}

export async function stopLiveStream(token: string, data: StopLiveParams) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_disable_live_stream", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
