import {useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {cn} from "../../../../utils/classNames";
import {adminCreateUserData} from "../../../_store/features/user-db/user-db-actions";
import {useAppDispatch} from "../../../_store/hooks";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {Label} from "../../../components/primitives/Label";
import {Switch} from "../../../components/primitives/Switch";
import {Toggle} from "../../../components/primitives/Toggle";
import {Eye, EyeVisible} from "../../../components/primitives/icons";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {NewUserBodySchema, NewUserBodyType, ROLES} from "../../../data-access/fetch-db-users";
import {RolePickerCombobox} from "./subcomponents/RolePickerCombobox";
import {Controller, SubmitHandler, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import RoleDescription from "./subcomponents/RoleDescription";
import {useNavigate} from "react-router-dom";
import {Checkbox} from "../../../components/primitives/Checkbox";
import useCurrentUserRole from "../../../components/hooks/useCurrentUserRole";

const rolesInitialState = {
	admin: false,
	master_editor: false,
	corporate: false,
	editor: false,
	partner: false,
	sponsor: false,
	influencer: false,
	customer_service: false,
	marketing: false,
};
const initialState: NewUserBodyType = {
	email: "",
	email_verified: false,
	password: "",
	disabled: false,
	name: "",
	phone: "",
	phone_verified: false,
	coin: 0,
	associate: false,
	...rolesInitialState,
};

function UsersNew() {
	const currentRole = useCurrentUserRole();
	const [passwordVisible, setPasswordVisible] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<NewUserBodyType>({
		resolver: zodResolver(NewUserBodySchema),
		defaultValues: initialState,
	});
	const formData = useWatch({control});

	const activeRole = useMemo(() => {
		const {admin, master_editor, editor, partner, influencer, sponsor, customer_service, marketing, corporate} = formData;

		const currentRole = Object.entries({admin, master_editor, editor, partner, influencer, sponsor, customer_service, marketing, corporate}).find(
			([key, value]) => value,
		) as [ROLES, boolean];

		if (currentRole) {
			return currentRole[0];
		}

		return null;
	}, [formData]);

	const onSubmit: SubmitHandler<NewUserBodyType> = data => {
		return dispatch(adminCreateUserData(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Usuario Creado Correctamente");
				reset();
				navigate(-1);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	useEffect(() => {
		if (currentRole === "marketing") {
			setValue("influencer", true);
		}
	}, [currentRole, setValue]);

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Usuario</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear usuarios y asignar roles.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar nombre de usuario..." {...register("name")} />
									{errors?.name?.message && <span className="text-sm font-medium text-destructive">{errors?.name?.message}</span>}
									<div className="mt-2 flex items-center space-x-2">
										<Controller
											name="disabled"
											control={control}
											render={({field}) => (
												<>
													<Switch
														id="user-disabled-toggle"
														checked={!field.value}
														onCheckedChange={c => field.onChange(!c)}
													/>
													<Label
														htmlFor="user-disabled-toggle"
														className={cn(field.value ? "text-red-600" : "text-green-800")}
													>
														{field.value ? "Usuario Desactivado" : "Usuario Activado"}
													</Label>
												</>
											)}
										/>
									</div>
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Correo Electrónico<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="email" placeholder="Insertar correo electrónico..." {...register("email")} />
									{errors?.email?.message && <span className="text-sm font-medium text-destructive">{errors?.email.message}</span>}
									<div className="mt-2 flex items-center space-x-2">
										<Controller
											name="email_verified"
											control={control}
											render={({field}) => (
												<>
													<Switch
														id="user-email-verified-toggle"
														checked={field.value}
														onCheckedChange={c => field.onChange(c)}
													/>
													<Label
														htmlFor="user-email-verified-toggle"
														className={cn(!field.value ? "text-red-600" : "text-green-800")}
													>
														{!field.value ? "No Verificado" : "Verificado"}
													</Label>
												</>
											)}
										/>
									</div>
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Contraseña<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="flex space-x-2">
										<Input
											type={passwordVisible ? "text" : "password"}
											placeholder="Insertar contraseña..."
											{...register("password")}
										/>
										<Toggle
											variant="outline"
											aria-label="Toggle italic"
											pressed={passwordVisible}
											onPressedChange={p => setPasswordVisible(p)}
										>
											{passwordVisible ? <Eye className="h-4 w-4" /> : <EyeVisible className="h-4 w-4" />}
										</Toggle>
									</div>
									{errors?.password?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.password.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Teléfono<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="tel" placeholder="Insertar numero de teléfono +123456789..." {...register("phone")} />
									{errors?.phone?.message && <span className="text-sm font-medium text-destructive">{errors?.phone.message}</span>}
									<div className="mt-2 flex items-center space-x-2">
										<Controller
											name="phone_verified"
											control={control}
											render={({field}) => (
												<>
													<Switch
														id="user-phone-verified-toggle"
														checked={field.value}
														onCheckedChange={c => field.onChange(c)}
													/>
													<Label
														htmlFor="user-phone-verified-toggle"
														className={cn(!field.value ? "text-red-600" : "text-green-800")}
													>
														{!field.value ? "No Verificado" : "Verificado"}
													</Label>
												</>
											)}
										/>
									</div>
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Coins</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="number" placeholder="Coins" min={0} {...register("coin", {valueAsNumber: true})} />
									{errors?.coin?.message && <span className="text-sm font-medium text-destructive">{errors?.coin.message}</span>}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Rol</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<RolePickerCombobox
										onChangeSelected={role => {
											const newRoles = {
												admin: false,
												master_editor: false,
												corporate: false,
												editor: false,
												partner: false,
												sponsor: false,
												influencer: false,
												customer_service: false,
												marketing: false,
											};
											if (!!role?.value && role.value !== "user") {
												newRoles[role?.value] = true;
											}

											Object.entries(newRoles).forEach(([key, value]) => {
												setValue(key as ROLES, value);
											});
										}}
									/>
									<RoleDescription role={activeRole} />
								</dd>
							</div>

							{!activeRole && (
								<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
									<dt className="text-sm font-medium leading-6 text-gray-900">Asociado</dt>
									<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Controller
											name="associate"
											control={control}
											render={({field}) => (
												<Checkbox
													checked={field.value}
													onCheckedChange={v => {
														field.onChange(v);
													}}
												/>
											)}
										/>
										<label className="select-none pl-2 text-gray-900" htmlFor="associated">
											Es un usuario asociado a la plataforma?
										</label>
									</dd>
								</div>
							)}
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate(-1);
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{isLoading || (isSubmitting && <Spinner className="mr-2 h-4 w-4 animate-spin" />)}
							Crear Usuario
						</Button>
					</div>
				</form>
			</div>
		</section>
	);
}

export default UsersNew;
