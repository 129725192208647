import {useEffect, useMemo, useState} from "react";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {getInfluencerConfig, getInfluencerLink} from "../../../../_store/features/user-db/user-db-actions";
import {useParams} from "react-router-dom";
import {GetInfluencerConfigResponse, GetInfluencerLinkResponse} from "../../../../data-access/role/configuration/influencer";
import {Spinner} from "../../../../components/primitives/icons";
import {ViewsTimeSeries} from "../../../../components/blocks/Stats";
import {InfluencerChartIncome} from "./_subcomponents/InfluencerChartIncome";
import Select, {MultiValue} from "react-select";
import {selectGlobalStyles} from "../../../../../utils/selectGlobalStyles";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {GetInfluencerAndPartnerTransactionsResponse} from "../../../../data-access/dashboard/statistics";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {MEMBERSHIP_TYPES} from "../../../../constants";
import {Skeleton} from "../../../../components/primitives/Skeleton";
import {DataTable} from "../../../../components/blocks/DataTable";
import {endOfToday, formatISO9075, roundToNearestMinutes} from "date-fns";
import {DateRange} from "react-day-picker";
import {formattedDayAndHour} from "../../../../../utils/formattedDayAndHour";
import {SelectOptionType} from "../../../../../utils/globalTypes";

const columnHelper = createColumnHelper<GetInfluencerAndPartnerTransactionsResponse>();

function InfluencerDetail() {
	const {id, name} = useParams<{id: string; name: string}>();
	const dispatch = useAppDispatch();
	const series = useAppSelector(({series}) => series.results);
	const [influencerData, setInfluencerData] = useState<GetInfluencerLinkResponse>();
	const [loadingInfluencerData, setLoadingInfluencerData] = useState<boolean>(false);
	const {isLoading: influencerSerieContentLoading} = useDashboardByNameQuery("topSeriesContents");
	const [influencerSeries, setInfluencerSeries] = useState<GetInfluencerConfigResponse>();
	const [seriesidList, setSeriesIdList] = useState<string>("");
	const [listContents, setListContents] = useState<SelectOptionType[]>();
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const {isLoading: tableLoading, data} = useDashboardByNameQuery("latestTransactions");
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [noSelection, setNoSelection] = useState(false);
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = new Date(2023, 0, 1);
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		if (!id) return;
		setLoadingInfluencerData(true);
		dispatch(getInfluencerConfig({searchby: "influencer_id", searchvalue: id})).then(res => {
			const {meta} = res;
			if (meta.requestStatus === "fulfilled") {
				if (!res.payload) return;
				setInfluencerSeries(res.payload as GetInfluencerConfigResponse);
			}
		});
		dispatch(getInfluencerLink({searchby: "influencer_id", searchvalue: id})).then(res => {
			const {meta} = res;
			if (meta.requestStatus === "fulfilled") {
				if (!res.payload) return;
				setInfluencerData(res.payload as GetInfluencerLinkResponse);
			}
			setLoadingInfluencerData(false);
		});
		return () => {
			setLoadingInfluencerData(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id]);

	useEffect(() => {
		if (seriesidList === "") {
			return;
		}
		dispatch(dashboardActions.getTopSeries());
		dispatch(dashboardActions.getTopSeriesContents(seriesidList));
		dispatch(
			dashboardActions.getInfluencerAndPartnerTransactions({
				seriesid_list: seriesidList,
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				influencer_id: id,
			}),
		);
	}, [dispatch, seriesidList, dates, id]);

	useEffect(() => {
		if (influencerSeries?.results) {
			setListContents(
				influencerSeries.results.map(content => {
					const title = series.find(s => s.id === content.serie_id)?.title;
					return {
						label: title || "",
						value: content.serie_id || "",
					};
				}),
			);
			if (seriesidList === "") {
				setSeriesIdList(influencerSeries.results.map(series => series.serie_id).join(","));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [influencerSeries?.results, id, seriesidList, dates]);

	const handleContentChange = (value: MultiValue<SelectOptionType>) => {
		if (value.length === 0) {
			setNoSelection(true);
			setSeriesIdList("");
			return;
		}
		setNoSelection(false);
		setSeriesIdList(value.map(series => series.value).join(","));
	};

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 5);

		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("membership_type", {
				id: "Membresía",
				header: ({column}) => <DataTableColumnHeader title="Membresía" column={column} />,
				cell: info => {
					const membershipValue = info.row.original.membership_type;
					const membership = MEMBERSHIP_TYPES.find(type => type.value === membershipValue);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{membership ? membership.label : ""}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("price", {
				id: "Precio",
				header: ({column}) => <DataTableColumnHeader title="Precio" column={column} />,
				cell: info => {
					const price = (info.row.original.price / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {price}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("title", {
				id: "Título",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => {
					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.title}</span>
						</div>
					);
				},
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	if (loadingInfluencerData)
		return (
			<div className="flex h-screen w-full items-center justify-center">
				<Spinner />
			</div>
		);

	return (
		<div className="flex h-screen flex-col">
			{!influencerData || !influencerSeries ? (
				<div className="flex h-screen items-center justify-center">
					<div className="mx-auto mt-5 flex h-full max-w-lg flex-col items-center justify-center text-center">
						<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
							<InformationCircle />
						</p>
						<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series.</h1>
						<p className="leading-7 [&:not(:first-child)]:mt-6">
							El influencer <b>{name}</b> no pertenece a ninguna serie, por favor contacta con un administrador o espera a ser añadido a
							una.
						</p>
					</div>
				</div>
			) : (
				<>
					<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
						<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Influencer - {name}</h2>
						<Select
							className="basic-multi-select mt-1 rounded"
							options={listContents}
							isMulti={true}
							styles={selectGlobalStyles}
							defaultValue={!loadingInfluencerData ? listContents : []}
							placeholder="Selecciona las series para ver sus estadísticas"
							onChange={handleContentChange}
						/>
					</div>

					{noSelection ? (
						<div className="mx-auto flex h-full max-w-lg flex-col items-center justify-center text-center">
							<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
								<InformationCircle />
							</p>
							<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series seleccionadas.</h1>
							<p className="leading-7 [&:not(:first-child)]:mt-6">Debes seleccionar al menos una serie para ver sus estadísticas.</p>
						</div>
					) : (
						<>
							<div className="flex flex-col">
								<div className="px-8 py-6">
									<InfluencerChartIncome
										currentSeriesId={seriesidList}
										influencerSeries={influencerSeries.results}
										influencerReferralCode={influencerData?.results[0]?.referral_code || ""}
										influencerReferUsersCount={influencerData?.results[0]?.count_users || 0}
										dates={dates}
										setSelectedDate={setSelectedDate}
									/>
								</div>
							</div>
							<div className="mt-4 flex items-center justify-between px-8">
								<h3 className="text-lg font-semibold leading-none tracking-tight">Membresías registradas: {data?.length}</h3>
							</div>
							{tableLoading ? (
								<Skeleton className="mx-7 my-7 h-[400px] bg-slate-300" />
							) : (
								<div className="flex grow-0 flex-col  p-8 pt-4">
									<DataTable
										ref={ref => setTableRef(ref)}
										columns={columns}
										dataset={data!}
										pageCount={Math.round((data?.length ?? 0) / pagination.pageSize)}
										pagination={pagination}
										onPaginationChange={setPagination}
										manualPagination={false}
										showPagination={false}
									/>
								</div>
							)}
							<div className="mb-4">
								<ViewsTimeSeries
									serieid={seriesidList}
									loading={influencerSerieContentLoading}
									title="Vistas de las series"
									codeReferral={influencerData?.results[0]?.referral_code || ""}
								/>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

export default InfluencerDetail;
