import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	adminGlobalNotificationData,
	Notification,
	InsertGlobalNotificationBody,
	InsertUserNotificationBody,
	adminUserNotificationData,
} from "../../../data-access/notify";

export interface notificationsInterface {
	loading: boolean;
	results: Notification[];
	success: boolean;
	error: string;
}

const initialState: notificationsInterface = {
	loading: false,
	results: [],
	success: false,
	error: "",
};

const globalNotifications = createAsyncThunk("notifications/global", async (data: InsertGlobalNotificationBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminGlobalNotificationData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem sending notification, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const userNotifications = createAsyncThunk("notifications/user", async (data: InsertUserNotificationBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await adminUserNotificationData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem sending notification, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(globalNotifications.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(globalNotifications.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(globalNotifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(userNotifications.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(userNotifications.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(userNotifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});

export const notificationsActions = {
	...notificationsSlice.actions,
	globalNotifications,
	userNotifications,
};

export default notificationsSlice;
