import {Skeleton} from "../../../primitives/Skeleton";
import {TooltipProvider, Tooltip, TooltipContent, TooltipTrigger} from "../../../primitives/ToolTip";
import {DollarSign, QuestionMarkCircle} from "../../../primitives/icons";

interface CardProps {
	title: string;
	amount?: number;
	tooltip?: string;
	loading?: boolean;
	isMoney?: boolean;
}
export const Card = ({title, amount, tooltip, loading, isMoney = true}: CardProps) => {
	return (
		<div className="flex flex-col justify-between rounded-lg border bg-card text-card-foreground shadow-sm">
			<div className="flex flex-row items-center justify-between space-y-0 p-6 pb-2">
				<div className="flex items-center gap-2">
					<h3 className="text-sm font-medium tracking-tight">{title}</h3>
					{tooltip && (
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<div>
										<QuestionMarkCircle className="h-4 w-4" />
									</div>
								</TooltipTrigger>
								<TooltipContent>
									<p>{tooltip}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
				</div>
				{isMoney && <DollarSign />}
			</div>
			<div className="p-6 pt-0">
				{loading ? (
					<Skeleton className="h-8 w-16 bg-slate-300" />
				) : (
					<div className="text-2xl font-bold">
						{isMoney ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(amount ?? 0) : amount}
					</div>
				)}
			</div>
		</div>
	);
};
