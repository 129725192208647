import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {auth} from "../../../firebase";
import {
	EditSerieProductBody,
	editSerieProductData,
	GetSerieProductListParams,
	getSerieProductsList,
	InsertSerieProductBody,
	insertSerieProductData,
	SerieProduct,
} from "../../../data-access/products/serie-products";
import {InsertSerieProductGoogleBody, insertSerieProductGoogleData} from "../../../data-access/products/serie-google";
import {InsertSerieProductStripeBody, insertSerieProductStripeData} from "../../../data-access/products/serie-stripe";

export interface serieProductsInterface {
	loading: boolean;
	results: SerieProduct[];
	validateProduct: boolean;
	totalResults: number;
	current: number;
	pageSize: number;
	success: boolean;
	error: string;
}

const initialState: serieProductsInterface = {
	loading: false,
	results: [],
	validateProduct: true,
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
	error: "",
};

/*GENERAL*/
const getSerieProducts = createAsyncThunk("serieProducts/serie-list", async (params: GetSerieProductListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getSerieProductsList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertSerieProducts = createAsyncThunk("serieProducts/insert", async (data: InsertSerieProductBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getSerieProductsList(token, {
			page: 0,
			page_size: 6,
			searchby: "serieid",
			searchvalue: data.serieid,
			active: true,
		});
		const resultValidate = response.data.results.some(el => el.membership_type === data.membership_type);

		if (!resultValidate) {
			const res = await insertSerieProductData(token, data);

			if (!res.data) return thunkAPI.rejectWithValue("Problem inserting serie product, try again.");

			return {
				item: res.data,
			};
		} else return thunkAPI.rejectWithValue("Ya existe un producto activo del mismo tipo de membresía asignado a esta serie.");
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editSerieProducts = createAsyncThunk("serieProducts/edit", async (data: EditSerieProductBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editSerieProductData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

/*STRIPE*/
const insertSerieStripeProducts = createAsyncThunk("serieProducts/stripe-insert", async (data: InsertSerieProductStripeBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;
		const res = await insertSerieProductStripeData(token, data);
		if (!res.data) return thunkAPI.rejectWithValue("Problem sincro stripe product, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

/*GOOGLE*/
const insertSerieGoogleProducts = createAsyncThunk("serieProducts/google-insert", async (data: InsertSerieProductGoogleBody, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;
		const res = await insertSerieProductGoogleData(token, data);
		if (!res.data) return thunkAPI.rejectWithValue("Problem sincro google product, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const serieProductsSlice = createSlice({
	name: "serieProducts",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSerieProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(getSerieProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getSerieProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertSerieProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertSerieProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertSerieProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(editSerieProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(editSerieProducts.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editSerieProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertSerieStripeProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertSerieStripeProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertSerieStripeProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
		builder
			.addCase(insertSerieGoogleProducts.pending, state => {
				state.loading = true;
				state.error = "";
			})
			.addCase(insertSerieGoogleProducts.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertSerieGoogleProducts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload as string;
				state.success = false;
			});
	},
});
export const serieProductsActions = {
	...serieProductsSlice.actions,
	getSerieProducts,
	insertSerieProducts,
	editSerieProducts,
	insertSerieGoogleProducts,
	insertSerieStripeProducts,
};

export default serieProductsSlice;
