import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetReportedCommentsListParams {
	page_size?: number;
	page?: number;
	searchby?: string;
	searchvalue?: string;
}

export interface GetCommentsListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: ReportedCommentsResult[];
}

export interface ReportedCommentsResult {
	id: string;
	author: string;
	banned: boolean;
	published: boolean;
	content: string;
	path: string;
	responses: number;
	likes: number;
	dislikes: number;
	subjectid: string;
	author_id: string;
	created_at: string;
	updated_at: string;
	content_id: string;
	number_reports_total: number;
	number_reports: number;
	number_reports_enforced: number;
	number_reports_rejected: number;
	status?: string;
}

/*LIST-ALL-COMMENTS-REPORTED*/
export async function getCommentsReportedList(
	token: string,
	params?: GetReportedCommentsListParams,
): Promise<AxiosResponse<GetCommentsListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_reported_comments", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

/*UPDATE*/
export interface EditReportedCommentBody {
	id: string;
	status: string;
}

interface EditReportedCommentResponse {
	id: string;
	commentid: string;
	userid: string;
	reason: string;
	hide: boolean;
	status: string;
}
export async function editReportedCommentData(token: string, data: EditReportedCommentBody): Promise<AxiosResponse<EditReportedCommentResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_reported_comment", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*LIST-ALL-REPORTS-FROM-A-COMMENT*/
export interface GetReportsListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: AllReportsForCommentResponse[];
}

export interface AllReportsForCommentResponse {
	commentid: string;
	userid: string;
	reason: string;
	hide: boolean;
	status: string;
	id: string;
	user_name: string;
}

export async function getAllReportsList(token: string, params?: GetReportedCommentsListParams): Promise<AxiosResponse<GetReportsListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_comment_report", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
