import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export const SeriePartnershipSchema = z.object({
	active: z.boolean(),
	serie_id: z.string(),
	partner_id: z.string(),
	profit_percentage: z.number(),
	audit_created: z.string().optional(),
	audit_updated: z.string().optional(),
	// Corporate role
	userid: z.string().optional(),
	photo_url: z.string().optional(),
	name: z.string().optional(),
	total_profit: z.string().optional(),
	series: z
		.array(
			z
				.object({
					active: z.boolean(),
					personal_profit: z.number(),
					production_cost: z.number(),
					profit_percentage: z.number(),
					series_id: z.string(),
					series_name: z.string(),
					series_profit: z.number(),
					series_Profit_pg: z.number(),
				})
				.optional(),
		)
		.optional(),
});
export type SeriePartnershipType = z.infer<typeof SeriePartnershipSchema>;

/***************
 * LIST CONFIGS
 ***************/
export interface GetPartnerConfigParams {
	seriesid?: string;
	series_id_list?: string;
	initial_date?: string;
	final_date?: string;
	searchby?: "partner_name" | "partner_id" | "serie_name" | "serie_id";
	searchvalue?: string;
	page?: number;
	page_size?: number;
	active?: boolean;
	order?: "asc" | "desc";
	orderby?: "name" | "profits";
}
export interface GetPartnerConfigResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: SeriePartnershipType[];
}

export async function getPartnerConfigData(token: string, params: GetPartnerConfigParams): Promise<AxiosResponse<GetPartnerConfigResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/list_partner_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

/***************
 * POST CONFIG
 ***************/
export async function insertPartnerConfigData(token: string, data: SeriePartnershipType): Promise<AxiosResponse<SeriePartnershipType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/create_partner_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/***************
 * UPDATE CONFIG
 ***************/
export async function updatePartnerConfigData(token: string, data: SeriePartnershipType): Promise<AxiosResponse<SeriePartnershipType>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_partner_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
