import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface FetchFormDataParams {
	idToken: string;
	page?: number;
	page_size?: number;
	searchby?: string;
	searchvalue?: string;
	sponsor?: boolean;
	status?: number;
}

export const fetchFormData = async (params: FetchFormDataParams) => {
	const {idToken, page, page_size, searchby, searchvalue, sponsor, status} = params;
	return await axiosHandler.get("/admin_list_content_form_data", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${idToken}`,
		},
		params: {
			page,
			searchby,
			searchvalue,
			sponsor,
			status,
			page_size,
		},
	});
};

export const EditFormBodySchema = z.object(
	{
		id: z.string(),
		message: z.string().nonempty("El texto del mensaje no puede estar vacio."),
		name: z.string().optional(),
		email: z.string().nonempty("El email no puede estar vacio."),
		phone: z.string().optional(),
		sponsor: z.boolean(),
		customer_service_note: z.string().optional(),
		status: z.number().optional(),
	},
	{required_error: "Este campo es requerido."},
);
export type EditFormBodyType = z.infer<typeof EditFormBodySchema>;

export interface EditFormDataResponse {
	id: string;
	name: null | string;
	email: string;
	phone: string;
	status: number;
	message: string;
	sponsor: boolean;
	audit_created: string;
	audit_updated: null | string;
	customer_service_note: null | string;
}

export async function editFormData(token: string, data: EditFormBodyType): Promise<AxiosResponse<EditFormDataResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/update_content_form_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
