import {useEffect, useState} from "react";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../../../../components/primitives/Select";
import {auth} from "../../../../firebase";
import {sponsorsList} from "../../../../data-access/fetch-db-users";

interface SponsorSelectProps {
	selectedSponsor?: any;
	onSponsorChange: (v: string) => void;
}

export default function SponsorSelect({selectedSponsor, onSponsorChange}: SponsorSelectProps) {
	const [selectSponsors, setSelectSponsors] = useState<any[]>([]);

	useEffect(() => {
		auth.currentUser?.getIdToken().then(idToken => {
			sponsorsList(idToken, null)
				.then(res => {
					if (res.data.results.length) {
						const options = res.data.results.map((u: any) => ({
							label: u.name,
							value: u.userid,
						}));
						setSelectSponsors(options);
					}
				})
				.catch(err => {
					console.log(err);
				});
		});
	}, []);

	return (
		<Select value={selectedSponsor} onValueChange={onSponsorChange}>
			<SelectTrigger className="w-[360px]">
				<SelectValue placeholder="Seleccione un Sponsor" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Sponsors</SelectLabel>
					{selectSponsors &&
						selectSponsors.map((contentType, index) => (
							<SelectItem key={index} value={contentType.value.toString()}>
								{contentType.label}
							</SelectItem>
						))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
}
