import Select from "react-select";
import {useEffect, useMemo, useState} from "react";
import {selectStyles} from "../../../../../utils/SelectStyles";
import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import Loader from "./Loader";

const escachaosData = [
	[25.784, -80.2101, "Miami", 240],
	[27.9945, -82.4447, "Tampa", 150],
	[28.4773, -81.337, "Orlando", 80],
	[38.5677, -121.4685, "Sacramento", 54],
	[29.4632, -98.5238, "San Antonio", 87],
	[41.4764, -81.6805, "Cleveland", 110],
	[40.7834, -73.9662, "Manhattan", 70],
	[39.762, -104.8758, "Denver", 130],
	[34.0522, -118.2437, "Los Angeles", 120],
	[45.8, 15.9, "Sarajevo", 178],
	[14.6, -90.2, "Quito", 50],
	[23.113592, -82.366592, "Havana", 84],
	[40.4167, -3.7033, "Madrid", 27],
	[45.4167, 9.75, "Milano", 12],
	[48.8566, 2.3522, "Paris", 9],
	[30.2672, -97.7431, "Austin", 170],
	[35.6895, 139.6917, "Tokyo", 250],
	[37.7749, -122.4194, "San Francisco", 180],
	[51.5074, -0.1278, "London", 220],
	[41.8781, -87.6298, "Chicago", 190],
	[25.7617, -80.1918, "Miami Beach", 320],
	[38.5677, -121.4685, "Sacramento", 54],
	[39.762, -104.8758, "Denver", 130],
	[34.0522, -118.2437, "Los Angeles", 120],
	[45.8, 15.9, "Sarajevo", 178],
	[14.6, -90.2, "Quito", 50],
	[22.113592, -81.3698592, "Havana", 84],
	[41.8781, -87.6298, "Chicago", 190],
	[30.2672, -97.7431, "Austin", 170],
	[35.6895, 139.6917, "Tokyo", 250],
	[37.7749, -122.4164, "San Francisco", 180],
	[51.5074, -0.1278, "London", 220],
	[45.8, 15.9, "Sarajevo", 178],
	[14.6, -90.2, "Quito", 50],
	[22.113592, -84.2367592, "Havana", 5],
	[40.4167, -3.7043, "Madrid", 27],
];

const deletedData = [
	[25.7617, -80.1918, "Miami Beach", 320],
	[38.5677, -121.4685, "Sacramento", 54],
	[39.762, -104.8758, "Denver", 130],
	[34.0522, -118.2437, "Los Angeles", 120],
	[45.8, 15.9, "Sarajevo", 178],
	[14.6, -90.2, "Quito", 50],
	[23.113592, -82.366592, "Havana", 84],
	[41.8781, -87.6298, "Chicago", 190],
	[30.2672, -97.7431, "Austin", 170],
	[35.6895, 139.6917, "Tokyo", 250],
	[37.7749, -122.4194, "San Francisco", 180],
	[51.5074, -0.1278, "London", 220],
];

const noasinoData = [
	[45.8, 15.9, "Sarajevo", 178],
	[14.6, -90.2, "Quito", 50],
	[23.113592, -82.366592, "Havana", 84],
	[40.4167, -3.7033, "Madrid", 27],
	[45.4167, 9.75, "Milano", 12],
	[48.8566, 2.3522, "Paris", 9],
	[27.9945, -82.4447, "Tampa", 150],
	[28.4773, -81.337, "Orlando", 80],
	[37.7749, -122.4194, "San Francisco", 180],
	[51.5074, -0.1278, "London", 220],
	[30.2672, -97.7431, "Austin", 170],
	[35.6895, 139.6917, "Tokyo", 250],
	[41.8781, -87.6298, "Chicago", 190],
];

interface SelectOption {
	label: string;
	value: string;
}

const selectStatus: SelectOption[] = [
	{label: "Banner: La autentica pizza cubana", value: "escachaos"},
	{label: "Banner: Lo mejor de Miami para saborear", value: "deleted"},
	{label: "Video: A partir de hoy solo batidos", value: "no-asi-no"},
];

const selectInitialState = {
	label: "Banner: La autentica pizza cubana",
	value: "escachaos",
};

interface SponsorGoogleWorldChartProps {
	isCorporate?: boolean;
}

export function SponsorGoogleWorldChart({isCorporate = false}: SponsorGoogleWorldChartProps) {
	const [selectedStatus, setSelectedStatus] = useState<SelectOption | null>(selectInitialState);
	const [seriesStatistics, setSeriesStatistics] = useState<any>({});
	const {isLoaded} = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
	});

	const center = useMemo(() => ({lat: 0, lng: 0}), []);
	useEffect(() => {
		if (selectedStatus?.value === "escachaos") {
			setSeriesStatistics(escachaosData);
		} else if (selectedStatus?.value === "deleted") {
			setSeriesStatistics(deletedData);
		} else {
			setSeriesStatistics(noasinoData);
		}
	}, [selectedStatus]);

	return (
		<div className="flex flex-1 flex-col rounded-lg border bg-card">
			<div className="flex flex-row items-center justify-between">
				<h3 className="p-8 text-left text-lg font-semibold leading-none tracking-tight">Vistas por Países.</h3>
				{!isCorporate && (
					<div className="mb-3 w-auto basis-1/3 pr-8">
						<Select
							className="mt-1 w-full rounded ring-1"
							styles={selectStyles}
							options={selectStatus as SelectOption[]}
							value={selectedStatus}
							onChange={selectedOption => {
								setSelectedStatus(selectedOption);
							}}
						/>
					</div>
				)}
			</div>
			<div className="flex items-center justify-between border-b border-border" />

			{!isLoaded ? (
				<Loader title="" />
			) : (
				<GoogleMap
					center={center}
					zoom={3}
					mapContainerStyle={{height: 700, width: "100%"}}
					options={{
						mapTypeControl: false,
						streetViewControl: false,
						zoomControlOptions: {
							position: 9,
						},
						minZoom: 3,
						maxZoom: 11,
					}}
				>
					{seriesStatistics.map((item: any, index: number) => {
						let size = Math.pow(item[3], 1 / 2.5);
						return (
							<MarkerF
								key={index}
								position={{lat: item[0], lng: item[1]}}
								icon={{
									path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
									fillColor: "#FF0000",
									fillOpacity: 0.4,
									strokeWeight: 0,
									scale: size <= 4 ? 4 : size >= 15 ? 15 : size,
								}}
							/>
						);
					})}
				</GoogleMap>
			)}
		</div>
	);
}
