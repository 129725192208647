import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface Comment {
	id: string;
	path: string;
	likes: number;
	author: string;
	banned: boolean;
	content: string;
	dislikes: number;
	author_id: string;
	published: boolean;
	responses: number;
	subjectid: string;
	created_at: string;
	updated_at: string;
	number_reports: number;
	content_id: string;
}

interface GetCommentsListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Comment[];
}
export interface GetCommentsListParams {
	page_size?: number;
	page?: number;
	searchby?: string;
	searchvalue?: string;
	published?: boolean;
	banned?: boolean;
	type?: number;
}
export async function getCommentsList(token: string, params?: GetCommentsListParams): Promise<AxiosResponse<GetCommentsListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_comment_list", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

/*UPDATE - BLOCK*/
export interface EditCommentBody {
	id: string;
	content: string;
	banned: boolean;
}
interface EditCommentResponse {
	id: string;
	path: string;
	likes: number;
	author: string;
	banned: boolean;
	content: string;
	dislikes: number;
	author_id: string;
	published: boolean;
	responses: number;
	subjectid: string;
	created_at: string;
	updated_at: string;
	number_reports: number;
	content_id: string;
}

export async function editCommentData(token: string, data: EditCommentBody): Promise<AxiosResponse<EditCommentResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/admin_update_comment", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*DELETE - REMOVE TREE*/
export interface RemoveCommentBody {
	commentid: string;
}
interface RemoveCommentResponse {
	result: string;
}
export async function removeCommentData(token: string, data: RemoveCommentBody): Promise<AxiosResponse<RemoveCommentResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/admin_delete_user_comment", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}

export interface GetCommentBranchParams {
	id?: string;
	path?: string;
}
export async function getCommentBranchData(token: string, params?: GetCommentBranchParams): Promise<AxiosResponse<Comment[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_comment_branch", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const CustomerServiceCommentResponseBodySchema = z.object({
	parentid: z.string(),
	content: z.object({
		type: z.number(),
		payload: z.string().nonempty("La respuesta no puede estar vacía."),
	}),
});

export type CustomerServiceCommentResponseBody = z.infer<typeof CustomerServiceCommentResponseBodySchema>;
type ReturnComment = Omit<Comment, "content"> & {content: string};

export async function insertCustomerServiceCommentResponse(
	token: string,
	body: CustomerServiceCommentResponseBody,
): Promise<AxiosResponse<ReturnComment>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/customer_service_comment_response", body, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
