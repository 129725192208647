import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {serieContentsActions} from "../../../../_store/features/serie-content/serie-content-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {Button} from "../../../../components/primitives/Button";
import {Checkbox} from "../../../../components/primitives/Checkbox";
import {Input} from "../../../../components/primitives/Input";
import {Textarea} from "../../../../components/primitives/Textarea";
import {Image as ImageIcon} from "../../../../components/primitives/icons";
import {Spinner} from "../../../../components/primitives/icons/Spinner";
import {CONTENT, CONTENT_TYPES, MEMBERSHIP_TYPES} from "../../../../constants";
import {InsertContentSchema, InsertContentType, uploadContentImage} from "../../../../data-access/series/content";
import {auth} from "../../../../firebase";
import MuxVideoCombobox from "../subcomponents/MuxVideoCombobox";
import ContentTypeSelect from "./subcomponents/ContentTypeSelect";
import MembershipTypeSelect from "./subcomponents/MembershipTypeSelect";
import {toast} from "react-hot-toast";
import {FOLDERS} from "../../../../data-access/multimedia/gallery";
import MultimediaImagePickerModal from "../../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../../_store/features/gallery/gallery-slice";
import {DayPicker} from "../../../../components/blocks/DatePicker";
import HourPicker from "../../../../components/primitives/HourPicker/HourPicker";
import {convertToStringHour} from "../../../../../utils/convertToStringHour";
import PremiereTypeSelect from "./subcomponents/PremiereTypeSelect";

const initialState: InsertContentType = {
	seriesid: "",
	title: "",
	slugs: "",
	order: 0,
	estreno: false,
	new: false,
	published: false,
	membership_type: MEMBERSHIP_TYPES[0].value,
	content_type: CONTENT_TYPES[0].value,
	short_description: "",
	long_description: "",
	video_id: "",
	duration: "",
	lowres_image: "",
	square_image: "",
	rectangular_image: "",
	release_date: null,
	whitelist_release_date: null,
	premiere_type: 0,
	display_release_date: false,
};

type ImageFieldsTypes = keyof Pick<InsertContentType, "lowres_image" | "square_image" | "rectangular_image">;
const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Fotos de Contenidos", value: "content"}];

export function SerieContentNew() {
	const {id: serieId} = useParams();
	const dispatch = useAppDispatch();
	const serie = useAppSelector(state => state.series.results.find(el => el.id === serieId));
	const serieContent = useAppSelector(state => state.serieContent);
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("lowres_image");
	const [hour, setHour] = useState<string>("00:00:00.000Z");
	const [whiteListHour, setWhiteListHour] = useState<string>("00:00:00.000Z");
	const [fieldLoading, setFieldLoading] = useState<ImageFieldsTypes[]>([]);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		control,
		getValues,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<InsertContentType>({
		resolver: zodResolver(InsertContentSchema),
		defaultValues: initialState,
	});

	useEffect(() => {
		if (!serie) {
			return navigate("/series/list");
		}
		setValue("seriesid", serie.id);
	}, [navigate, serie, setValue]);

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("contentid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadContentImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const onSubmit: SubmitHandler<InsertContentType> = data => {
		if (data.release_date) {
			const date = new Date(`${data.release_date.split("T")[0]}T${hour}Z`);
			data.release_date = date.toISOString();
		}
		if (data.whitelist_release_date) {
			const date = new Date(`${data.whitelist_release_date.split("T")[0]}T${whiteListHour}Z`);
			data.whitelist_release_date = date.toISOString();
		}
		const contentData = {
			...data,
			seriesid: serieId!,
			order: serieContent.results.filter(x => x.content_type === data.content_type).length + 1,
		};
		return dispatch(serieContentsActions.insertSerieContent(contentData as InsertContentType)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Contenido Creado Correctamente");
				reset();
				setTimeout(() => {
					navigate(`/series/edit/${serieId}`);
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	const handleSelectHour = (date: string) => {
		setHour(date);
	};

	const handleSelectWhiteListHour = (date: string) => {
		setWhiteListHour(date);
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Contenido - {serie?.title}</h2>
				<span className="text-base text-muted-foreground lg:text-lg">
					Permite crear contenidos asociados a una serie para ser mostrados en las plataformas.
				</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Titulo<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar titulo del contenido..." {...register("title")} />
									{errors?.title?.message && <span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Slug<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar slug del contenido..." {...register("slugs")} />
									{errors?.slugs?.message && <span className="text-sm font-medium text-destructive">{errors?.slugs?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Es estreno</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="items-top flex space-x-2">
										<Controller
											name="estreno"
											control={control}
											render={({field}) => (
												<Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />
											)}
										></Controller>
										<div className="grid gap-1.5 leading-none">
											<label
												htmlFor="terms1"
												className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Marcar contenido como estreno.
											</label>
											<p className="text-sm text-muted-foreground">
												Al marcar el contenido como estreno, se comenzara a mostrar como nuevo en los preview de los videos.
											</p>
										</div>
									</div>
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Publico</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="items-top flex space-x-2">
										<Controller
											name="published"
											control={control}
											render={({field}) => (
												<Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />
											)}
										></Controller>
										<div className="grid gap-1.5 leading-none">
											<label
												htmlFor="terms1"
												className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Marcar contenido como publicado.
											</label>
											<p className="text-sm text-muted-foreground">
												Al marcar el contenido como publico, se comenzara a mostrar en las plataformas.
											</p>
										</div>
									</div>
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Tipo de Contenido</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Controller
										name="content_type"
										control={control}
										render={({field}) => (
											<ContentTypeSelect
												selectedContentType={field.value.toString()}
												onSelectedContentTypeChange={v => field.onChange(parseInt(v))}
												contentToExclude={[CONTENT.SHORT, CONTENT.STREAM]}
											/>
										)}
									/>
									{errors?.content_type?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.content_type.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Mostrar fecha de release</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="items-top flex space-x-2">
										<Controller
											name="display_release_date"
											control={control}
											render={({field}) => (
												<Checkbox id="display_release_date" checked={field.value} onCheckedChange={c => field.onChange(c)} />
											)}
										></Controller>
										<div className="grid gap-1.5 leading-none">
											<label
												htmlFor="display_release_date"
												className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Mostrar cuenta atrás de le fecha de release.
											</label>
											<p className="text-sm text-muted-foreground">
												Al marcar el checkbox, el usuario podrá ver el tiempo que falta para el release.
											</p>
										</div>
									</div>
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
								<dd className="mt-1 justify-start self-start sm:col-span-2 sm:mt-0">
									<div className="flex flex-row">
										<Controller
											name="release_date"
											control={control}
											render={({field}) => (
												<DayPicker
													{...field}
													onDayChange={date => {
														const dateFormatted = date.toISOString();
														field.onChange(dateFormatted);
														setValue("release_date", dateFormatted);
													}}
													className="justify-start self-start"
												/>
											)}
										/>
										<p className="mx-2 self-center text-xl">-</p>
										<HourPicker hourSelected={convertToStringHour(null)} onTimeChange={date => handleSelectWhiteListHour(date)} />
									</div>
									{errors?.release_date?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
									Fecha de release <p className="ml-2 text-xs font-bold text-destructive">*Whitelist</p>
								</dt>
								<dd className="mt-1 justify-start self-start sm:col-span-2 sm:mt-0">
									<div className="flex flex-row">
										<Controller
											name="whitelist_release_date"
											control={control}
											render={({field}) => (
												<DayPicker
													{...field}
													onDayChange={date => {
														const dateFormatted = date.toISOString();
														field.onChange(dateFormatted);
													}}
													className="justify-start self-start"
												/>
											)}
										/>
										<p className="mx-2 self-center text-xl">-</p>
										<HourPicker hourSelected={convertToStringHour(null)} onTimeChange={date => handleSelectHour(date)} />
									</div>
									{errors?.whitelist_release_date?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.whitelist_release_date?.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Tipo de Membresía</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Controller
										name="membership_type"
										control={control}
										render={({field}) => (
											<MembershipTypeSelect
												selectedMembership={field.value.toString()}
												onMembershipChange={v => field.onChange(parseInt(v))}
											/>
										)}
									/>
									{errors?.membership_type?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.membership_type.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Tipo de Estreno</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Controller
										name="premiere_type"
										control={control}
										render={({field}) => (
											<PremiereTypeSelect
												selectedPremiereType={field.value.toString()}
												onPremiereTypeChange={v => field.onChange(parseInt(v))}
											/>
										)}
									/>
									{errors?.membership_type?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.membership_type.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción corta</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea placeholder="Insertar descripción corta del contenido..." {...register("short_description")} />
									{errors?.short_description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.short_description.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción larga</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea placeholder="Insertar descripción larga del contenido..." {...register("long_description")} />
									{errors?.long_description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.long_description.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<MuxVideoCombobox
										id="video_content_id"
										name="video_id"
										defaultValue={getValues("video_id")}
										onChangeSelected={selected => {
											const videoDuration = `${selected.duration}`;
											setValue("video_id", selected.playbackid);
											setValue("duration", videoDuration);
										}}
									/>
									{errors?.video_id?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.video_id.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Duración</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar duración del contenido..." {...register("duration")} />
									<p className="mt-1.5 text-sm text-muted-foreground">
										Si rellenas el Video ID se tratara de obtener la duración de ese video.
									</p>
									{errors?.duration?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.duration?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Baja Resolución</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("lowres_image") ? (
												fieldLoading.includes("lowres_image") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("lowres_image")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "lowres_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("lowres_image");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.lowres_image?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.lowres_image.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Cuadrada</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("square_image") ? (
												fieldLoading.includes("square_image") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("square_image")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												name="square_image"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "square_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("square_image");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.square_image?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.square_image?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Rectangular</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("rectangular_image") ? (
												fieldLoading.includes("rectangular_image") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("rectangular_image")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												name="rectangular_image"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "rectangular_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("rectangular_image");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.rectangular_image?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.rectangular_image?.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate("/series/list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Contenido
						</Button>
					</div>
				</form>
			</div>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={1}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setValue(type, url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</section>
	);
}
