import {GetGeneralIncomeAndProfitResponse} from "../../../../data-access/dashboard/statistics";

function CostEarnGraphic(props: {
	statistics: GetGeneralIncomeAndProfitResponse & {range_profit: number; total_profit: number};
	earnPercentage: number;
	profitPercentage: number;
}) {
	const {statistics, earnPercentage, profitPercentage} = props;
	const production_cost = statistics?.production_cost ?? 0;
	const greenLineWidth = 100 - Math.round(earnPercentage);
	const blueLineWidth = Math.round(earnPercentage);

	const profitGreenLineWidth = 100 - Math.round(profitPercentage);
	const profitRedLineWidth = Math.round(profitPercentage);
	const earnings = statistics?.range_profit > production_cost ? (statistics?.range_profit - production_cost) * 0.01 : 0;
	const isEarning = earnings > 0;

	if (statistics.total_profit && !isEarning) {
		return (
			<div className="relative flex flex-col p-6 pt-0">
				<div className="flex w-full flex-row justify-between">
					<div className="mb-10 flex items-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="mt-1 h-3 w-8 rounded-sm bg-lime-600" />
							<p className="text-center text-sm font-medium tracking-tight">{`Ingresos $${
								(statistics?.total_profit * 0.01).toFixed(2) ?? 0
							}`}</p>
						</div>
					</div>
					<div className="mb-10 flex items-center justify-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="h-3 w-8 rounded-sm bg-red-600" />
							<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${
								(production_cost * 0.01).toFixed(2) ?? 0
							}`}</p>
						</div>
					</div>
				</div>
				<div className="flex flex-row pt-0">
					<div
						className={`h-14 w-[${profitGreenLineWidth}%] min-w-[80px] rounded-es-lg rounded-ss-lg bg-lime-600 p-5`}
						style={{width: `${profitGreenLineWidth}%`}}
					/>
					<div
						className={`h-14 w-[${profitRedLineWidth}%] min-w-[80px] rounded-ee-lg rounded-se-lg bg-red-600 p-5`}
						style={{width: `${profitRedLineWidth}%`}}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="relative flex flex-col p-6 pt-0">
			{earnings === 0 ? (
				<div className="flex w-full flex-col">
					<div className="mb-10 flex items-center justify-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="h-3 w-8 rounded-sm bg-red-600" />
							<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${
								statistics.production_cost ?? 0
							}`}</p>
						</div>
					</div>
					<div className={`h-14 w-[100%] rounded-lg bg-red-600 p-5`} />
				</div>
			) : (
				<div className="flex w-full flex-col">
					<div className="flex w-full flex-row justify-between">
						<div className="mb-10 flex items-center">
							<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
								<div className="mt-1 h-3 w-8 rounded-sm bg-lime-600" />
								<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${
									(production_cost / 100).toFixed(2) ?? 0
								}`}</p>
							</div>
						</div>
						<div className="mb-10 flex items-center justify-center">
							<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
								<div className="h-3 w-8 rounded-sm bg-blue-900" />
								<p className="text-center text-sm font-medium tracking-tight">{`Ganancia $${earnings.toFixed(2) ?? 0}`}</p>
							</div>
						</div>
					</div>
					<div className="flex w-full flex-row pt-0">
						<div
							className={`h-14 w-[${greenLineWidth}%] rounded-es-lg rounded-ss-lg bg-lime-600 p-5`}
							style={{width: `${greenLineWidth}%`}}
						/>
						<div
							className={`h-14 w-[${blueLineWidth}%] rounded-ee-lg rounded-se-lg bg-blue-900 p-5`}
							style={{width: `${blueLineWidth}%`}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default CostEarnGraphic;
