import {PayloadAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {MarketingListParams, MarketingListResult, getUsersListForMarketing} from "../../../data-access/marketing/marketing";
import {auth} from "../../../firebase";

export interface marketingInterface {
	loading: boolean;
	results: MarketingListResult[];
	totalResults: number;
	pageSize: number;
	current: number;
	error: string;
	success: boolean;
}

const initialState: marketingInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	pageSize: 0,
	current: 0,
	success: false,
	error: "",
};

const getUsersForMarketing = createAsyncThunk("marketing/list", async (params: MarketingListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getUsersListForMarketing(token, params);

		return {
			totalResults: response.totalResults,
			pageSize: response.pageSize,
			current: response.current,
			results: response.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const marketingSlice = createSlice({
	name: "marketing",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getUsersForMarketing.pending, state => {
				state.loading = true;
			})
			.addCase(getUsersForMarketing.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getUsersForMarketing.rejected, (state, action) => {});
	},
});
export const marketingActions = {
	...marketingSlice.actions,
	getUsersForMarketing,
};

export default marketingSlice;
