import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {CancelCircleFilled, CheckCircleFilled, Eye, Warning, NoSymbol, Comment as CommentIcon} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {commentsActions} from "../../_store/features/comments/comments-slice";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {Comment, GetCommentsListParams} from "../../data-access/comments/comments";
import {serieContentsActions} from "../../_store/features/serie-content/serie-content-slice";
import ReactSelect from "react-select";
import {DetailsModal} from "./subcomponents/DetailsModal";
import ReplySheet from "./subcomponents/ReplySheet";
import {DataTable} from "../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {Button} from "../../components/primitives/Button";
import {TooltipContent, TooltipTrigger, Tooltip, TooltipProvider} from "../../components/primitives/ToolTip";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import {COMMENTS_TYPES} from "../../constants/comments";
import {shortsActions} from "../../_store/features/shorts/shorts-slice";
import {SelectOptionType} from "../../../utils/globalTypes";

const searchOptions = [
	{name: "Comentario ID", value: "id"},
	{name: "Autor ID", value: "author_id"},
	{name: "Texto Comentario", value: "content"},
	{name: "Contenido", value: "subjectid"},
];

const publishedOptions = [
	{name: "Todos", value: "all"},
	{name: "Activos", value: "active"},
	{name: "Bloqueados", value: "banned"},
	{name: "Eliminados", value: "deleted"},
];

const columnHelper = createColumnHelper<Comment>();

function CommentsList() {
	const comments = useAppSelector(state => state.comments);
	const serieContent = useAppSelector(state => state.serieContent);
	const shortContent = useAppSelector(state => state.shorts);

	const dispatch = useAppDispatch();
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedPublished, setSelectedPublished] = useState(publishedOptions[0]);
	const [paramsAll, setParamsAll] = useState<GetCommentsListParams>();
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	/*MODAL DETAILS*/
	const [isModalDetails, setIsModalDetails] = useState(false);
	const [selectCommentId, setSelectCommentId] = useState<any>();
	const [isReplySheetOpen, setIsReplySheetOpen] = useState(false);
	const [selectedCommentPathData, setSelectedCommentPathData] = useState<{id: string; path: string}>();

	const [listContents, setListContents] = useState<SelectOptionType[]>();

	const handleSearch = () => {
		const params = getParams(selectedPublished);

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (!searchValue && !selectedPublished) return;
		if (page.pageSize === 0) return;

		setParamsAll({page: page.pageIndex, page_size: page.pageSize, ...params});
		dispatch(commentsActions.getComments({page: page.pageIndex, page_size: page.pageSize, ...params}));
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		dispatch(serieContentsActions.getAllSeriesContents());
		dispatch(
			shortsActions.getShortsList({
				params: {page_size: 999999, page: 0},
			}),
		);
	}, [dispatch]);

	useEffect(() => {
		if (serieContent.results.length) {
			const options = serieContent.results.map(content => ({
				label: content.serie_title + " - " + content.title,
				value: content.id,
			}));
			setListContents(options);
		}
	}, [serieContent]);

	const handleSelectionChange = (option: SelectOptionType | null) => {
		if (option) {
			setSearchValue(option.value);
		}
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		return [
			columnHelper.accessor("author", {
				id: "Fecha / Usuario / Id",
				header: ({column}) => <DataTableColumnHeader title="Fecha de publicado / Usuario / Id" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">
							{new Date(info.row.original.created_at).toLocaleString(undefined, {dateStyle: "short", timeStyle: "short"})} -{" "}
							{info.row.original.author || "Anónimo"}
						</span>
						<span className="overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.author_id ?? "-"}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("content_id", {
				id: "Contenido",
				header: ({column}) => <DataTableColumnHeader title="Contenido" column={column} />,
				cell: info => {
					const serieComment = serieContent.results.find(el => el.id === info.row.original.content_id);
					const shortComment = shortContent.results.find(el => el.id === info.row.original.content_id);
					const content = serieComment ? serieComment : shortComment;

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{content?.title}</span>
							{content?.serie_title ? (
								<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{content?.serie_title}</span>
							) : (
								<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{content?.short_description}</span>
							)}
						</div>
					);
				},
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("content", {
				id: "Texto Comentario / Id",
				header: ({column}) => <DataTableColumnHeader title="Texto Comentario / Id" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{JSON.parse(info.row.original.content).payload}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.display({
				id: "Estado",
				header: "Estado",
				cell: info => (
					<TooltipProvider>
						<div className="items-left justify-left flex w-full gap-0.5">
							{JSON.parse(info.row.original.content).type === COMMENTS_TYPES.DELETED ? (
								<span className={"relative inline-block px-3 py-1 font-semibold leading-tight text-red-700"}>
									<Tooltip>
										<TooltipTrigger>
											<NoSymbol />
										</TooltipTrigger>
										<TooltipContent>Eliminado</TooltipContent>
									</Tooltip>
								</span>
							) : (
								<>
									{info.cell.row.original.banned ? (
										<span className={"relative inline-block px-3 py-1 font-semibold leading-tight text-red-700"}>
											<Tooltip>
												<TooltipTrigger>
													<CancelCircleFilled />
												</TooltipTrigger>
												<TooltipContent>Bloqueado</TooltipContent>
											</Tooltip>
										</span>
									) : (
										<>
											{info.cell.row.original.number_reports > 0 ? (
												<span className={"relative inline-block px-3 py-1 font-semibold leading-tight"}>
													<Tooltip>
														<TooltipTrigger>
															<span className="absolute left-[4px] top-[-13px] rounded-full bg-yellow-400 px-1">
																{info.cell.row.original.number_reports}
															</span>
															<Warning />
														</TooltipTrigger>
														<TooltipContent>Reportes</TooltipContent>
													</Tooltip>
												</span>
											) : (
												<span className={"relative inline-block px-3 py-1 font-semibold leading-tight text-green-700"}>
													<Tooltip>
														<TooltipTrigger>
															<CheckCircleFilled />
														</TooltipTrigger>
														<TooltipContent>Activo</TooltipContent>
													</Tooltip>
												</span>
											)}
										</>
									)}
								</>
							)}
						</div>
					</TooltipProvider>
				),
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="justify-left ml-2 flex w-full gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setSelectCommentId(info.row.original.id);
								setIsModalDetails(true);
							}}
						>
							<Eye className="h-4 w-4" />
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setSelectedCommentPathData({id: info.row.original.id, path: info.row.original.path});
								setIsReplySheetOpen(true);
							}}
						>
							<CommentIcon className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serieContent.results, tableRef]);

	const refresh = () => {
		setSelectedPublished(publishedOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(commentsActions.getComments({page: page.pageIndex, page_size: page.pageSize}));
	};

	const handleVisibilityFilter = (value: string) => {
		const status = publishedOptions.find(el => el.name === value) ?? publishedOptions[0];

		setSelectedPublished(status);

		const params = getParams(status);
		dispatch(commentsActions.getComments({page: page.pageIndex, page_size: page.pageSize, ...params}));
	};

	const getParams = (status: {name: string; value: string}) => {
		const params: Record<string, any> = {};
		switch (status.value) {
			case "active":
				params.published = true;
				params.banned = false;
				params.type = COMMENTS_TYPES.TEXT;
				break;
			case "banned":
				params.banned = true;
				break;
			case "deleted":
				params.type = COMMENTS_TYPES.DELETED;
				break;
			default:
				params.published = undefined;
		}
		return params;
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Comentarios</h2>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						{selectedSearchBy.value === "subjectid" ? (
							<ReactSelect
								className="w-full text-sm"
								styles={{
									control: baseStyles => ({
										...baseStyles,
										height: "32px",
										minHeight: "32px",
										borderRadius: "6px",
										borderBottomRightRadius: 0,
										borderTopRightRadius: 0,
										backgroundColor: "transparent",
										borderColor: "hsl(var(--border))",
									}),
									placeholder: baseStyles => ({
										...baseStyles,
										fontSize: "14px",
									}),
									valueContainer: baseStyles => ({
										...baseStyles,
										padding: 0,
										paddingLeft: "12px",
									}),
									dropdownIndicator: baseStyles => ({
										...baseStyles,
										padding: "0px 8px",
									}),
									indicatorsContainer: baseStyles => ({
										...baseStyles,
										height: "32px",
									}),
									input: baseStyles => ({
										...baseStyles,
										minHeight: 0,
										margin: 0,
										padding: 0,
										lineHeight: "100%",
										display: "flex",
										width: "100%",
										fontSize: "14px",
									}),
									singleValue: baseStyles => ({
										...baseStyles,
										fontSize: "14px",
									}),
								}}
								isLoading={serieContent.loading}
								isSearchable={true}
								options={listContents}
								onChange={handleSelectionChange}
								placeholder="Selecciona un contenido..."
							/>
						) : (
							<Input
								className="h-8 rounded-r-none"
								type="text"
								onChange={e => setSearchValue(e.target.value)}
								value={searchValue}
								placeholder="Buscar..."
							/>
						)}
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleVisibilityFilter} value={selectedPublished.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{publishedOptions.map((pOption, idx) => (
									<SelectItem key={idx} value={`${pOption.name}`}>
										{pOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={comments.results}
					pageCount={Math.ceil(comments.totalResults / comments.pageSize)}
					pagination={page}
					loading={comments.loading}
					onPaginationChange={setPage}
					rowHeight={57}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<DetailsModal
				onDismiss={() => {
					setIsModalDetails(false);
				}}
				open={isModalDetails}
				id={selectCommentId}
				returnParams={paramsAll!}
			/>
			<ReplySheet
				commentData={selectedCommentPathData}
				open={isReplySheetOpen}
				onOpenChange={o => {
					setIsReplySheetOpen(o);
				}}
			/>
		</div>
	);
}

export default CommentsList;
