import axios, {AxiosResponse} from "axios";
import {dbUser} from "../../_store/features/user-db/user-db-slice";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface UserAuth extends dbUser {
	get_partner_series:
		| {
				serieid: string;
				profit: number;
				active: boolean;
				title: string;
				production_cost: number;
		  }[]
		| null;
	get_influencer_data: {
		link: string;
		referral_code: string;
		influencer_series: {
			active: boolean;
			serie_id: string;
			audit_created: string;
			audit_updated: string;
			influencer_id: string;
			audit_author_id: string;
			profit_percentage: number;
		}[];
		count_users: number;
	} | null;
	get_sponsor_data: {
		total_payments: string | null;
		banner_ads:
			| {
					id: string;
					img_mobile: string;
					img_web: string;
					order: number;
					prints: number;
					published: boolean;
					sponsor: string;
					text: string;
			  }[]
			| null;
		video_ads:
			| {
					audit_created: string;
					audit_updated: string;
					playback_id: string;
					prints: number;
					sponsor_id: string;
					title: string;
					video_id: string;
			  }[]
			| null;
	} | null;
}

export async function getAdminUserData(token: string) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_user_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface GetSponsorDataParams {
	sponsorid: string;
	initial_date?: string;
	final_date?: string;
}

export async function getSponsorData(token: string, params?: GetSponsorDataParams): Promise<AxiosResponse<Pick<UserAuth, "get_sponsor_data">>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_get_sponsor_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface RemoveUserDeviceBody {
	userid: string;
	device_infoid: string;
}
interface RemoveUserDeviceResponse {
	result: string;
}
export async function removeUserDevice(token: string, data: RemoveUserDeviceBody): Promise<AxiosResponse<RemoveUserDeviceResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/admin_delete_user_device", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}
