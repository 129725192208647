import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, ChartOptions, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {addDays, differenceInDays, format, isSameDay, parse} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

export const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					if (tooltipItems[0].label.includes("T")) {
						return format(new Date(tooltipItems[0].label), "EE, dd MMMM");
					}
				},
				label: function (context) {
					let label = context.dataset.label || "";

					if (label) {
						label += ": ";
					}
					if (context.parsed.y !== null) {
						label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
					}
					return label;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "d MMM").toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
		y: {
			ticks: {
				callback(tickValue) {
					return new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(Number(tickValue) ?? 0);
				},
			},
		},
	},
};

interface SerieIncomeAndProfitProps {
	dates: DateRange | undefined;
}

interface MonthlyData {
	[key: string]: {
		date: string;
		profit: number;
		profit_coins: number;
	};
}

const thirtyDays = 2678400000;

export function MembershipCoinsIncome({dates}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; profit: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery("generalIncome");

	const [showByMonths, setShowByMonths] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > thirtyDays);
	}, [dates, dispatch]);

	const data: ChartData<"bar", {date: string; profit: number}[]> = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		//Transforms the data returned from big query
		const membershipsData =
			statistics?.daily_profit_memberships?.map(({day, month, year, profit}) => ({
				date: new Date(year, month - 1, day).toISOString(),
				profit: profit * 0.01,
				profit_coins: 0,
			})) ?? [];

		const coinsData =
			statistics?.daily_profit_coins?.map(({day, month, year, profit}) => ({
				date: new Date(year, month - 1, day).toISOString(),
				profit: 0,
				profit_coins: profit * 0.01,
			})) ?? [];

		const mergedData = [...membershipsData, ...coinsData];

		const groupedData = mergedData.reduce((acc: {date: string; profit: number; profit_coins: number}[], curr) => {
			const existingItem = acc.find(item => item.date === curr.date);
			if (existingItem) {
				existingItem.profit += curr.profit;
				existingItem.profit_coins += curr.profit_coins;
			} else {
				acc.push(curr);
			}
			return acc;
		}, []);

		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endDate, -idx).toISOString(), profit: 0, profit_coins: 0}));

		const charData = fillerData
			.map(fillEl => {
				const el = groupedData.find(statEl => {
					return isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return el;

				return fillEl;
			})
			.reverse();

		const monthlyProfitData = groupedData.reduce((acc: MonthlyData, dataPoint) => {
			const date = new Date(dataPoint.date);
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!acc[key]) {
				acc[key] = {
					date: `${year}-${monthString}`,
					profit: 0,
					profit_coins: 0,
				};
			}

			acc[key].profit += dataPoint.profit;
			acc[key].profit_coins += dataPoint.profit_coins;

			return acc;
		}, {});

		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!monthlyProfitData[key]) {
				monthlyProfitData[key] = {
					date: `${year}-${monthString}`,
					profit: 0,
					profit_coins: 0,
				};
			}

			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		const monthlyDataArray = Object.values(monthlyProfitData);

		monthlyDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});

		const selectedData = showByMonths ? monthlyDataArray : charData;

		return {
			datasets: [
				{
					label: "Paquetes Coins",

					data: selectedData,
					backgroundColor: "#4BC0C0",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit_coins",
					},
				},
				{
					label: "Membresías",

					data: selectedData,
					backgroundColor: "#005096",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit",
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, statistics, showByMonths]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ingreso del período.</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							height={350}
							ref={chartRef}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));
												const year = date.getFullYear();
												const month = date.getMonth() + 1;
												const day = date.getDate();

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(new Date(year, month), "MMM").toLowerCase()
													: format(new Date(year, month - 1, day), "d MMM").toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										stacked: true,
									},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
