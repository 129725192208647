import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {useState, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {DataTable} from "../../../components/blocks/DataTable";
import {Button} from "../../../components/primitives/Button";
import {Link} from "react-router-dom";
import {ListPlus, Pencil, Refresh, Trash} from "../../../components/primitives/icons";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import {auth} from "../../../firebase";
import {crewActions} from "../../../_store/features/crew/crew-slice";
import {deleteStaffProfession, StaffProfession} from "../../../data-access/series/staff";

const columnHelper = createColumnHelper<StaffProfession>();

const searchOptions = [{name: "Nombre", value: "fullname"}];

export function ProfessionsList() {
	const dispatch = useAppDispatch();
	const crew = useAppSelector(state => state.crew);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteStaffProfessionID, setDeleteStaffProfessionID] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		dispatch(crewActions.getStaffProfessionList({page: page.pageIndex, page_size: page.pageSize, ...params}));
	};

	const refresh = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(crewActions.getStaffProfessionList({page: page.pageIndex, page_size: page.pageSize}));
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const CONTROL_COL_WIDTH = 150;

		if (tableWidth > 0) {
			tableWidth = tableWidth - CONTROL_COL_WIDTH;
		}

		return [
			columnHelper.accessor("profession", {
				id: "Nombre",
				header: ({column}) => <DataTableColumnHeader title="Nombre / Id" column={column} />,
				cell: info => (
					<div className="ml-2 flex flex-col text-left">
						<span className="truncate font-medium">{info.row.original.profession}</span>
						<span className="truncate text-muted-foreground">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 1),
			}),

			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/crew/professions/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteStaffProfessionID(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: CONTROL_COL_WIDTH,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Profesiones</h2>
				<Button className="gap-2" asChild>
					<Link to="/crew/professions/new">
						<ListPlus />
						Nueva profesión
					</Link>
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={crew.staffProfessionsResults}
					pageCount={Math.ceil(crew.staffProfessionsTotalResults / crew.staffProfessionsPageSize)}
					pagination={page}
					loading={crew.staffProfessionsLoading}
					onPaginationChange={setPage}
					rowHeight={57}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					auth.currentUser
						?.getIdToken()
						.then(token => {
							deleteStaffProfession(token, {id: deleteStaffProfessionID})
								.then(() => {
									setDeleteConfirmationModalOpen(false);
									setDeleteStaffProfessionID("");
									if (!auth.currentUser) return;

									const params: Record<string, any> = {};

									if (searchValue) {
										params.searchvalue = searchValue;
										params.searchby = selectedSearchBy.value;
									}

									dispatch(crewActions.getStaffProfessionList({page: page.pageIndex, page_size: page.pageSize, ...params}));
								})
								.catch(err => {
									console.log(err);
								});
						})
						.catch(() => {});
				}}
				title={"Eliminar Persona"}
				description={<span className="text-white">Al confirmar se eliminara esta profesión, este proceso no se puede revertir.</span>}
			/>
		</div>
	);
}
