import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {EditInsertVersionInfoParams, VersionsListResponse, newVersionInitialState} from "../../data-access/versions/versions";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {Button} from "../../components/primitives/Button";
import {CancelCircleFilled, CheckCircleFilled, ListPlus, Pencil, Trash} from "../../components/primitives/icons";
import {DataTable} from "../../components/blocks/DataTable";
import ActionConfirmModal from "../../components/blocks/ActionConfirmModal";
import {versionsActions} from "../../_store/features/versions/versions-slice";
import EditInsertVersionModal from "../../components/blocks/EditInsertVersionModal";

const columnHelper = createColumnHelper<VersionsListResponse>();
export interface NotasObj {
	[key: string]: string;
}

function ListVersions() {
	const dispatch = useAppDispatch();
	const versions = useAppSelector(state => state.versions);
	const reversedResults = versions.results.slice().reverse();
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteVersionId, setDeleteVersionId] = useState("");
	const [versionInfo, setVersionInfo] = useState<EditInsertVersionInfoParams>(newVersionInitialState);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [isEdit, setIsEdit] = useState(false);
	const [editInsertVersionModalOpen, setEditInsertVersionModalOpen] = useState(false);

	useEffect(() => {
		const checkUpdate = async () => {
			dispatch(versionsActions.getVersions());
		};
		checkUpdate();
	}, [dispatch]);
	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("app_version", {
				id: "Versión / Id",
				header: ({column}) => <DataTableColumnHeader title="Versión / Id" column={column} />,
				cell: info => (
					<div className="ml-2 flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left text-sm">{info.row.original.app_version}</span>
						<span className="overflow-hidden text-ellipsis text-left text-sm text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: tableWidth / 6,
			}),
			columnHelper.accessor("release_notes", {
				id: "Notas",
				header: ({column}) => <DataTableColumnHeader title="Notas" column={column} />,
				cell: info => {
					const notes: NotasObj = JSON.parse(info.row.original.release_notes);

					return (
						<div className="ml-2 flex flex-col text-left">
							<ul className="flex flex-col">
								{Object.keys(notes)
									.slice(-3)
									.reverse()
									.map((notaKey, index) => (
										<li className="truncate" key={index}>
											{notes[notaKey]}
										</li>
									))}
							</ul>
						</div>
					);
				},
				size: tableWidth / 6,
			}),
			columnHelper.accessor("major_release", {
				id: "Major release",
				header: ({column}) => <DataTableColumnHeader title="Major release" column={column} />,
				cell: info => (
					<div className="ml-2 flex w-full">
						<span className={"text-left font-medium" + (info.cell.row.original.major_release ? " text-green-700" : " text-red-700")}>
							{info.row.original.major_release ? <CheckCircleFilled /> : <CancelCircleFilled />}
						</span>
					</div>
				),
				size: tableWidth / 6,
			}),
			columnHelper.accessor("critical_release", {
				id: "Critical release",
				header: ({column}) => <DataTableColumnHeader title="Critical release" column={column} />,
				cell: info => (
					<div className="ml-2 flex w-full">
						<span className={"text-left font-medium" + (info.cell.row.original.critical_release ? " text-green-700" : " text-red-700")}>
							{info.row.original.critical_release ? <CheckCircleFilled /> : <CancelCircleFilled />}
						</span>
					</div>
				),
				size: tableWidth / 6,
			}),
			columnHelper.accessor("link", {
				id: "Link",
				header: ({column}) => <DataTableColumnHeader title="Link" column={column} />,
				cell: info => (
					<div className="ml-2 flex w-full">
						<span className="truncate font-medium">{info.row.original.link}</span>
					</div>
				),
				size: tableWidth / 6,
			}),
			columnHelper.display({
				id: "Controles",
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setVersionInfo(info.row.original);
								setIsEdit(true);
								setEditInsertVersionModalOpen(true);
							}}
						>
							<Pencil className="h-4 w-4" />
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteVersionId(info.row.original.id ? info.row.original.id : "");
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: tableWidth / 6,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Versiones</h2>
				<Button
					className="gap-2"
					onClick={() => {
						setEditInsertVersionModalOpen(true);
					}}
				>
					<ListPlus />
					Nueva Version
				</Button>
			</div>

			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={reversedResults}
					pageCount={Math.ceil(versions.results.length / page.pageSize)}
					pagination={page}
					loading={versions.loading}
					onPaginationChange={setPage}
					rowHeight={65}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					dispatch(versionsActions.deleteVersions(deleteVersionId));
					setDeleteConfirmationModalOpen(false);
				}}
				title={"Eliminar Versión"}
				description={<span className="text-white">Al confirmar eliminaras la versión, este proceso no se puede revertir.</span>}
			/>
			<EditInsertVersionModal
				open={editInsertVersionModalOpen}
				onDismiss={() => {
					setEditInsertVersionModalOpen(false);
					setIsEdit(false);
					setVersionInfo(newVersionInitialState);
				}}
				onSuccess={params => {
					setEditInsertVersionModalOpen(false);
					setIsEdit(false);
					setVersionInfo(newVersionInitialState);
					if (isEdit) {
						dispatch(versionsActions.editVersions(params));
						setIsEdit(false);
					} else {
						dispatch(versionsActions.insertVersions(params));
						setIsEdit(false);
					}
				}}
				isEdit={isEdit}
				versionInfo={versionInfo}
			/>
		</div>
	);
}

export default ListVersions;
