import axios, {AxiosRequestConfig} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface MarketingListResult {
	name: string;
	email: string;
	phone: string;
	userid: string;
}

export interface MarketingListParams {
	output: string;
	page: number;
	page_size: number;
	seriesid?: string;
	contentid?: string;
	membership?: number;
	initial_date?: string;
	final_date?: string;
	series_views?: string;
	trailer_views?: string;
	content_views?: string;
	series_like?: boolean;
	series_dislike?: boolean;
	membership_purchase?: boolean;
	content_like?: boolean;
	content_dislike?: boolean;
	validated_phone?: boolean;
	validated_email?: boolean;
	views?: boolean;
	muxvideo_id?: string;
	all?: boolean;
	admins?: boolean;
}

type MarketingRequestConfig = {
	headers: {
		"Content-Type": string;
		"Content-Disposition": string;
		Accept: string;
		Authorization: string;
	};
	params?: MarketingListParams;
	responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | undefined;
};

export async function getUsersListForMarketing(token: string, params?: MarketingListParams, useResponseType: boolean = false) {
	const idToken = `Bearer ${token}`;
	const headers = {
		"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"Content-Disposition": "attachment; filename=marketing.xlsx",
		Accept: "*/*",
		Authorization: idToken,
	};

	const config: AxiosRequestConfig & MarketingRequestConfig = {
		headers,
		params,
	};

	if (useResponseType) {
		config.responseType = "blob";
	}

	const response = await axiosHandler.get("/marketing_query_data", config);

	return response.data;
}
