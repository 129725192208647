import {deepEqual} from "@firebase/util";
import {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {validateEmail} from "../../../../../utils/validators";
import {Avatar} from "../../../../components/primitives/Avatar";
import {Close, ArrowUpTray, Trash} from "../../../../components/primitives/icons";
import {CheckCircle} from "../../../../components/primitives/icons/CheckCircle";
import {Spinner} from "../../../../components/primitives/icons/Spinner";
import {XCircle} from "../../../../components/primitives/icons/XCircle";
import ToolTip from "../../../../components/primitives/ToolTip-toDeprecate";
import {PROVIDERS} from "../../../../constants";
import {adminNotifyViaEmail} from "../../../../data-access/notify";
import {auth} from "../../../../firebase";
import {editDbUserData, fetchDbUsersData} from "../../../../_store/features/user-db/user-db-actions";
import {dbUser} from "../../../../_store/features/user-db/user-db-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import AvatarImagePicker from "../../subcomponents/AvatarImagePicker";
import {toast} from "react-hot-toast";
import {Button} from "../../../../components/primitives/Button";
import {Checkbox} from "../../../../components/primitives/Checkbox";
import {associatesActions} from "../../../../_store/features/associates/associates-slice";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {DataTable} from "../../../../components/blocks/DataTable";
import ActionConfirmModal from "../../../../components/blocks/ActionConfirmModal";
import {removeUserDevice} from "../../../../data-access/user-auth";
import useCurrentUserRole from "../../../../components/hooks/useCurrentUserRole";
import {formattedDayAndHour} from "../../../../../utils/formattedDayAndHour";

export const editUserInitialState: dbUser = {
	userid: "",
	audit_created: "",
	audit_updated: undefined,
	email: "",
	name: "",
	phone: "",
	coin: 0,
	photo_url: undefined,
	disabled: false,
	email_verified: false,
	phone_verified: false,
	provider: "",
	verify_hash: "",
	password_hash: "",
	removed: false,
	admin: null,
	editor: null,
	master_editor: null,
	corporate: null,
	partner: null,
	sponsor: null,
	influencer: null,
	customer_service: null,
	marketing: null,
	associate: false,
	user_devices_info: null,
};

const rolesToCheck: (keyof dbUser)[] = [
	"admin",
	"editor",
	"master_editor",
	"partner",
	"sponsor",
	"influencer",
	"customer_service",
	"marketing",
	"corporate",
];

interface user_devices {
	id: string;
	brand: string;
	userid: string;
	base_os: string;
	uniqueid: string;
	created_at: string;
	updated_at: string | null;
}

const columnHelper = createColumnHelper<user_devices>();

export default function UserData() {
	let {id} = useParams();
	const usersDb = useAppSelector(state => state.dbuser);
	const user = useAppSelector(state => state.user.userInfo);
	const currentUserRole = useCurrentUserRole();
	const initialUser = useAppSelector(state => state.dbuser.results.find(el => el.userid === id));
	const seriesAssociates = useAppSelector(state => state.associates.results).filter(user => user.userid === id);
	const [editUser, setEditUser] = useState<dbUser>(editUserInitialState);
	const [isUserAdmin, setIsUserAdmin] = useState(false);
	const [imagePickerOpen, setImagePickerOpen] = useState(false);
	const [notifyChangesViaEmail, setNotifyChangesViaEmail] = useState(false);
	const [validEmail, setValidEmail] = useState<boolean>(false);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 1,
	});
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteUserDevice, setDeleteUserDevice] = useState({
		userid: "",
		device_infoid: "",
	});
	const userDevices = editUser.user_devices_info ?? [];
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!initialUser) return;
		setEditUser(initialUser);
		setIsUserAdmin(rolesToCheck.some(role => initialUser[role] === true));
		if (currentUserRole && currentUserRole === "admin") {
			dispatch(associatesActions.getUserAssociatesSeries({}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialUser]);

	useEffect(() => {
		if (!editUser.email) return;

		setValidEmail(validateEmail(editUser.email));
	}, [editUser.email]);

	const hasChanges = useMemo(() => {
		if (!initialUser) return false;
		return !deepEqual(initialUser, editUser);
	}, [editUser, initialUser]);

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 5);

		return [
			columnHelper.accessor("brand", {
				id: "Dispositivo",
				header: ({column}) => <DataTableColumnHeader title="Dispositivo" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left capitalize">{info.row.original.brand}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("base_os", {
				id: "Sistema Operativo",
				header: ({column}) => <DataTableColumnHeader title="Sistema Operativo" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left capitalize">{info.row.original.base_os}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("uniqueid", {
				id: "Id del Dispositivo",
				header: ({column}) => <DataTableColumnHeader title="Id del Dispositivo" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.uniqueid}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha de inicio",
				header: ({column}) => <DataTableColumnHeader title="Fecha de inicio" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-start gap-3">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteUserDevice({
									userid: editUser?.userid ?? "",
									device_infoid: info.row.original.id,
								});
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [editUser?.userid, tableRef]);

	const deleteDevice = async () => {
		const idToken = await auth.currentUser?.getIdToken();
		if (!idToken) {
			throw new Error("No se pudo obtener el token de autenticación.");
		}

		const promise = removeUserDevice(idToken, {
			userid: deleteUserDevice.userid,
			device_infoid: deleteUserDevice.device_infoid,
		}).then(() => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: 999999,
					admin: true,
				}),
			);
		});

		toast.promise(promise, {
			loading: "Eliminando dispositivo",
			error: "Error al eliminar dispositivo",
			success: "Dispositivo eliminado correctamente.",
		});
	};

	return (
		<div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="white mx-3 inline w-32 text-right font-semibold text-gray-900">ID:</div>
				<span className={"font-semibold" + (editUser?.disabled ? " text-red-500" : " text-green-500")}>{editUser?.userid}</span>
				<span className="ml-3">
					<ToolTip text={"Habilitar"}>
						<Button
							type="button"
							className="m-0 inline-flex items-center rounded-lg bg-green-600 p-1.5 text-sm text-gray-100 hover:bg-green-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{disabled: false}};
								});
							}}
						>
							<CheckCircle />
						</Button>
					</ToolTip>
				</span>
				<span className="ml-3 mr-20">
					<ToolTip text={"Deshabilitar"}>
						<Button
							type="button"
							className="inline-flex items-center rounded-lg bg-red-600 p-1.5 text-sm text-gray-100 hover:bg-red-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{disabled: true}};
								});
							}}
						>
							<XCircle />
						</Button>
					</ToolTip>
				</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Fecha Creación:</div>
				<span className={"font-semibold text-black"}>
					{new Date(editUser.audit_created).toLocaleString("es", {
						year: "numeric",
						month: "long",
						day: "numeric",
						hour: "numeric",
						hourCycle: "h12",
						minute: "numeric",
					})}
				</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Última Modificación:</div>
				<span className={"font-semibold text-black"}>
					{editUser.audit_updated
						? new Date(editUser.audit_updated).toLocaleString("es", {
								year: "numeric",
								month: "long",
								day: "numeric",
								hour: "numeric",
								hourCycle: "h12",
								minute: "numeric",
						  })
						: "-"}
				</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Proveedor:</div>
				<span className={"font-semibold text-black"}>{PROVIDERS[editUser.provider]}</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Nombre:</div>
				<input
					type="text"
					value={editUser.name}
					className="mr-5 w-96 flex-1 rounded-lg border-2 border-gray-600 px-3 py-2 outline-0"
					placeholder={editUser?.name}
					onChange={event => {
						setEditUser(prevState => {
							return {...prevState, ...{name: event.target.value}};
						});
					}}
				/>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Foto:</div>
				<span className="">
					{editUser?.photo_url ? (
						<img className="h-1/2 w-auto" src={editUser?.photo_url + "?" + Date.now()} alt="Foto Perfil" />
					) : (
						<Avatar />
					)}
				</span>
				<span>
					<ToolTip text={"Eliminar Foto"}>
						<Button
							type="button"
							className="m-5 inline-flex items-center rounded-lg bg-red-600 p-1.5 text-sm text-gray-100 hover:bg-red-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{photo_url: ""}};
								});
							}}
						>
							<Close />
						</Button>
					</ToolTip>
				</span>
				<span>
					<ToolTip text={"Agregar Foto"}>
						<Button
							type="button"
							className="inline-flex items-center rounded-lg bg-slate-900 p-1.5 text-sm text-gray-100 hover:bg-slate-800"
							onClick={() => {
								setImagePickerOpen(true);
							}}
						>
							<ArrowUpTray />
						</Button>
					</ToolTip>
				</span>
			</div>

			{!isUserAdmin && user?.admin && (
				<div className="flex items-center border-b border-gray-200 py-3">
					<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Asociado:</div>
					<div className="mr-auto flex items-center">
						<Checkbox
							checked={editUser.associate || false}
							onCheckedChange={() => {
								setEditUser(prevState => {
									return {...prevState, ...{associate: !editUser.associate}};
								});
							}}
							disabled={seriesAssociates.length > 0}
						/>
						<label className="select-none pl-2 text-gray-900" htmlFor="associated">
							Es un usuario asociado a la plataforma?
						</label>
					</div>
				</div>
			)}

			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Correo Electrónico:</div>
				<ToolTip text={validEmail ? "" : "Correo Inválido"}>
					<input
						type="text"
						spellCheck={false}
						className={
							"mr-5 w-96 flex-1 rounded-lg border-2 px-3 py-2 outline-0" +
							(editUser?.email_verified ? " border-green-700 bg-green-50" : " border-red-700 bg-red-50") +
							(validEmail
								? " text-green-700"
								: " text-red-700 underline decoration-red-700 decoration-wavy decoration-1 underline-offset-1")
						}
						placeholder={editUser?.email}
						value={editUser.email}
						onChange={event => {
							setEditUser(prevState => {
								return {...prevState, ...{email: event.target.value}};
							});
						}}
					/>
				</ToolTip>
				<span>
					<ToolTip text={"Marcar Verificado"}>
						<Button
							type="button"
							className="m-0 inline-flex items-center rounded-lg bg-green-600 p-1.5 text-sm text-gray-100 hover:bg-green-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{email_verified: true}};
								});
							}}
						>
							<CheckCircle />
						</Button>
					</ToolTip>
				</span>
				<span className="ml-3 mr-20">
					<ToolTip text={"Marcar No Verificado"}>
						<Button
							type="button"
							className="inline-flex items-center rounded-lg bg-red-600 p-1.5 text-sm text-gray-100 hover:bg-red-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{email_verified: false}};
								});
							}}
						>
							<XCircle />
						</Button>
					</ToolTip>
				</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Teléfono:</div>
				<input
					type="text"
					value={editUser?.phone ?? ""}
					className={
						"mr-5 w-96 flex-1 rounded-lg border-2 px-3 py-2 outline-0" +
						(editUser?.phone_verified ? " border-green-600 bg-green-100" : " border-red-600 bg-red-100")
					}
					placeholder={editUser?.phone ?? "Insertar número de teléfono ..."}
					onChange={event => {
						setEditUser(prevState => {
							return {...prevState, ...{phone: event.target.value}};
						});
					}}
				/>
				<span>
					<ToolTip text={"Marcar Verificado"}>
						<Button
							type="button"
							className="m-0 inline-flex items-center rounded-lg bg-green-600 p-1.5 text-sm text-gray-100 hover:bg-green-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{phone_verified: true}};
								});
							}}
						>
							<CheckCircle />
						</Button>
					</ToolTip>
				</span>
				<span className="ml-3 mr-20">
					<ToolTip text={"Marcar No Verificado"}>
						<Button
							type="button"
							className="inline-flex items-center rounded-lg bg-red-600 p-1.5 text-sm text-gray-100 hover:bg-red-500"
							onClick={() => {
								setEditUser(prevState => {
									return {...prevState, ...{phone_verified: false}};
								});
							}}
						>
							<XCircle />
						</Button>
					</ToolTip>
				</span>
			</div>
			<div className="flex items-center border-b border-gray-200 py-3">
				<div className="mx-3 inline w-32 text-right font-semibold text-gray-900">Coins:</div>
				<input
					type="text"
					value={editUser.coin}
					className="mr-5 w-96 flex-1 rounded-lg border-2 border-gray-600 px-3 py-2 outline-0"
					placeholder={editUser?.coin.toString()}
					onKeyDown={event => {
						const RE = /\D/g;
						if (RE.test(event.key) && event.key !== "Backspace") {
							event.preventDefault();
						}
					}}
					onChange={event => {
						if (event.target.value === "") {
							setEditUser(prevState => {
								return {...prevState, ...{coin: 0}};
							});
							return;
						}
						setEditUser(prevState => {
							return {...prevState, ...{coin: parseInt(event.target.value)}};
						});
					}}
				/>
			</div>

			{editUser.user_devices_info && (
				<div className="flex w-full items-center border-b border-gray-200 py-3">
					<p className="mx-3 inline w-32 text-right font-semibold text-gray-900">Dispositivos del usuario:</p>
					<div className="mr-4 mt-1 flex h-fit w-full sm:col-span-2 sm:mt-0">
						<DataTable
							ref={ref => setTableRef(ref)}
							columns={columns}
							dataset={userDevices}
							pageCount={1}
							pagination={page}
							onPaginationChange={setPage}
							rowHeight={65}
							showPagination={false}
						/>
					</div>
				</div>
			)}

			<div className="flex items-center justify-end space-x-2 rounded-b border-t border-gray-200 p-6">
				<div className="mr-auto flex items-center pl-6 ">
					<input
						id="published"
						name="published"
						type="checkbox"
						className="h-4 w-4"
						checked={notifyChangesViaEmail}
						onChange={e => setNotifyChangesViaEmail(prev => !prev)}
					/>
					<label className="select-none pl-2 text-gray-900" htmlFor="published">
						Notificar cambios via Email
					</label>
				</div>
				<button
					type="button"
					disabled={!validEmail || !hasChanges}
					className="rounded-lg bg-neutral-900 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none disabled:cursor-not-allowed disabled:bg-neutral-700 disabled:text-neutral-400 hover:bg-neutral-700"
					onClick={() => {
						auth.currentUser
							?.getIdToken()
							.then(idToken => {
								dispatch(editDbUserData({data: editUser, admin: isUserAdmin}));

								if (notifyChangesViaEmail) {
									const isEmailChanged = initialUser?.email !== editUser.email;
									const isPhoneChanged = initialUser?.phone !== editUser.phone;
									let message = "";

									if (isEmailChanged) {
										message = message.concat(`Tu email ha sido actualizado, para acceder debes utilizar: ${editUser.email}. `);
									}
									if (isEmailChanged) {
										message = message.concat(`Tu email ha sido actualizado, para acceder debes utilizar: ${editUser.email}. `);
									}

									if (isPhoneChanged) {
										message = message.concat(`Tu teléfono ha sido actualizado, en el futuro debes utilizar: ${editUser.phone}. `);
									}
									if (isPhoneChanged) {
										message = message.concat(`Tu teléfono ha sido actualizado, en el futuro debes utilizar: ${editUser.phone}. `);
									}

									if (isEmailChanged || isPhoneChanged) {
										adminNotifyViaEmail(idToken, {
											recipient: editUser.email,
											subject: "Se han realizado cambios a su perfil",
											notification: message,
										});
									}
									setNotifyChangesViaEmail(false);
								}
							})
							.then(() => {
								navigate(-1);
								toast.success("Datos actualizados correctamente");
							});
					}}
				>
					{usersDb.loading ? <Spinner className="h-5 w-5 animate-spin" /> : "Actualizar"}
				</button>
				<button
					type="button"
					className="rounded-lg bg-neutral-200 px-5 py-2.5 text-sm font-medium text-neutral-900 focus:z-10 focus:outline-none focus:ring-4"
					onClick={() => {
						if (hasChanges) {
							const res = window.confirm(
								"Tienes cambios que no han sido guardados, si abandonas la pagina los perderás. Estas seguro que deseas salir?",
							);
							if (res === false) return;
						}
						navigate(-1);
					}}
				>
					Cancelar
				</button>
			</div>
			<AvatarImagePicker
				user={editUser}
				open={imagePickerOpen}
				onDismiss={() => {
					setImagePickerOpen(false);
				}}
				onSuccess={e => {
					setEditUser(prev => ({...prev, photo_url: e + "?" + Date.now()}));
				}}
			/>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					deleteDevice();
					setDeleteConfirmationModalOpen(false);
					setDeleteUserDevice({
						userid: "",
						device_infoid: "",
					});
				}}
				title={"Eliminar Anuncio"}
				description={<span className="text-white">Al confirmar eliminarás este dispositivo para el usuario. ¿Deseas continuar?</span>}
			/>
		</div>
	);
}
