import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {
	getSerieIncomeAndProfit,
	getSeriesLikesAndFavorites,
	GetSerieIncomeAndProfitParams,
	GetSerieIncomeAndProfitResponse,
	GetSerieLikesAndFavoritesParams,
	getContentsLikesAndFavorites,
	GetContentLikesAndFavoritesParams,
	GetSponsorAdViewResponse,
	GetSponsorAdViewParams,
	getSponsorAdViewsData,
	gGetInfluencerProfitData,
	GetInfluencerProfitParams,
	GetInfluencerProfitResponse,
	getSponsorAdViewsByAdsData,
	GetInfluencerAndPartnerTransactionsResponse,
	getLatestTransactionsDetails,
	GetInfluencerAndPartnerTransactionsParams,
	getGeneralIncomeAndProfit,
	GetGeneralIncomeAndProfitResponse,
	getSeriesContentEarnings,
	GetSerieContentEarnings,
	SeriesContentEarningsResponse,
	GetGeneralIncomeAndProfitParams,
	getInfluencerProfit,
	InfluencersBySeriesProfitResponse,
	InfluencersBySeriesProfitParams,
	GetProfitCorporateParams,
	getProfitCorporateListData,
	GetProfitCorporateResponse,
	GetSeriesAndContentInteractionResponse,
} from "../../../data-access/dashboard/statistics";
import {RootState} from "../../store";
import {getTopContent, getTopSeries as getTopSeriesData} from "../../../data-access/mux";
import {TopStatsResponse} from "../../../data-access/mux";

type RequestState = "pending" | "fulfilled" | "rejected";
export type DashboardQueries =
	| "generalIncome"
	| "partnerSeriesIncome"
	| "topSeries"
	| "serieLastMonthLikesAndFav"
	| "contentLastMonthLikesAndFav"
	| "topSeriesContents"
	| "sponsorAdViews"
	| "sponsorAdViewsByAds"
	| "seriesIncome"
	| "influencerIncome"
	| "latestTransactions"
	| "profitCorporate"
	| "serieContentEarning"
	| "influencerProfitByContent";

export type DataByName = {
	generalIncome: GetGeneralIncomeAndProfitResponse;
	partnerSeriesIncome: GetSerieIncomeAndProfitResponse;
	serieLastMonthLikesAndFav: GetSeriesAndContentInteractionResponse;
	topSeries: TopStatsResponse;
	contentLastMonthLikesAndFav: GetSeriesAndContentInteractionResponse;
	topSeriesContents: TopStatsResponse;
	sponsorAdViews: GetSponsorAdViewResponse;
	sponsorAdViewsByAds: GetSponsorAdViewResponse;
	seriesIncome: Record<string, GetSerieIncomeAndProfitResponse>;
	influencerIncome: GetInfluencerProfitResponse;
	latestTransactions: GetInfluencerAndPartnerTransactionsResponse[];
	profitCorporate: GetProfitCorporateResponse;
	serieContentEarning: SeriesContentEarningsResponse[];
	influencerProfitByContent: InfluencersBySeriesProfitResponse;
};

export interface dashboardInterface {
	loading: boolean;
	selectedSerieId: string | undefined;
	selectedContentId: string | undefined;
	statusByName: Record<DashboardQueries, RequestState | undefined>;
	dataByName: {
		[k in keyof DataByName]: DataByName[k] | undefined;
	};
}

const initialState: dashboardInterface = {
	loading: false,
	selectedSerieId: undefined,
	selectedContentId: undefined,
	statusByName: {
		generalIncome: undefined,
		partnerSeriesIncome: undefined,
		topSeries: undefined,
		serieLastMonthLikesAndFav: undefined,
		contentLastMonthLikesAndFav: undefined,
		topSeriesContents: undefined,
		sponsorAdViews: undefined,
		sponsorAdViewsByAds: undefined,
		seriesIncome: undefined,
		influencerIncome: undefined,
		latestTransactions: undefined,
		profitCorporate: undefined,
		serieContentEarning: undefined,
		influencerProfitByContent: undefined,
	},
	dataByName: {
		generalIncome: undefined,
		partnerSeriesIncome: undefined,
		topSeries: undefined,
		serieLastMonthLikesAndFav: undefined,
		contentLastMonthLikesAndFav: undefined,
		topSeriesContents: undefined,
		sponsorAdViews: undefined,
		sponsorAdViewsByAds: undefined,
		seriesIncome: undefined,
		influencerIncome: undefined,
		latestTransactions: undefined,
		profitCorporate: undefined,
		serieContentEarning: undefined,
		influencerProfitByContent: undefined,
	},
};

const getGeneralSeriesIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-general-income",
	async (params: GetGeneralIncomeAndProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getGeneralIncomeAndProfit(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getPartnersSeriesProfit = createAppAsyncThunk(
	"dashboard/get-serie-income-last-month",
	async (params: GetSerieIncomeAndProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSerieIncomeAndProfit(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getPartnerSeriesIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-series-income",
	async (params: Pick<GetSerieIncomeAndProfitParams, "initial_date" | "final_date">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const responses = await Promise.all(
				thunkAPI
					.getState()
					.user.userInfo?.get_partner_series?.filter(el => el.active)
					.map(async serie => {
						const res = await getSerieIncomeAndProfit(token, {...params, seriesid_list: serie.serieid});

						return [serie.serieid, res.data];
					}) ?? [],
			);

			return Object.fromEntries(responses);
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getInfluencerSerieIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-influencer-income",
	async (params: GetInfluencerProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await gGetInfluencerProfitData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getSerieLastMonthLikeAndFavorite = createAppAsyncThunk(
	"dashboard/get-serie-likes-and-favorites-last-month",
	async (params: GetSerieLikesAndFavoritesParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSeriesLikesAndFavorites(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getContentLastMonthLikeAndFavorite = createAppAsyncThunk(
	"dashboard/get-content-likes-and-favorites-last-month",
	async (params: GetContentLikesAndFavoritesParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getContentsLikesAndFavorites(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getTopSeries = createAppAsyncThunk("dashboard/get-top-series", async (_, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getTopSeriesData(token);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getTopSeriesContents = createAppAsyncThunk("dashboard/get-top-serie-contents", async (seriesid_list: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getTopContent(token, seriesid_list);

		if (!thunkAPI.getState().dashboard.selectedContentId) {
			thunkAPI.dispatch(dashboardActions.setSelectedContentId(response.data[0].contentid));
		}

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSponsorAdViews = createAppAsyncThunk("dashboard/get-sponsor-ad-views", async (params: GetSponsorAdViewParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getSponsorAdViewsData(token, {...params});

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSponsorAdViewsByAds = createAppAsyncThunk("dashboard/get-sponsor-ad-views-by-ads", async (params: GetSponsorAdViewParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token) return;

		const response = await getSponsorAdViewsByAdsData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getInfluencerAndPartnerTransactions = createAppAsyncThunk(
	"dashboard/get-influencer-and-partner-transactions",
	async (params: GetInfluencerAndPartnerTransactionsParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getLatestTransactionsDetails(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getProfitCorporateTransactions = createAppAsyncThunk("dashboard/get-profit-corporate", async (params: GetProfitCorporateParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getProfitCorporateListData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSerieEarnings = createAppAsyncThunk("dashboard/get-serie-content-earnings", async (params: GetSerieContentEarnings, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getSeriesContentEarnings(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getInfluencerProfitByContent = createAppAsyncThunk(
	"dashboard/get-influencer-profit",
	async (params: InfluencersBySeriesProfitParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getInfluencerProfit(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setSelectedSerieId(state, action: PayloadAction<string | undefined>) {
			state.selectedSerieId = action.payload;
		},
		setSelectedContentId(state, action: PayloadAction<string | undefined>) {
			state.selectedContentId = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getGeneralSeriesIncomeAndProfit.pending, (state, action) => {
				state.loading = true;
				state.statusByName["generalIncome"] = "pending";
			})
			.addCase(getGeneralSeriesIncomeAndProfit.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["generalIncome"] = "fulfilled";
				state.dataByName["generalIncome"] = action.payload;
			})
			.addCase(getGeneralSeriesIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["generalIncome"] = "rejected";
			});
		builder
			.addCase(getPartnersSeriesProfit.pending, (state, action) => {
				state.loading = true;
				state.statusByName["partnerSeriesIncome"] = "pending";
			})
			.addCase(getPartnersSeriesProfit.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["partnerSeriesIncome"] = "fulfilled";
				state.dataByName["partnerSeriesIncome"] = action.payload;
			})
			.addCase(getPartnersSeriesProfit.rejected, (state, action) => {
				state.statusByName["partnerSeriesIncome"] = "rejected";
			});
		builder
			.addCase(getTopSeries.pending, (state, action) => {
				state.statusByName["topSeries"] = "pending";
			})
			.addCase(getTopSeries.fulfilled, (state, action) => {
				state.statusByName["topSeries"] = "fulfilled";
				state.dataByName["topSeries"] = action.payload;
			})
			.addCase(getTopSeries.rejected, (state, action) => {
				state.statusByName["topSeries"] = "rejected";
			});
		builder
			.addCase(getTopSeriesContents.pending, (state, action) => {
				state.statusByName["topSeriesContents"] = "pending";
			})
			.addCase(getTopSeriesContents.fulfilled, (state, action) => {
				state.statusByName["topSeriesContents"] = "fulfilled";
				state.dataByName["topSeriesContents"] = action.payload;
			})
			.addCase(getTopSeriesContents.rejected, (state, action) => {
				state.statusByName["topSeriesContents"] = "rejected";
			});
		builder
			.addCase(getSerieLastMonthLikeAndFavorite.pending, (state, action) => {
				state.statusByName["serieLastMonthLikesAndFav"] = "pending";
			})
			.addCase(getSerieLastMonthLikeAndFavorite.fulfilled, (state, action) => {
				state.statusByName["serieLastMonthLikesAndFav"] = "fulfilled";
				state.dataByName["serieLastMonthLikesAndFav"] = action.payload;
			})
			.addCase(getSerieLastMonthLikeAndFavorite.rejected, (state, action) => {
				state.statusByName["serieLastMonthLikesAndFav"] = "rejected";
			});
		builder
			.addCase(getContentLastMonthLikeAndFavorite.pending, (state, action) => {
				state.statusByName["contentLastMonthLikesAndFav"] = "pending";
			})
			.addCase(getContentLastMonthLikeAndFavorite.fulfilled, (state, action) => {
				state.statusByName["contentLastMonthLikesAndFav"] = "fulfilled";
				state.dataByName["contentLastMonthLikesAndFav"] = action.payload;
			})
			.addCase(getContentLastMonthLikeAndFavorite.rejected, (state, action) => {
				state.statusByName["contentLastMonthLikesAndFav"] = "rejected";
			});
		builder
			.addCase(getSponsorAdViews.pending, (state, action) => {
				state.statusByName["sponsorAdViews"] = "pending";
			})
			.addCase(getSponsorAdViews.fulfilled, (state, action) => {
				state.statusByName["sponsorAdViews"] = "fulfilled";
				state.dataByName["sponsorAdViews"] = action.payload;
			})
			.addCase(getSponsorAdViews.rejected, (state, action) => {
				state.statusByName["sponsorAdViews"] = "rejected";
			});
		builder
			.addCase(getSponsorAdViewsByAds.pending, (state, action) => {
				state.statusByName["sponsorAdViewsByAds"] = "pending";
			})
			.addCase(getSponsorAdViewsByAds.fulfilled, (state, action) => {
				state.statusByName["sponsorAdViewsByAds"] = "fulfilled";
				state.dataByName["sponsorAdViewsByAds"] = action.payload;
			})
			.addCase(getSponsorAdViewsByAds.rejected, (state, action) => {
				state.statusByName["sponsorAdViewsByAds"] = "rejected";
			});
		builder
			.addCase(getPartnerSeriesIncomeAndProfit.pending, (state, action) => {
				state.statusByName["seriesIncome"] = "pending";
			})
			.addCase(getPartnerSeriesIncomeAndProfit.fulfilled, (state, action) => {
				state.statusByName["seriesIncome"] = "fulfilled";
				state.dataByName["seriesIncome"] = action.payload;
			})
			.addCase(getPartnerSeriesIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["seriesIncome"] = "rejected";
			});
		builder
			.addCase(getInfluencerSerieIncomeAndProfit.pending, (state, action) => {
				state.statusByName["influencerIncome"] = "pending";
			})
			.addCase(getInfluencerSerieIncomeAndProfit.fulfilled, (state, action) => {
				state.statusByName["influencerIncome"] = "fulfilled";
				state.dataByName["influencerIncome"] = action.payload;
			})
			.addCase(getInfluencerSerieIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["influencerIncome"] = "rejected";
			});
		builder
			.addCase(getInfluencerAndPartnerTransactions.pending, (state, action) => {
				state.loading = true;
				state.statusByName["latestTransactions"] = "pending";
			})
			.addCase(getInfluencerAndPartnerTransactions.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["latestTransactions"] = "fulfilled";
				state.dataByName["latestTransactions"] = action.payload;
			})
			.addCase(getInfluencerAndPartnerTransactions.rejected, (state, action) => {
				state.statusByName["latestTransactions"] = "rejected";
			});
		builder
			.addCase(getProfitCorporateTransactions.pending, (state, action) => {
				state.loading = true;
				state.statusByName["profitCorporate"] = "pending";
			})
			.addCase(getProfitCorporateTransactions.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["profitCorporate"] = "fulfilled";
				state.dataByName["profitCorporate"] = action.payload;
			})
			.addCase(getProfitCorporateTransactions.rejected, (state, action) => {
				state.statusByName["profitCorporate"] = "rejected";
			});
		builder
			.addCase(getSerieEarnings.pending, (state, action) => {
				state.loading = true;
				state.statusByName["serieContentEarning"] = "pending";
			})
			.addCase(getSerieEarnings.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["serieContentEarning"] = "fulfilled";
				state.dataByName["serieContentEarning"] = action?.payload?.result;
			})
			.addCase(getSerieEarnings.rejected, (state, action) => {
				state.statusByName["serieContentEarning"] = "rejected";
			});
		builder
			.addCase(getInfluencerProfitByContent.pending, (state, action) => {
				state.loading = true;
				state.statusByName["influencerProfitByContent"] = "pending";
			})
			.addCase(getInfluencerProfitByContent.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["influencerProfitByContent"] = "fulfilled";
				state.dataByName["influencerProfitByContent"] = action.payload;
			})
			.addCase(getInfluencerProfitByContent.rejected, (state, action) => {
				state.statusByName["influencerProfitByContent"] = "rejected";
			});
	},
});

export const dashboardActions = {
	...dashboardSlice.actions,
	getGeneralSeriesIncomeAndProfit,
	getPartnersSeriesProfit: getPartnersSeriesProfit,
	getSerieLastMonthLikeAndFavorite,
	getContentLastMonthLikeAndFavorite,
	getTopSeriesContents,
	getTopSeries,
	getSponsorAdViews,
	getSponsorAdViewsByAds,
	getPartnerSeriesIncomeAndProfit,
	getInfluencerSerieIncomeAndProfit,
	getInfluencerAndPartnerTransactions,
	getProfitCorporateTransactions,
	getSerieEarnings,
	getInfluencerProfitByContent,
};

export const selectedPartnerSerieSelector = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboard.selectedSerieId,
	(partnerSeries, selectedSerieId) => partnerSeries?.find(el => el.serieid === selectedSerieId),
);

export const selectedPartnerSerieContentSelector = createSelector(
	(state: RootState) => state.dashboard.dataByName["topSeriesContents"],
	(state: RootState) => state.dashboard.selectedContentId,
	(serieContents, selectedContentId) => serieContents?.find(el => el.contentid === selectedContentId),
);

export const selectedPartnerSeries = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboard.dataByName["topSeries"],
	(partnerSeries, topSeries) =>
		partnerSeries
			?.filter(el => el.active)
			.map(el => {
				const serieData = topSeries?.find(serie => serie.seriesid === el.serieid);

				return {...el, ...serieData};
			}),
);

export const selectTotalizedStatistic = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboard.dataByName["seriesIncome"],
	(state: RootState) => state.dashboard.dataByName["topSeries"],
	(partnerSeries, serieIncomeMap, topSeries) => {
		const seriesByIncome: {serieId: string; title: string; amount: number; img?: string}[] = [];
		let totalSeriesIncome = 0;
		let netTotalIncome = 0;
		let totalIncomeLastMonth = 0;
		let totalPartnerIncome = 0;

		for (const serie in serieIncomeMap) {
			const element = serieIncomeMap[serie];
			const partnerSerieData = partnerSeries?.find(el => el.serieid === serie);
			const seriesData = topSeries?.find(el => el.seriesid === serie);

			if (!partnerSerieData) continue;

			totalSeriesIncome += element.total_profit;
			totalIncomeLastMonth += element.range_profit;
			const elNetTotalIncome = element.total_profit - (partnerSerieData?.production_cost ?? 0);
			netTotalIncome += elNetTotalIncome;
			totalPartnerIncome += elNetTotalIncome > 0 ? elNetTotalIncome * ((partnerSerieData?.profit ?? 0) / 100) : 0;

			seriesByIncome.push({
				serieId: partnerSerieData?.serieid,
				title: partnerSerieData?.title,
				amount: elNetTotalIncome > 0 ? elNetTotalIncome * ((partnerSerieData?.profit ?? 0) / 100) : 0,
				img: seriesData?.img_thumbnail,
			});
		}

		return {totalSeriesIncome, totalIncomeLastMonth, netTotalIncome, totalPartnerIncome, seriesByIncome};
	},
);

export const selectedInfluencerSerieContentSelector = createSelector(
	(state: RootState) => state.dashboard.dataByName["topSeriesContents"],
	(state: RootState) => state.dashboard.selectedContentId,
	(serieContents, selectedContentId) => serieContents?.find(el => el.contentid === selectedContentId),
);

export const selectedInfluencerSeries = createSelector(
	(state: RootState) => state.user.userInfo?.get_influencer_data?.influencer_series,
	(state: RootState) => state.dashboard.dataByName["topSeries"],
	(influencerSeries, topSeries) =>
		influencerSeries
			?.filter(el => el.active)
			.map(el => {
				const serieData = topSeries?.find(serie => serie.seriesid === el.serie_id);

				return {...el, ...serieData};
			}),
);

export const selectedInfluencerSerieSelector = createSelector(
	selectedInfluencerSeries,
	(state: RootState) => state.dashboard.selectedSerieId,
	(influencerSeries, selectedSerieId) => influencerSeries?.find(el => el.serie_id === selectedSerieId),
);

export default dashboardSlice;
