import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function CorporateNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavLink to="/corporate/influencer" label="Influencers" />
			<NavLink to="/corporate/partner" label="Partners" />
			<NavLink to="/corporate/sponsor" label="Sponsors" />
			<NavLink to="/corporate/marketing" label="Marketing" />
			<NavGroup title="Customer Service" route="/comments">
				<NavLink to="/customer-service/contact" label="Contacto" />
				<NavLink to="/comments/list" label="Comentarios" />
				<NavLink to="/users/list" label="Usuarios" />
			</NavGroup>
		</div>
	);
}
