import {isSameDay} from "date-fns";
type chartData = {date: string; views: number};

export const thirtyDays = 2678400000;

export const transformedData = (data: chartData[], viewData: chartData[], showByMonth: boolean) => {
	const dailyDataGrouped = viewData.reduce((acc: {date: string; views: number}[], curr) => {
		const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
		if (existingDayData) {
			existingDayData.views += curr.views;
		} else {
			acc.push(curr);
		}
		return acc;
	}, []);

	const dailyData = data
		.map(fillEl => {
			let item = dailyDataGrouped.find(statEl => isSameDay(new Date(fillEl.date), new Date(statEl.date)));
			return {views: item?.views ?? fillEl.views, date: fillEl.date.toString()};
		})
		.reverse();

	if (showByMonth) {
		const groupedData = dailyData.reduce((acc, current) => {
			const date = new Date(current.date);
			const year = date.getFullYear();
			const month = date.getMonth();

			const key = `${year}-${month}`;

			if (!acc[key]) {
				acc[key] = {date: current.date, views: 0};
			}

			acc[key].views += current.views;

			return acc;
		}, {} as {[key: string]: {date: string; views: number}});

		const arrayOfObjects = Object.values(groupedData).map(value => value);

		return arrayOfObjects;
	} else {
		return dailyData;
	}
};

export function groupArrayByDay(array: chartData[]): chartData[] {
	return array.reduce((acc: {date: string; views: number}[], curr) => {
		const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
		if (existingDayData) {
			existingDayData.views += curr.views;
		} else {
			acc.push(curr);
		}
		return acc;
	}, []);
}

export function combineData(fillerData: chartData[], dailyLikes: chartData[]) {
	return fillerData
		.map(fillEl => {
			const el = dailyLikes.find(statEl => {
				return isSameDay(new Date(fillEl.date), new Date(statEl.date));
			});

			if (el) return {...el, date: fillEl.date};

			return fillEl;
		})
		.reverse();
}
