import {useEffect, useState} from "react";
import {Modal} from "../../../components/primitives/Modal";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {stickersActions} from "../../../_store/features/stickers/stickers-slice";
import {reportedCommentsActions} from "../../../_store/features/comments/reported-comments-slice";
import {commentsActions} from "../../../_store/features/comments/comments-slice";
import {GetCommentsListParams} from "../../../data-access/comments/comments";
import {ReportedCommentsResult} from "../../../data-access/comments/reported-comments";
import {toast} from "react-hot-toast";
import {COMMENTS_TYPES} from "../../../constants/comments";

interface DetailsModalProps {
	onDismiss: () => void;
	open: boolean;
	id: string;
	returnParams?: GetCommentsListParams;
	editMode?: boolean;
	isReported?: boolean;
}

export function DetailsModal({open, onDismiss, id, editMode = false, isReported = false}: DetailsModalProps) {
	const reportedComment = useAppSelector(state => state.reportedComments.results.find(el => el.id === id));
	const comment = useAppSelector(state => state.comments.results.find(el => el.id === id));
	const data = isReported ? reportedComment : comment;
	const sticker = useAppSelector(state => state.stickers);
	const reported = useAppSelector(state => state.reportedComments);
	const serieContent = useAppSelector(state => state.serieContent);
	const shortContent = useAppSelector(state => state.shorts);

	const serieComment = serieContent.results.find(el => el.id === data?.content_id);
	const shortComment = shortContent.results.find(el => el.id === data?.content_id);
	const content = serieComment ? serieComment : shortComment;

	const isLoading = useAppSelector(state => state.comments.loading);
	const error = useAppSelector(state => state.comments.error);

	const [isSubmittedComment, SetIsSubmittedComment] = useState(false);

	const dispatch = useAppDispatch();

	function handleDismiss() {
		onDismiss?.();
	}

	useEffect(() => {
		if (!isLoading) {
			if (error) {
				toast.error("Error: " + error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const handleBlockComment = () => {
		if (window.confirm("¿Esta seguro que deseas bloquear el comentario?")) {
			if (data) {
				const contentUpdate = {type: COMMENTS_TYPES.AUTHOR_BANNED, payload: JSON.parse(data.content).payload};
				const contentUpdateJ = JSON.stringify(contentUpdate);
				dispatch(commentsActions.editComments({id: data.id, banned: true, content: contentUpdateJ})).then(response => {
					if (response.meta.requestStatus === "fulfilled") {
						toast.success("Comentario bloqueado correctamente.");
						handleDismiss();
					} else {
						toast.error("Error al bloquear el comentario.");
					}
				});
			}
		}
	};

	const handleRemoveComment = () => {
		if (window.confirm("¿Esta seguro que deseas eliminar el comentario?. Se eliminara todo el árbol asociado al comentario.")) {
			if (data) {
				dispatch(commentsActions.removeComments({commentid: data.id})).then(response => {
					if (response.meta.requestStatus === "fulfilled") {
						toast.success("Comentario eliminado correctamente.");
						handleDismiss();
					} else {
						toast.error("Error al eliminar el comentario.");
					}
				});
			}
		}
	};

	const handleChangeReport = (event: any, id: string) => {
		const value = event.target.value;
		SetIsSubmittedComment(true);
		dispatch(reportedCommentsActions.editReportedComments({id: id, status: value})).then(response => {
			if (response.meta.requestStatus === "fulfilled") {
				toast.success("Reporte actualizado correctamente.");
			}
		});
	};

	const getReportedComments = (data: ReportedCommentsResult) => {
		dispatch(reportedCommentsActions.getReportsForComment({searchby: "commentid", searchvalue: data.id}));
		if (JSON.parse(data.content).type === 1) {
			dispatch(stickersActions.getSticker({id: JSON.parse(data.content).payload}));
		}
	};

	useEffect(() => {
		reportedComment && getReportedComments(reportedComment);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportedComment]);

	return (
		<Modal title="Detalles y Reportes" open={open} onDismiss={handleDismiss} big>
			<div className="container w-full px-0.5 pr-4">
				<div className="flex">
					<div>
						<div className="mb-6">
							<div className="mb-3 flex w-full">
								<div className="mr-2 w-2/5 font-bold text-white">Fecha Publicación:</div>
								<div className="w-3/5 text-white">
									{data && new Date(data.created_at).toLocaleString(undefined, {dateStyle: "full", timeStyle: "short"})}
								</div>
							</div>
							<div className="mb-3 flex w-full">
								<div className="mr-2 w-2/5 font-bold text-white">Usuario:</div>
								<div className="w-3/5 text-white">{data?.author}</div>
							</div>
							<div className="mb-3 flex w-full">
								<div className="mr-2 w-2/5 font-bold text-white">Contenido:</div>
								<div className="w-3/5 text-white">
									<span className="overflow-hidden text-ellipsis">
										{content?.title} - {content?.serie_title} {content?.short_description}
									</span>
									<span className="overflow-hidden text-ellipsis text-neutral-500"></span>
								</div>
							</div>
							<div className="mb-3 flex w-full">
								<div className="mr-2 w-2/5 font-bold text-white">Comentario:</div>
								<div className="w-3/5 text-white">
									{data && JSON.parse(data.content).type !== 1 ? (
										JSON.parse(data.content).payload
									) : (
										<>
											{sticker.loading ? (
												<Spinner />
											) : (
												<>
													{sticker.results[0] ? (
														<img className="h-24" src={sticker.results[0].url} alt="Sticker del comentario" />
													) : (
														"Sticker no encontrado"
													)}
												</>
											)}
										</>
									)}
								</div>
							</div>
							<div className="mb-3 flex w-full">
								<div className="mr-2 w-2/5 font-bold text-white">Estado:</div>
								<div className="w-3/5 text-white">
									{data && JSON.parse(data.content).type === COMMENTS_TYPES.DELETED ? (
										<span className={"font-semibold leading-tight text-red-700"}>Eliminado</span>
									) : (
										<>
											{data && data.banned ? (
												<span className={"font-semibold leading-tight text-red-700"}>Bloqueado</span>
											) : (
												<>
													{data && data.number_reports > 0 ? (
														<span className={"font-semibold leading-tight text-yellow-700"}>Reportado</span>
													) : (
														<span className={"font-semibold leading-tight text-green-700"}>Activo</span>
													)}
												</>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="ml-3">
						<div className="mb-3 max-h-[390px] overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
							<div className="not-prose relative overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25">
								<div className="relative overflow-auto rounded-xl">
									<div className="my-3 overflow-hidden shadow-sm">
										<table className="w-full table-auto border-collapse text-sm">
											<thead>
												<tr>
													<th className="border-b p-4 pb-3 pl-8 pt-0 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
														Usuario
													</th>
													<th className="border-b p-4 pb-3 pt-0 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
														Motivo
													</th>
													<th className="border-b p-4 pb-3 pt-0 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
														Oculto
													</th>
													<th className="border-b p-4 pb-3 pr-8 pt-0 text-left font-medium text-slate-400 dark:border-slate-600 dark:text-slate-200">
														Estado
													</th>
												</tr>
											</thead>
											<tbody className="bg-white dark:bg-slate-800">
												{reported.loading && !isSubmittedComment ? (
													<tr>
														<td
															colSpan={4}
															className="border-b border-slate-100 p-4 py-2 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400"
														>
															<Spinner />
														</td>
													</tr>
												) : (
													<>
														{reported.reportsResult.length > 0 ? (
															<>
																{reported.reportsResult.map((option, index) => (
																	<tr key={index}>
																		<td className="border-b border-slate-100 p-4 py-2 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
																			{option.user_name}
																		</td>
																		<td className="border-b border-slate-100 p-4 py-2 text-slate-500 dark:border-slate-700 dark:text-slate-400">
																			{option.reason}
																		</td>
																		<td className="border-b border-slate-100 p-4 py-2 text-slate-500 dark:border-slate-700 dark:text-slate-400">
																			{option.hide ? "SI" : "NO"}
																		</td>
																		<td className="border-b border-slate-100 p-4 py-2 pr-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
																			{editMode === true ? (
																				<select
																					defaultValue={option.status}
																					onChange={e => handleChangeReport(e, option.id)}
																					title="Selecciona el estado"
																				>
																					<option value="pending">Pendiente</option>
																					<option value="enforced">Validado</option>
																					<option value="discarded">Rechazado</option>
																				</select>
																			) : (
																				<>
																					{option.status === "pending" && <span>Pendiente</span>}
																					{option.status === "enforced" && <span>Validado</span>}
																					{option.status === "discarded" && <span>Rechazado</span>}
																				</>
																			)}
																		</td>
																	</tr>
																))}
															</>
														) : (
															<tr className="text-center">
																<td
																	colSpan={4}
																	className="border-b border-slate-100 p-4 py-2 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400"
																>
																	Sin reportes registrados.
																</td>
															</tr>
														)}
													</>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isLoading ? (
					<Spinner />
				) : (
					<>
						{data && JSON.parse(data.content).type !== -5 && (
							<div className="flex justify-end">
								{data?.banned === false && (
									<button
										className="col-start-2 mr-4 flex items-center justify-center rounded bg-amber-200 px-4 py-2 uppercase transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
										onClick={() => handleBlockComment()}
									>
										Bloquear
									</button>
								)}
								<button
									className="col-start-2 flex items-center justify-center rounded bg-red-400 px-4 py-2 uppercase transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
									onClick={() => handleRemoveComment()}
								>
									Eliminar
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</Modal>
	);
}
