import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartData} from "chart.js";
import {addDays, differenceInDays, endOfDay, format, formatISO9075} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";
import {combineData, groupArrayByDay, thirtyDays, transformedData} from "../../../../../utils/barGraphTransformData";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface LikesAndFavoritesProps {
	seriesIds: string;
	contentId?: string;
	isCorporate?: boolean;
	dates?: DateRange;
}

export function LikesAndFavorites({seriesIds, contentId, isCorporate = false, dates}: LikesAndFavoritesProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; views: number}[]>>(null);
	const {data: statistics, isLoading} = useDashboardByNameQuery(contentId ? "contentLastMonthLikesAndFav" : "serieLastMonthLikesAndFav");
	const dispatch = useAppDispatch();

	const [showByMonths, setShowByMonths] = useState(false);

	useEffect(() => {
		if (contentId) {
			dispatch(
				dashboardActions.getContentLastMonthLikeAndFavorite({
					contentid: contentId,
					initial_date: isCorporate && dates ? formatISO9075(dates?.from!) : formatISO9075(addDays(Date.now(), -30)),
					final_date: isCorporate && dates ? formatISO9075(dates?.to!) : formatISO9075(endOfDay(new Date())),
				}),
			);
			return;
		}
		dispatch(
			dashboardActions.getSerieLastMonthLikeAndFavorite({
				seriesid_list: seriesIds,
				initial_date: isCorporate && dates ? formatISO9075(dates?.from!) : formatISO9075(addDays(Date.now(), -30)),
				final_date: isCorporate && dates ? formatISO9075(dates?.to!) : formatISO9075(endOfDay(new Date())),
			}),
		);
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > thirtyDays);
	}, [contentId, dispatch, seriesIds, dates, isCorporate]);

	const data: ChartData<"bar", {date: string; views: number}[]> = useMemo(() => {
		const startDate = dates ? new Date(dates?.from!) : addDays(Date.now(), -30);
		const endDate = dates ? new Date(dates?.to!) : new Date();
		const diff_days = differenceInDays(endDate, startDate) + 1;

		// Transforms the data returned from big query
		// views in this case represents the values of likes, favs, and dislikes.

		const dailyLikes =
			statistics?.daily_likes?.map(({day, month, year, hour, minute, second, total_day_likes}) => ({
				date: new Date(year, month - 1, day, hour, minute, second).toISOString(),
				views: total_day_likes,
			})) ?? [];

		const dailyDislikes =
			statistics?.daily_dislikes?.map(({day, month, year, hour, minute, second, total_day_dislikes}) => ({
				date: new Date(year, month - 1, day, hour, minute, second).toISOString(),
				views: total_day_dislikes,
			})) ?? [];

		const dailyFavs =
			statistics?.daily_favorite?.map(({day, month, year, hour, minute, second, total_day_favorite}) => ({
				date: new Date(year, month - 1, day, hour, minute, second).toISOString(),
				views: total_day_favorite,
			})) ?? [];

		// Group the dat by day
		const dailyDislikesGrouped = groupArrayByDay(dailyDislikes);
		const dailyLikesGrouped = groupArrayByDay(dailyLikes);
		const dailyFavsGrouped = groupArrayByDay(dailyFavs);

		// Creates a template data to be filled by the statistics
		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), views: 0}));

		// Combined data from statistics and filled.
		const dailyLikesData = combineData(fillerData, dailyLikesGrouped);
		const dailyDislikesData = combineData(fillerData, dailyDislikesGrouped);
		const dailyFavsData = combineData(fillerData, dailyFavsGrouped);

		return {
			datasets: [
				{
					label: "Likes",
					data: transformedData(fillerData, dailyLikesData, showByMonths),
					backgroundColor: "#088395",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "views",
					},
				},
				{
					label: "Dislikes",
					data: transformedData(fillerData, dailyDislikesData, showByMonths),
					backgroundColor: "#B30000",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "views",
					},
				},
				{
					label: "Follow",
					data: transformedData(fillerData, dailyFavsData, showByMonths),
					backgroundColor: "#FF6969",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "views",
					},
				},
			],
		};
	}, [statistics, dates, showByMonths]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 py-6 pt-0 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">
						{isCorporate ? "Likes, Dislikes y Follows." : "Likes, Dislikes y Follows últimos 30 días."}
					</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							ref={chartRef}
							height={350}
							options={{
								plugins: {
									legend: {
										position: "top",
									},
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												if (showByMonths) {
													const date = new Date(tooltipItems[0].label);
													const year = date.getFullYear();
													const month = date.getMonth() + 1;
													return format(new Date(year, month - 1), "yyyy, MMM");
												} else {
													return format(new Date(tooltipItems[0].label), "EE, dd MMMM");
												}
											},
										},
									},
								},
								responsive: true,
								maintainAspectRatio: false,
								interaction: {
									mode: "index" as const,
									intersect: false,
								},
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));
												const year = date.getFullYear();
												const month = date.getMonth() + 1;
												const day = date.getDate();

												// Format the date based on the available information
												const formattedDate = showByMonths
													? format(new Date(year, month - 1), "MMM").toLowerCase()
													: format(new Date(year, month - 1, day), "d MMM").toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
									},
									y: {
										ticks: {
											precision: 0,
										},
									},
								},
							}}
							data={data}
							id="likes"
						/>
					</div>
				)}
			</div>
		</div>
	);
}
