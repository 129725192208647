import "chartjs-adapter-date-fns";
import {formatISO9075, subYears} from "date-fns";
import {useEffect, useRef, useState} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../../_store/features/dashboard/hooks";
import {dashboardActions} from "../../../../../_store/features/dashboard/dashboard-slice";
import {Card} from "../../../../../components/blocks/Stats/Card";
import {Spinner, UserFilled} from "../../../../../components/primitives/icons";
import {DatePickerWithRange} from "../../../../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import {thirtyDays} from "../../../../../../utils/barGraphTransformData";
import InfluencerBarChart from "../../../../../components/blocks/Stats/InfluencerBarChart/index";

interface SerieIncomeAndProfitProps {
	influencerSeries: {
		active: boolean;
		serie_id: string;
		influencer_id: string;
		profit_percentage: number;
		audit_created?: string | undefined;
		audit_updated?: string | undefined;
	}[];
	influencerReferralCode: string | undefined;
	influencerReferUsersCount: number | undefined;
	currentSeriesId: string;
	dates: DateRange | undefined;
	setSelectedDate: (value: DateRange | undefined) => void;
}
export function InfluencerChartIncome({
	influencerSeries,
	influencerReferralCode,
	influencerReferUsersCount,
	dates,
	currentSeriesId,
	setSelectedDate,
}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; value: number}[]>>(null);
	const {data: statistics, isLoading: isStatisticsLoading} = useDashboardByNameQuery("influencerIncome");
	const [percentage, setPercentage] = useState(0);
	const [showByMonths, setShowByMonths] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > thirtyDays);

		const general_percentage = influencerSeries.reduce((acc, item) => acc + item.profit_percentage, 0) / influencerSeries.length;

		dispatch(
			dashboardActions.getInfluencerSerieIncomeAndProfit({
				seriesid_list: currentSeriesId,
				referral_code: influencerReferralCode || "",
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		).then(() => {
			setPercentage(general_percentage * 0.01);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dates, currentSeriesId]);
	
	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 md:grid-cols-2 lg:grid-cols-7">
			<div className="flex w-80 flex-row">
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					disabled={{before: subYears(new Date(), 1), after: new Date()}}
					date={dates}
				/>
			</div>
			<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-3">
				<Card
					title="Total de ingreso Bruto"
					amount={influencerReferralCode ? (statistics?.historic_profit ? statistics.historic_profit * 0.01 : 0) : 0}
					loading={influencerReferralCode ? isStatisticsLoading : false}
				/>
				<Card
					title="Total de ganancia del Influencer"
					amount={influencerReferralCode ? (statistics?.historic_profit ? statistics.historic_profit * 0.01 : 0) * (percentage ?? 0) : 0}
					loading={influencerReferralCode ? isStatisticsLoading : false}
				/>
				<Card
					title="Total de ganancia de la Plataforma"
					amount={
						influencerReferralCode
							? (statistics?.historic_profit ? statistics.historic_profit * 0.01 : 0) -
							  (statistics?.historic_profit ? statistics.historic_profit * 0.01 : 0) * (percentage ?? 0)
							: 0
					}
					loading={influencerReferralCode ? isStatisticsLoading : false}
				/>
			</div>
			<div className="col-span-full flex w-full items-center justify-start gap-4 rounded-md border border-border p-6">
				<div className="mr-2 aspect-square rounded-lg bg-foreground p-2 text-secondary">
					<UserFilled className="h-4 w-4" />
				</div>
				<div className="flex items-center gap-2 text-lg font-semibold">
					{influencerReferralCode ? (
						<>
							<span>Referidos registrados:</span>
							<span>{influencerReferUsersCount}</span>
						</>
					) : (
						<span className="text-warning">Influencer sin código de referido.</span>
					)}
				</div>
			</div>
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ganancias por compras de los referidos.</h3>
				</div>
				{isStatisticsLoading ? (
					<div className="flex h-[400px] flex-col items-center justify-center">
						{!influencerReferralCode ? (
							<h1 className="ext-lg text-xl font-semibold leading-none tracking-tight text-warning">
								Influencer sin código de referido
							</h1>
						) : (
							<Spinner />
						)}
					</div>
				) : (
					<InfluencerBarChart chartRef={chartRef} dates={dates} showByMonths={showByMonths} percentage={percentage} />
				)}
			</div>
		</div>
	);
}
