import axios, {AxiosResponse} from "axios";
import {z} from "zod";
import {shortResult} from "../../_store/features/shorts/shorts-slice";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetShortsParamsList {
	page?: number;
	page_size?: number;
	searchby?: "title" | "short_description" | "long_description" | "id";
	searchvalue?: string;
	published?: boolean;
	estreno?: boolean;
	tags?: string;
}

export async function getShorts(token: string, params?: GetShortsParamsList) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_short_videos_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export const InsertShortsParamListBodySchema = z.object({
	title: z.string().nonempty("El titulo no puede estar vacío."),
	slugs: z.string().nonempty("El slug no puede estar vacío."),
	order: z.number().optional(),
	ranking: z.number().optional(),
	estreno: z.boolean().optional(),
	short_description: z.string().optional(),
	long_description: z.string().optional(),
	video_id: z.string().optional(),
	duration: z.number().optional(),
	lowres_image: z.string().optional(),
	rectangular_image: z.string().optional(),
	release_date: z.string().optional(),
});

export type InsertShortsDataBodyType = z.infer<typeof InsertShortsParamListBodySchema>;

export async function insertShorts(token: string, data: InsertShortsDataBodyType): Promise<AxiosResponse<shortResult>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_short_video_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadShortsImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_short_videos_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}

export const EditShortsDataBodySchema = z.object({
	id: z.string(),
	title: z.string(),
	slugs: z.string(),
	order: z.number(),
	ranking: z.number(),
	estreno: z.boolean(),
	published: z.boolean(),
	short_description: z.string(),
	long_description: z.string(),
	video_id: z.string(),
	duration: z.number(),
	lowres_image: z.string(),
	rectangular_image: z.string(),
	link: z.string().nullable(),
	release_date: z.string(),
});

export type EditShortsDataBodyType = z.infer<typeof EditShortsDataBodySchema>;

export async function editShorts(token: string, data: EditShortsDataBodyType): Promise<AxiosResponse<shortResult>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_short_video_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
