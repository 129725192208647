import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {MEMBERSHIP_TYPES} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, roundToNearestMinutes, subYears} from "date-fns";
import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import Select, {ActionMeta, SingleValue} from "react-select";
import {selectStyles} from "../../../../utils/SelectStyles";
import {seriesActions} from "../../../_store/features/series/series-slice";
import {Button} from "../../../components/primitives/Button";
import {useNavigate} from "react-router-dom";
import {coinProductsActions} from "../../../_store/features/products/coin-products-slice";
import {formattedDayAndHour} from "../../../../utils/formattedDayAndHour";
import {SelectOptionType} from "../../../../utils/globalTypes";

const columnHelper = createColumnHelper<any>();

const selectMemberships: SelectOptionType[] = [
	{label: "Todos", value: "all"},
	{label: "Membresía", value: "memberships"},
	{label: "Coins", value: "coins"},
];

const selectMembershipType: SelectOptionType[] = [
	{label: "Todas", value: "all"},
	{label: "Miembro", value: "1"},
	{label: "Miembro Vip", value: "2"},
	{label: "Upgrade", value: "3"},
];

const index = [0, 1, 2, 3];

function PurchasesDetail() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const series = useAppSelector(state => state.series.results);
	const coins = useAppSelector(state => state.coinProducts.results);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const selectInputRefs = useRef<any[]>([]);
	const {isLoading: tableLoading, data: tableData} = useDashboardByNameQuery("profitCorporate");
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [serieId, setSerieId] = useState<string | undefined>();
	const [coinId, setCoinId] = useState<string | undefined>();

	const [onlyGet, setOnlyGet] = useState<"memberships" | "coins" | "all">("all");
	const [membershipType, setMembershipType] = useState<string>();
	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = new Date(2023, 0, 1);
		return {
			from,
			to,
		};
	});

	const seriesOptions: SelectOptionType[] = useMemo(() => {
		const options = series.map(serie => ({label: serie.title, value: serie.id}));
		options.unshift({label: "Todo", value: ""});
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.length]);

	const coinsOptions: SelectOptionType[] = useMemo(() => {
		const options = coins.map(coin => ({label: coin.name, value: coin.id}));
		options.unshift({label: "Todo", value: ""});
		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coins.length]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({params: {page: 0, page_size: 99999999}}));
		dispatch(coinProductsActions.getCoinProducts({page: 0, page_size: 99999999}));
	}, [dispatch]);

	useLayoutEffect(() => {
		if (!pagination.pageSize) return;
		dispatch(
			dashboardActions.getProfitCorporateTransactions({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				seriesid: serieId,
				only_get: onlyGet,
				membership_type: membershipType,
				product_coins_id: coinId,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dates, pagination]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	function resetSelectors() {
		setCoinId(undefined);
		setOnlyGet("all");
		setSerieId(undefined);
		setMembershipType(undefined);
	}

	function handleSelectContent(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.value !== "") {
				setSerieId(newValue.value);
			} else {
				resetSelectors();
				index.forEach(index => {
					selectInputRefs.current[index].clearValue();
				});
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}

	function handleSelectCoins(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.value !== "") {
				setCoinId(newValue.value);
				setOnlyGet("coins");
			} else {
				resetSelectors();
				index.forEach(index => {
					selectInputRefs.current[index].clearValue();
				});
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}

	function handleSelectPurchaseType(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.value !== "all") {
				setOnlyGet(newValue.value as "memberships" | "coins");
			} else {
				resetSelectors();
				index.forEach(index => {
					selectInputRefs.current[index].clearValue();
				});
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}
	function handleSelectMembershipType(newValue: SingleValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue) {
			if (newValue.value !== "all") {
				setMembershipType(newValue.value);
				setOnlyGet("memberships");
			} else {
				resetSelectors();
				index.forEach(index => {
					selectInputRefs.current[index].clearValue();
				});
			}
			setPagination(prev => {
				return {
					...prev,
					pageIndex: 0,
				};
			});
		}
	}

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 5);

		return [
			columnHelper.accessor("user_name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.user_name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("membership_type", {
				id: "Compra",
				header: ({column}) => <DataTableColumnHeader title="Compra" column={column} />,
				cell: info => {
					const membershipValue = info.row.original.membership_type;
					const membership = MEMBERSHIP_TYPES.find(type => type.value === membershipValue);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">
								{membership ? membership.label : info.row.original.product_coins_name}
							</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("price", {
				id: "Precio",
				header: ({column}) => <DataTableColumnHeader title="Precio" column={column} />,
				cell: info => {
					const price = (info.row.original.price / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {price}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("title", {
				id: "Título",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => {
					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.title || "-"}</span>
						</div>
					);
				},
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Compras Realizadas</h2>
			</div>
			<div className="flex h-full flex-col">
				<div className="flex flex-row justify-between px-4">
					<DatePickerWithRange
						onDateRangeChange={handleDateRangeChange}
						disabled={{before: subYears(new Date(), 1), after: new Date()}}
						date={dates}
						className="left-0 w-80 py-6"
					/>
					<div className="flex flex-row">
						<Select
							ref={ref => {
								selectInputRefs.current[0] = ref;
							}}
							className="basic-select  mx-4 my-7 w-full min-w-[200px] text-sm"
							styles={selectStyles}
							options={selectMemberships as any}
							onChange={handleSelectPurchaseType}
							placeholder="Tipo de compra"
						/>

						<Select
							ref={ref => {
								selectInputRefs.current[1] = ref;
							}}
							className="basic-select mx-4 my-7 w-full min-w-[200px] text-sm"
							styles={selectStyles}
							options={selectMembershipType as any}
							onChange={handleSelectMembershipType}
							placeholder="Tipo de membresía"
						/>

						<Select
							ref={ref => {
								selectInputRefs.current[2] = ref;
							}}
							className="basic-select mx-4 my-7 w-full text-sm"
							styles={selectStyles}
							isSearchable={true}
							options={seriesOptions}
							classNamePrefix="select"
							onChange={handleSelectContent}
							placeholder="Contenido"
						/>

						<Select
							ref={ref => {
								selectInputRefs.current[3] = ref;
							}}
							className="basic-select mx-4 my-7 w-full  min-w-[200px] text-sm"
							styles={selectStyles}
							isSearchable={true}
							options={coinsOptions}
							classNamePrefix="select"
							onChange={handleSelectCoins}
							placeholder="Paquete Coins"
						/>
					</div>
				</div>

				<div className="flex h-full grow-0 flex-col p-8 pt-4">
					<div className="flex flex-row">
						<h3 className="p-2 font-semibold">Total de resultados: {tableData?.totalResults}</h3>
						<h3 className="p-2 font-semibold">
							Total profit: ${tableData?._total_amount ? (tableData?._total_amount * 0.01).toFixed(2) : 0}
						</h3>
					</div>
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={tableData?.results || []}
						pageCount={Math.ceil((tableData?.totalResults || 0) / pagination.pageSize)}
						pagination={pagination}
						onPaginationChange={setPagination}
						withDynamicPageSize
						rowHeight={40}
						showPagination={false}
						loading={tableLoading}
					/>
				</div>

				<div className="flex flex-row justify-end px-4 pb-4">
					<Button
						className="mr-2 w-32"
						size="sm"
						variant="outline"
						onClick={() => {
							navigate(-1);
						}}
					>
						Cancelar
					</Button>
				</div>
			</div>
		</div>
	);
}

export default PurchasesDetail;
