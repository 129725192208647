import React, {RefObject, useMemo} from "react";
import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {addDays, differenceInDays, endOfDay, format, isSameDay, parse} from "date-fns";
import {Bar} from "react-chartjs-2";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {DateRange} from "react-day-picker";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface MonthlyData {
	[key: string]: {
		date: string;
		value: number;
	};
}

interface InfluencerBarChartProps {
	chartRef: RefObject<
		ChartJSOrUndefined<
			"bar",
			{
				date: string;
				value: number;
			}[]
		>
	>;
	dates: DateRange | undefined;
	showByMonths: boolean;
	percentage: number;
}
export default function InfluencerBarChart({chartRef, dates, showByMonths, percentage}: InfluencerBarChartProps) {
	const {data: statistics} = useDashboardByNameQuery("influencerIncome");

	const data: ChartData<"bar", {date: string; value: number}[]> | null = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		//Transforms the data returned from big query
		const dailyProfit =
			statistics?.daily_profit?.map(({day, month, year, hour, minute, second, profit}) => ({
				date: new Date(year, month - 1, day, hour, minute, second).toISOString(),
				value: profit * 0.01,
			})) ?? [];

		// Group elements by day in dailyProfit
		const dailyProfitGrouped = dailyProfit.reduce((acc: {date: string; value: number}[], curr) => {
			const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
			if (existingDayData) {
				existingDayData.value += curr.value;
			} else {
				acc.push(curr);
			}
			return acc;
		}, []);

		//Creates a template data to be filled by the statistics
		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), value: 0}));

		//Combined data from statistics and filled.
		const dailyProfitData = fillerData
			.map(fillEl => {
				const el = dailyProfitGrouped.find(statEl => {
					return isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return {...el, date: fillEl.date};

				return fillEl;
			})
			.reverse();

		const monthlyProfitData = dailyProfitGrouped.reduce((acc: MonthlyData, dataPoint) => {
			const date = new Date(dataPoint.date);
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!acc[key]) {
				acc[key] = {
					date: `${year}-${monthString}`,
					value: 0,
				};
			}

			acc[key].value += dataPoint.value;

			return acc;
		}, {});

		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const monthString = month < 10 ? `0${month}` : `${month}`;
			const key = `${year}-${monthString}`;

			if (!monthlyProfitData[key]) {
				monthlyProfitData[key] = {
					date: `${year}-${monthString}`,
					value: 0,
				};
			}

			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		const monthlyDataArray = Object.values(monthlyProfitData);

		monthlyDataArray.sort((a, b) => {
			const dateA = parse(a.date, "yyyy-MM", new Date());
			const dateB = parse(b.date, "yyyy-MM", new Date());

			return dateA.getTime() - dateB.getTime();
		});

		const selectedData = showByMonths ? monthlyDataArray : dailyProfitData;

		return {
			datasets: [
				{
					label: "Pago por referidos",
					data: selectedData,
					backgroundColor: "#a3a3a35a",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
					order: 2,
				},
				{
					label: "Ganancia",
					data: selectedData.map(el => ({
						...el,
						value: el.value * percentage,
					})),
					backgroundColor: "#4BC0C0",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
					order: 1,
				},
			],
		};
	}, [dates?.from, dates?.to, statistics?.daily_profit, showByMonths, percentage]);

	return (
		<div className="relative p-6 pt-0">
			<Bar
				height={350}
				ref={chartRef}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						tooltip: {
							callbacks: {
								title(tooltipItems) {
									if (showByMonths) {
										const date = new Date(tooltipItems[0].label);
										const year = date.getFullYear();
										const month = date.getMonth() + 1;
										return format(new Date(year, month), "yyyy, MMM");
									} else {
										return format(new Date(tooltipItems[0].label), "EE, dd MMMM");
									}
								},
								label: function (context) {
									let label = context.dataset.label || "";

									if (label) {
										label += ": ";
									}
									if (context.parsed.y !== null) {
										label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
									}
									return label;
								},
							},
						},
					},
					scales: {
						x: {
							ticks: {
								callback(tickValue) {
									const date = new Date(this.getLabelForValue(tickValue as any));
									const year = date.getFullYear();
									const month = date.getMonth() + 1;
									const day = date.getDate();

									// Format the date based on the available information
									const formattedDate = showByMonths
										? format(new Date(year, month), "MMM").toLowerCase()
										: format(new Date(year, month - 1, day), "d MMM").toLowerCase();

									return formattedDate;
								},
							},
							grid: {
								color: "transparent",
							},
							stacked: true,
						},
					},
				}}
				data={data}
			/>
		</div>
	);
}
