export const MEMBERSHIP_TYPES = [
	{label: "Free", value: 0},
	{label: "Miembro", value: 1},
	{label: "Miembro VIP", value: 2},
	{label: "Upgrade", value: 3},
];

export const CONTENT_TYPES = [
	{label: "Capitulo", value: 0},
	{label: "Preview", value: 1},
	{label: "Contenido", value: 2},
	{label: "Short", value: 3},
	{label: "Stream", value: 4},
	{label: "Live Stream", value: 5},
];

export const PREMIERE_TYPES = [
	{label: "Estreno", value: 0},
	{label: "Estreno en Vivo", value: 1},
];

export const PROVIDERS: Record<string, string> = {
	password: "Password",
	"google.com": "Google",
	"apple.com": "Apple",
	"facebook.com": "Facebook",
};

export const PUBLICITY_TYPES = [
	{label: "General", value: 0},
	{label: "Sponsor", value: 1},
];

export enum CONTENT {
	CAPITULO = 0,
	PREVIEW = 1,
	CONTENT = 2,
	SHORT = 3,
	STREAM = 4,
	LIVE_STREAM = 5,
}
