import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export const FOLDERS = [
	"cast",
	"content",
	"design",
	"profiles",
	"publicity",
	"serie/banners",
	"serie/logos",
	"serie/thumbnails",
	"staff",
	"stickers",
	"short_videos",
] as const;

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export interface FolderData {
	url: string;
	name: string;
	size: string;
	timeCreated: string;
}

interface GetFolderContentListResponse {
	totalFiles: number;
	pageSize: number;
	results: FolderData[];
	queryPageToken: string | null;
}

export interface GetFolderContentListParams {
	page_size?: number;
	next_page_token?: string | null;
	folder: ArrayElement<typeof FOLDERS> | string;
}

export async function administrativeListFolder(
	token: string,
	params?: GetFolderContentListParams,
): Promise<AxiosResponse<GetFolderContentListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/administrative_list_folder", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
