import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface GetSerieIncomeAndProfitParams {
	seriesid_list?: string;
	// Format: YYYY-MM-DD
	initial_date: string;
	// Format: YYYY-MM-DD
	final_date: string;
}

export interface GetGeneralIncomeAndProfitParams {
	seriesid?: string;
	initial_date: string;
	final_date: string;
}

export interface DailyProfit {
	profit: number;
	production_cost?: number;
	day: number;
	month: number;
	year: number;
	hour: number;
	minute: number;
	second: number;
}

export interface GetSerieIncomeAndProfitResponse {
	total_profit: number;
	range_profit: number;
	daily_profit: DailyProfit[];
}

export interface GetGeneralIncomeAndProfitResponse {
	production_cost: number | null;
	total_profit_memberships: number | null;
	range_profit_memberships: number | null;
	total_profit_coins: number | null;
	range_profit_coins: number | null;
	daily_profit_memberships: DailyProfit[];
	daily_profit_coins: DailyProfit[];
	daily_cost: DailyProfit[] | null;
}

export async function getGeneralIncomeAndProfit(
	token: string,
	params?: GetGeneralIncomeAndProfitParams,
): Promise<AxiosResponse<GetGeneralIncomeAndProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_corporate_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSerieIncomeAndProfit(
	token: string,
	params?: GetSerieIncomeAndProfitParams,
): Promise<AxiosResponse<GetSerieIncomeAndProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_partner_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetSerieLikesAndFavoritesParams {
	seriesid_list: string;
	initial_date: string;
	final_date: string;
}

interface DailyLikes extends Exclude<DailyProfit, "profit" | "production_cost"> {
	total_day_likes: number;
}

interface DailyFavorites extends Exclude<DailyProfit, "profit" | "production_cost"> {
	total_day_favorite: number;
}

interface DailyDislikes extends Exclude<DailyProfit, "profit" | "production_cost"> {
	total_day_dislikes: number;
}

export interface GetSeriesAndContentInteractionResponse {
	daily_likes: DailyLikes[];
	daily_favorite: DailyFavorites[];
	daily_dislikes: DailyDislikes[];
}

export async function getSeriesLikesAndFavorites(
	token: string,
	params?: GetSerieLikesAndFavoritesParams,
): Promise<AxiosResponse<GetSeriesAndContentInteractionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_likes_favorite", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetContentLikesAndFavoritesParams {
	contentid: string;
	initial_date: string;
	final_date: string;
}

export async function getContentsLikesAndFavorites(
	token: string,
	params?: GetContentLikesAndFavoritesParams,
): Promise<AxiosResponse<GetSeriesAndContentInteractionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_content_likes_favorite", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetSponsorAdViewParams {
	sponsorid?: string;
	adid?: string;
	initial_date: string;
	final_date: string;
}

interface GroupedViews {
	views: number;
	seriesid?: string;
	adid?: string;
	date: string;
}

export interface GetSponsorAdViewResponse {
	daily_views: GroupedViews[];
}

export async function getSponsorAdViewsData(token: string, params?: GetSponsorAdViewParams): Promise<AxiosResponse<GetSponsorAdViewResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_sponsor_ad_views", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSponsorAdViewsByAdsData(token: string, params?: GetSponsorAdViewParams): Promise<AxiosResponse<GetSponsorAdViewResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_sponsor_ad_views_by_ads", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetInfluencerProfitParams {
	referral_code: string;
	seriesid_list: string;
	initial_date: string;
	final_date: string;
}

export interface GetInfluencerProfitResponse {
	historic_profit: number;
	range_profit: number;
	daily_profit: DailyProfit[];
}

export async function gGetInfluencerProfitData(
	token: string,
	params?: GetInfluencerProfitParams,
): Promise<AxiosResponse<GetInfluencerProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_influencer_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetSeriesStatsParams {
	series_list: string[];
}

export type GetSeriesStatsResponse = {
	seriesid: string;
	title: string;
	img_thumbnail: string;
	likes: number;
	favorites: number;
	members: number;
}[];

export async function getSeriesStatsData(token: string, params?: GetSeriesStatsParams): Promise<AxiosResponse<GetSeriesStatsResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_stats_series_list", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetInfluencerAndPartnerTransactionsParams {
	influencer_id?: string;
	seriesid_list: string;
	initial_date?: string;
	final_date?: string;
}

export interface GetSerieContentEarnings {
	seriesid: string;
	initial_date?: string;
	final_date?: string;
	content_type?: string;
}

export interface SeriesContentEarningsResponse {
	title: string;
	stickers: number;
	contentid: string;
	donations: number;
	stickers_count: number;
	donations_count: number;
}

export interface GetInfluencerAndPartnerTransactionsResponse {
	name: string;
	created_at: string;
	membership_type: number;
	price: number;
	title: string;
}

export interface ResultList {
	influencer_id: string;
	referral_code: string;
	photo_url: null | string;
	name: string;
	profit_percentage: number;
	serie_id: string;
}

export interface InfluencersBySeriesProfit {
	influencer_id: string;
	photo_url: string;
	name: string;
	active: boolean;
	referral_code: string;
	total_profit: number;
	series: {
		active: boolean;
		personal_profit: number;
		production_cost: number;
		profit_percentage: number;
		series_id: string;
		series_name: string;
		series_profit: number;
		series_profit_pg: null;
	}[];
}
export interface InfluencersBySeriesProfitResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: InfluencersBySeriesProfit[];
}

export async function getLatestTransactionsDetails(
	token: string,
	params?: GetInfluencerAndPartnerTransactionsParams,
): Promise<AxiosResponse<GetInfluencerAndPartnerTransactionsResponse[]>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_membership_list_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface GetProfitCorporateParams {
	initial_date: string;
	final_date: string;
	page_size: number;
	page: number;
	referral_code?: string;
	seriesid?: string;
	only_get: "memberships" | "coins" | "all";
	membership_type?: string;
	product_coins_id?: string;
	product_coins_coins?: string;
}

export interface GetProfitCorporateResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: CorporateProfitResult[];
	_total_amount: number;
}

export interface CorporateProfitResult {
	id?: string;
	user_name: string;
	coins?: number;
	price: number;
	created_at: string;
	payment_id?: string;
	referral_code: string;
	title?: string;
	membership_type?: number;
	product_coins_id?: string;
	product_coins_name?: string;
}

export async function getProfitCorporateListData(
	token: string,
	params?: GetProfitCorporateParams,
): Promise<AxiosResponse<GetProfitCorporateResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_profit_corporate_list_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getSeriesContentEarnings(
	token: string,
	params?: GetSerieContentEarnings,
): Promise<AxiosResponse<{result: SeriesContentEarningsResponse[]}>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_series_content_coins", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface InfluencersBySeriesProfitParams {
	seriesid?: string;
	initial_date?: string;
	final_date?: string;
	searchby?: "influencer_name" | "series_name" | "seriesid";
	searchvalue?: string;
	page?: number;
	page_size?: number;
	order?: "asc" | "desc";
	orderby?: "name" | "profits";
}

export interface SponsorBySeriesProfitParams {
	seriesid?: string;
	initial_date?: string;
	final_date?: string;
	searchby?: "sponsor_name" | "series_name";
	searchvalue?: string;
	order?: "asc" | "desc";
	orderby?: "name" | "profits";
}

export async function getInfluencerProfit(
	token: string,
	params: InfluencersBySeriesProfitParams | undefined,
): Promise<AxiosResponse<InfluencersBySeriesProfitResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_series_influencer_profit", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
