import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {administrativeListFolder, FolderData, FOLDERS, GetFolderContentListParams} from "../../../data-access/multimedia/gallery";
import {auth} from "../../../firebase";
import {uploadPublicityImage} from "../../../data-access/advertisers";
import {uploadCastImage} from "../../../data-access/series/cast";
import {uploadContentImage} from "../../../data-access/series/content";
import {uploadSeriesImage} from "../../../data-access/series/series";
import {HttpStatusCode} from "axios";

export interface imageGalleryInterface {
	totalFiles: number;
	pageSize: number;
	results: FolderData[];
	queryPageToken: string | null;
	loading: boolean;
	uploading: boolean;
	uploadError: string;
}

const initialState: imageGalleryInterface = {
	totalFiles: 0,
	pageSize: 0,
	results: [],
	queryPageToken: null,
	loading: false,
	uploading: false,
	uploadError: "",
};

const getGalleryMedia = createAsyncThunk("gallery/list", async (params: GetFolderContentListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await administrativeListFolder(token, params);

		return {
			pageSize: response.data.pageSize!,
			queryPageToken: response.data.queryPageToken!,
			results: response.data.results.filter((item: FolderData) => !/-w100.|-w200.|-w400.|-w800.|-w1800./.test(item.url)),
			totalFiles: response.data.totalFiles!,
			totalResults: response.data.results.length,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertGalleryMedia = createAsyncThunk("gallery/upload", async (data: {file: any; tab: string; onSuccess: () => void}, thunkAPI) => {
	const body = new FormData();
	const {file, tab, onSuccess} = data;

	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		if (tab === FOLDERS[4]) {
			body.append("file", file);
			body.append("type", "img_web");
			body.append("publicityid", crypto.randomUUID());

			const res = await uploadPublicityImage(token, body);
			if (res.status === HttpStatusCode.Created) {
				onSuccess();
			}
			return res.data;
		}

		if (tab === FOLDERS[0]) {
			body.append("file", file);
			body.append("castid", crypto.randomUUID());

			const res = await uploadCastImage(token, body);
			if (res.status === HttpStatusCode.Created) {
				onSuccess();
			}
			return res.data;
		}

		if (tab === FOLDERS[5]) {
			body.append("file", file);
			body.append("type", "img_banner_web_mobile");
			body.append("serieid", crypto.randomUUID());

			const res = await uploadSeriesImage(token, body);
			if (res.status === HttpStatusCode.Created) {
				onSuccess();
			}
			return res.data;
		}

		if (tab === FOLDERS[6]) {
			body.append("file", file);
			body.append("type", "img_logo");
			body.append("serieid", crypto.randomUUID());

			const res = await uploadSeriesImage(token, body);
			if (res.status === HttpStatusCode.Created) {
				onSuccess();
			}
			return res.data;
		}

		if (tab === FOLDERS[7]) {
			body.append("file", file);
			body.append("type", "img_thumbnail");
			body.append("serieid", crypto.randomUUID());

			const res = await uploadSeriesImage(token, body);
			if (res.status === HttpStatusCode.Created) {
				onSuccess();
			}
			return res.data;
		}

		if (tab === FOLDERS[1]) {
			body.append("file", file);
			body.append("type", "rectangular_image");
			body.append("contentid", crypto.randomUUID());

			const res = await uploadContentImage(token, body);
			if (res.status === HttpStatusCode.Created) {
				onSuccess();
			}
			return res.data;
		}
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem uploading image");
	}
});

const gallerySlice = createSlice({
	name: "gallery",
	initialState,
	reducers: {
		resetToInitial: () => initialState,
		setError: (state, action) => {
			state.uploadError = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getGalleryMedia.pending, state => {
				state.loading = true;
			})
			.addCase(getGalleryMedia.fulfilled, (state, action) => {
				state.loading = false;
				state.pageSize = action.payload?.pageSize!;
				state.queryPageToken = action.payload?.queryPageToken!;
				state.results = state.results
					.concat(action.payload?.results!)
					.sort((a, b) => new Date(b.timeCreated).valueOf() - new Date(a.timeCreated).valueOf());
				state.totalFiles =
					state.totalFiles !== 0
						? state.totalFiles! - action.payload?.totalResults! + action.payload?.results.length!
						: action.payload?.totalFiles! - action.payload?.totalResults! + action.payload?.results.length! + 1;
			})
			.addCase(getGalleryMedia.rejected, (state, action) => {});

		builder
			.addCase(insertGalleryMedia.pending, state => {
				state.uploading = true;
			})
			.addCase(insertGalleryMedia.fulfilled, (state, action) => {
				state.uploading = false;
				state.results.unshift({
					url: action.payload.result,
					name: "Just Uploaded",
					size: "unknown",
					timeCreated: new Date().toString(),
				});
			})
			.addCase(insertGalleryMedia.rejected, (state, action) => {
				state.uploading = false;
				state.uploadError = action.payload as string;
			});
	},
});

export const galleryActions = {
	...gallerySlice.actions,
	getGalleryMedia,
	insertGalleryMedia,
};

export default gallerySlice;
