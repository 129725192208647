import axios, {AxiosResponse} from "axios";
import {dbUser} from "../_store/features/user-db/user-db-slice";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: process.env.REACT_APP_APP_DOMAIN});

export const fetchDbUsers = async ({
	idToken,
	page,
	page_size,
	admin,
	searchby,
	searchvalue,
	role,
	active,
	associate,
}: {
	idToken: string;
	page: number;
	page_size?: number;
	admin: boolean;
	searchby?: string;
	searchvalue?: string;
	role?: string;
	active?: boolean;
	associate?: boolean;
}) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/list_db_users`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${idToken}`,
		},
		params: {
			admin,
			page: page,
			searchby: searchby,
			searchvalue: searchvalue,
			role: role,
			active,
			page_size,
			associate,
		},
	});
};

export const updateDbUsers = async (idToken: string, userData: dbUser, admin: boolean) => {
	return await axios.patch(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_update_user_data`,
		{
			...userData,
			admin: admin,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export interface UpdateProfileBody {
	email: string;
	email_verified?: boolean;
	password?: string;
	disabled?: boolean;
	name: string;
	phone: string;
	phone_verified?: boolean;
	coin?: number;
	provider?: string;
	referral_code?: string;
}

export const updateProfile = async (idToken: string, data: UpdateProfileBody) => {
	return await axios.patch(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_update_profile_data`,
		{
			...data,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export const createAdminPasswordRequest = async (email: string, recaptchaToken: string, action: string, admin: boolean = false) => {
	return await axios.post(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_password_email_request`,
		{
			email: email,
			recaptchatoken: recaptchaToken,
			action: action,
			admin: admin,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
			},
		},
	);
};

export const adminUpdatePassword = async (idToken: string, userid: string, password: string, admin: boolean) => {
	return await axios.patch(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_update_password`,
		{
			userid: userid,
			password: password,
			admin: admin,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: `Bearer ${idToken}`,
			},
		},
	);
};

export const execPasswordReset = async (hash: string, password: string, admin: boolean = false) => {
	return await axios.post(
		`${process.env.REACT_APP_APP_DOMAIN as string}/admin_password_email_reset`,
		{hash, password, admin},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
			},
		},
	);
};

export const NewUserBodySchema = z.object(
	{
		email: z.string().email({message: "El email es invalido."}),
		email_verified: z.boolean(),
		password: z.string().min(6, "La contraseña debe tener un mínimo de 6 caracteres."),
		disabled: z.boolean(),
		name: z.string().nonempty("El nombre de usuario es requerido."),
		phone: z.string(),
		phone_verified: z.boolean(),
		coin: z.number(),
		admin: z.boolean(),
		master_editor: z.boolean(),
		editor: z.boolean(),
		partner: z.boolean(),
		sponsor: z.boolean(),
		influencer: z.boolean(),
		customer_service: z.boolean(),
		marketing: z.boolean(),
		associate: z.boolean(),
		corporate: z.boolean(),
	},
	{required_error: "Este campo es requerido."},
);
export type NewUserBodyType = z.infer<typeof NewUserBodySchema>;
export type ROLES = keyof Pick<
	NewUserBodyType,
	"admin" | "master_editor" | "editor" | "partner" | "sponsor" | "influencer" | "customer_service" | "marketing" | "corporate"
>;

interface NewUserBodyResponse {
	coin: number;
	name: string;
	email: string;
	phone: string;
	userid: string;
	disabled: boolean;
	provider: string;
	photo_url: string;
	verify_hash: string;
	audit_created: string;
	audit_updated: string;
	password_hash: string;
	email_verified: boolean;
	phone_verified: boolean;
	admin: boolean;
	master_editor: boolean;
	editor: boolean;
	partner: boolean;
	sponsor: boolean;
	influencer: boolean;
	customer_service: boolean;
	marketing: boolean;
}

export const adminCreateUser = async (token: string, data: NewUserBodyType): Promise<AxiosResponse<NewUserBodyResponse>> => {
	return await axiosHandler.post("/admin_create_user", data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

interface ExportUserListParams {
	email: undefined | boolean;
	name: undefined | boolean;
	phone: undefined | boolean;
	coin: undefined | boolean;
	disabled: undefined | boolean;
	email_verified: undefined | boolean;
	phone_verified: undefined | boolean;
}

export const exportUserList = async (token: string, params: ExportUserListParams) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/export_user_list`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${token}`,
		},
		params,
	});
};

interface ExportAdminListParams {
	email: undefined | boolean;
	name: undefined | boolean;
	phone: undefined | boolean;
	coin: undefined | boolean;
	disabled: undefined | boolean;
	email_verified: undefined | boolean;
	phone_verified: undefined | boolean;
}

export const exportAdminList = async (token: string, params: ExportAdminListParams) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/export_admin_list`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${token}`,
		},
		params,
	});
};

export const sponsorsList = async (token: string, params: null) => {
	return await axios.get(`${process.env.REACT_APP_APP_DOMAIN as string}/list_db_users_sponsor`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `Bearer ${token}`,
		},
		params,
	});
};
