import {Key, useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {useSearchParams} from "react-router-dom";
import {FOLDERS} from "../../../data-access/multimedia/gallery";
import {galleryActions} from "../../../_store/features/gallery/gallery-slice";
import {Check, Folder, FolderOpen, Spinner} from "../../primitives/icons";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../primitives/Dialog";
import {Button} from "../../primitives/Button";
import {DotLottiePlayer as LottiePlayer} from "@dotlottie/react-player";

interface MultimediaImagePickerProps {
	open?: boolean;
	tabIndex: number;
	folderNames: {name: string; value: (typeof FOLDERS)[number]}[];
	acceptExtensions?: string[];
	onDismiss?: () => void;
	onSuccess: (e: string) => void;
}

function MultimediaImagePickerModal({onDismiss, onSuccess, tabIndex, folderNames, open, acceptExtensions}: MultimediaImagePickerProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const nextPageToken = useAppSelector(state => state.gallery.queryPageToken);
	const folderData = useAppSelector(state => state.gallery.results);
	const totalResult = useAppSelector(state => state.gallery.totalFiles);
	const loading = useAppSelector(state => state.gallery.loading);
	const firstRender = useRef(true);
	const [selectedImage, setSelectedImage] = useState<string>("");
	const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
	const observer = useRef<IntersectionObserver>();

	const dispatch = useAppDispatch();

	const handleSave = useCallback(() => {
		onSuccess(selectedImage);
		setSelectedImage("");
	}, [onSuccess, selectedImage]);

	const lastBookElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();
			const tabName = searchParams.get("tab");
			if (!tabName) {
				return setSearchParams({tab: FOLDERS[tabIndex]});
			}
			if (!FOLDERS.some(el => el === tabName)) {
				return setSearchParams({tab: FOLDERS[tabIndex]});
			}
			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && folderData.length < totalResult - 1) {
					setShowLoadMoreButton(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[folderData.length, loading, searchParams, setSearchParams, tabIndex, totalResult],
	);

	const loadMore = () => {
		setShowLoadMoreButton(false);
		dispatch(
			galleryActions.getGalleryMedia({
				folder: searchParams.get("tab")!,
				next_page_token: nextPageToken,
				page_size: 20,
			}),
		);
	};

	useEffect(() => {
		if (open) {
			const tabName = searchParams.get("tab");
			if (!tabName) {
				return setSearchParams({tab: FOLDERS[tabIndex]});
			}

			if (!FOLDERS.some(el => el === tabName)) {
				return setSearchParams({tab: FOLDERS[tabIndex]});
			}
			dispatch(galleryActions.getGalleryMedia({folder: tabName, page_size: 2}));
			setShowLoadMoreButton(false);
			firstRender.current = false;
		}
	}, [dispatch, open, searchParams, setSearchParams, tabIndex]);

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				onDismiss?.();
				setSelectedImage("");
				setShowLoadMoreButton(false);
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">Seleccionar de multimedia</DialogTitle>
				</DialogHeader>
				<ul className="sticky top-5 z-10 flex overflow-auto bg-neutral-900 text-center text-sm font-medium text-white">
					{folderNames.map((folder, idx) => (
						<li className={classNames(searchParams.get("tab") === folder.value ? "" : "border-b border-black")} key={idx}>
							<button
								onClick={() => {
									firstRender.current = true;
									dispatch(galleryActions.resetToInitial());
									setSearchParams({tab: folder.value});
								}}
								aria-current="page"
								className={classNames(
									"capitalize",
									searchParams.get("tab") === folder.value
										? "relative block border border-b-0 border-black bg-gray-100 p-4 text-black"
										: "block p-4 hover:bg-gray-50 hover:text-gray-600",
								)}
							>
								<div className="flex flex-shrink-0 items-center gap-1 whitespace-nowrap">
									{searchParams.get("tab") === folder.value ? <FolderOpen className="h-4 w-4" /> : <Folder className="h-4 w-4" />}
									<span>{folder.name}</span>
								</div>
							</button>
						</li>
					))}
					<div className="w-full border-b border-black" />
				</ul>
				<div className="flex max-h-[360px] flex-wrap justify-center gap-2 overflow-y-auto p-4 sm:gap-4">
					{folderData
						?.filter(el => {
							if (!acceptExtensions) return true;

							for (let i = 0; i < acceptExtensions.length; i++) {
								const isAccepted = el.url.endsWith(acceptExtensions[i]);
								if (isAccepted) return true;
							}

							return false;
						})
						.map((el: {name: Key | null | undefined; url: string | undefined}) => (
							<div
								className="relative aspect-square h-28 w-28 overflow-hidden rounded border border-neutral-300 bg-neutral-100 p-2 last-of-type:mr-auto"
								ref={lastBookElementRef}
								key={el.name}
								onClick={() => {
									el.url && setSelectedImage(el.url);
								}}
							>
								{el.url?.endsWith(".json") || el.url?.endsWith(".lottie") ? (
									<LottiePlayer autoplay loop src={el.url} className="mx-auto flex h-full w-fit" />
								) : (
									<img src={el.url} className="block aspect-square overflow-hidden object-contain" alt="" />
								)}
								{selectedImage === el.url && (
									<div className="absolute right-0 top-0 rounded-bl border-b border-l border-neutral-300 bg-white p-0.5">
										<Check className="h-5 w-5" />
									</div>
								)}
							</div>
						))}
					<div className="flex w-full items-center justify-center">
						{showLoadMoreButton && !loading && (
							<Button
								variant="link"
								onClick={loadMore}
								className="flex h-4 items-center justify-center font-medium text-white underline"
							>
								Cargar más
							</Button>
						)}
					</div>
				</div>
				{loading && (
					<div className="flex w-full items-center justify-center">
						<span className="mr-4 font-medium italic text-neutral-400">Cargando...</span>
						<Spinner className="h-6 w-6 animate-spin stroke-red-500" />
					</div>
				)}
				{!loading && !folderData.length && (
					<div className="flex w-full items-center justify-center py-2">
						<span className="mr-4 italic text-neutral-400">No hay más archivos</span>
					</div>
				)}

				<div className="flex flex-row justify-end">
					<Button
						className="mr-2 w-32 text-white"
						size="sm"
						variant="outline"
						onClick={() => {
							setSelectedImage("");
							onDismiss?.();
						}}
					>
						Cancelar
					</Button>
					<Button
						className="w-32 bg-black text-white"
						size="sm"
						variant="outline"
						type="submit"
						disabled={loading || !selectedImage}
						onClick={handleSave}
					>
						Aceptar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default MultimediaImagePickerModal;
