import {zodResolver} from "@hookform/resolvers/zod";
import {useState} from "react";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {seriesActions} from "../../_store/features/series/series-slice";
import {useAppDispatch} from "../../_store/hooks";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Image as ImageIcon} from "../../components/primitives/icons";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {InsertSeriesDataBodySchema, InsertSeriesDataBodyType, uploadSeriesImage} from "../../data-access/series/series";
import {auth} from "../../firebase";
import MuxVideoCombobox from "./Edit/subcomponents/MuxVideoCombobox";
import {toast} from "react-hot-toast";
import MultimediaImagePickerModal from "../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../_store/features/gallery/gallery-slice";
import {FOLDERS_NAMES} from "../../../utils/folderNames";
import {DayPicker} from "../../components/blocks/DatePicker";
import HourPicker from "../../components/primitives/HourPicker/HourPicker";
import {convertToStringHour} from "../../../utils/convertToStringHour";
import {Checkbox} from "../../components/primitives/Checkbox";

const initialState: InsertSeriesDataBodyType = {
	title: "",
	slug: "",
	published: false,
	video_mux_id: "",
	description: "",
	membership_title: "",
	tags: "",
	position_order: 0,
	img_logo: "",
	img_thumbnail: "",
	img_banner_web_mobile: "",
	img_banner_web_desktop: "",
	img_square_thumbnail: "",
	production_cost: 0,
	release_date: null,
	whitelist_release_date: null,
	display_release_date: false,
};
type ImageFieldsTypes = keyof Pick<
	InsertSeriesDataBodyType,
	"img_banner_web_desktop" | "img_banner_web_mobile" | "img_logo" | "img_square_thumbnail" | "img_thumbnail"
>;

function SeriesNew() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [fieldLoading, setFieldLoading] = useState<ImageFieldsTypes[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [hour, setHour] = useState<string>("00:00:00.000Z");
	const [whiteListHour, setWhiteListHour] = useState<string>("00:00:00.000Z");
	const [type, setType] = useState<ImageFieldsTypes>("img_banner_web_desktop");
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		control,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<InsertSeriesDataBodyType>({
		resolver: zodResolver(InsertSeriesDataBodySchema),
		defaultValues: initialState,
	});

	const onSubmit: SubmitHandler<InsertSeriesDataBodyType> = data => {
		if (data.release_date) {
			const date = new Date(`${data.release_date.split("T")[0]}T${hour}Z`);
			data.release_date = date.toISOString();
		}

		if (data.whitelist_release_date) {
			const date = new Date(`${data.whitelist_release_date.split("T")[0]}T${whiteListHour}Z`);
			data.whitelist_release_date = date.toISOString();
		}
		return dispatch(seriesActions.insertSerie(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Serie Creada Correctamente");
				reset();
				setTimeout(() => {
					navigate("/series/list");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("serieid", crypto.randomUUID());
		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadSeriesImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	const handleSelectHour = (date: string) => {
		setHour(date);
	};

	const handleSelectWhiteListHour = (date: string) => {
		setWhiteListHour(date);
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nueva Serie</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear series que se mostraran en toda la plataforma.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Titulo<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar titulo de la serie..." {...register("title")} />
									{errors?.title?.message && <span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Slug<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar slug de la serie..." {...register("slug")} />
									{errors?.slug?.message && <span className="text-sm font-medium text-destructive">{errors?.slug?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Costo de Producción</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										placeholder="Costo de Producción de la serie..."
										{...register("production_cost", {valueAsNumber: true})}
									/>
									{errors?.production_cost?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.production_cost?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<MuxVideoCombobox
										id="video_content_id"
										name="video_id"
										defaultValue={getValues("video_mux_id") ?? ""}
										onChangeSelected={selected => {
											setValue("video_mux_id", selected.playbackid);
										}}
									/>
									{errors?.video_mux_id?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.video_mux_id.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea rows={3} placeholder="Descripción de la serie..." {...register("description")} />
									{errors?.description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Mostrar fecha de release</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="items-top flex space-x-2">
										<Controller
											name="display_release_date"
											control={control}
											render={({field}) => (
												<Checkbox id="display_release_date" checked={field.value} onCheckedChange={c => field.onChange(c)} />
											)}
										></Controller>
										<div className="grid gap-1.5 leading-none">
											<label
												htmlFor="display_release_date"
												className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
											>
												Mostrar cuenta atrás de le fecha de release.
											</label>
											<p className="text-sm text-muted-foreground">
												Al marcar el checkbox, el usuario podrá ver el tiempo que falta para el release.
											</p>
										</div>
									</div>
								</dd>
							</div>

							<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
								<dd className="mt-1 w-full justify-start self-start">
									<div className="flex flex-row">
										<Controller
											name="release_date"
											control={control}
											render={({field}) => (
												<DayPicker
													{...field}
													onDayChange={date => {
														const dateFormatted = date.toISOString();
														field.onChange(dateFormatted);
													}}
													className="min-w-[150px] justify-start self-start"
												/>
											)}
										/>
										<p className="mx-2 self-center text-xl">-</p>
										<HourPicker hourSelected={convertToStringHour(null)} onTimeChange={date => handleSelectWhiteListHour(date)} />
									</div>
									{errors?.release_date?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
									)}
								</dd>
							</div>

							<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
									Fecha de release <p className="ml-2 text-xs font-bold text-destructive">*Whitelist</p>
								</dt>
								<dd className="mt-1 w-full justify-start self-start">
									<div className="flex flex-row">
										<Controller
											name="whitelist_release_date"
											control={control}
											render={({field}) => (
												<DayPicker
													{...field}
													onDayChange={date => {
														const dateFormatted = date.toISOString();
														field.onChange(dateFormatted);
													}}
													className="min-w-[150px] justify-start self-start"
												/>
											)}
										/>
										<p className="mx-2 self-center text-xl">-</p>
										<HourPicker hourSelected={convertToStringHour(null)} onTimeChange={date => handleSelectHour(date)} />
									</div>
									{errors?.whitelist_release_date?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.whitelist_release_date?.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Titulo en Membresía<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Titulo en Membresía..." {...register("membership_title")} />
									{errors?.membership_title?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.membership_title?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Palabras Clave</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea placeholder="Palabras claves de la serie" rows={3} {...register("tags")} />
									<span className="text-sm text-muted-foreground">*Adicione palabras clave separada por comas</span>
									{errors?.tags?.message && <span className="text-sm font-medium text-destructive">{errors?.tags?.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="number" placeholder="Orden de la serie..." {...register("position_order", {valueAsNumber: true})} />
									{errors?.position_order?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.position_order?.message}</span>
									)}
								</dd>
							</div>
							<div className="scroll-m-20 pb-4 pt-12 text-2xl font-semibold tracking-tight">Imágenes</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Logo de la Serie</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_logo") ? (
												fieldLoading.includes("img_logo") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_logo")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_logo");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_logo");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_logo?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_logo.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Thumbnail</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_thumbnail") ? (
												fieldLoading.includes("img_thumbnail") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_thumbnail")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_thumbnail");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_thumbnail");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_thumbnail?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_thumbnail.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Cuadrada</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_square_thumbnail") ? (
												fieldLoading.includes("img_square_thumbnail") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_square_thumbnail")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_square_thumbnail");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_square_thumbnail");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_square_thumbnail?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_square_thumbnail.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Banner Web desktop</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_banner_web_desktop") ? (
												fieldLoading.includes("img_banner_web_desktop") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_banner_web_desktop")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_banner_web_desktop");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_banner_web_desktop");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_banner_web_desktop?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_banner_web_desktop.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Banner web Movil</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("img_banner_web_mobile") ? (
												fieldLoading.includes("img_banner_web_mobile") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("img_banner_web_mobile")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "img_banner_web_mobile");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-black transition-colors duration-300 hover:bg-neutral-700 hover:text-white"
												onClick={() => {
													handleSelectImage("img_banner_web_mobile");
												}}
											>
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.img_banner_web_mobile?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.img_banner_web_mobile.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate("/series/list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Serie
						</Button>
					</div>
				</form>
			</div>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={5}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setValue(type, url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</section>
	);
}

export default SeriesNew;
