import {useEffect, useState, useMemo} from "react";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {shortResult, shortsActions} from "../../../_store/features/shorts/shorts-slice";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {auth} from "../../../firebase";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {Badge} from "../../../components/primitives/Badge";
import {ListPlus, Pencil, Refresh} from "../../../components/primitives/icons";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import {DataTable} from "../../../components/blocks/DataTable";
import {Button} from "../../../components/primitives/Button";
import {Link} from "react-router-dom";
import Tag from "../../../components/primitives/icons/Tag";
import TagsSheet from "../Tags/TagsSheet";

const searchOptions = [
	{name: "Titulo", value: "title"},
	{name: "Upload ID", value: "uploadid"},
	{name: "Playback ID", value: "playbackid"},
];

const publishedOptions = [
	{name: "Todos", value: undefined},
	{name: "Activo", value: true},
	{name: "Inactivo", value: false},
];

const columnHelper = createColumnHelper<shortResult>();

function ShortsList() {
	const dispatch = useAppDispatch();
	const shorts = useAppSelector(state => state.shorts);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedPublished, setSelectedPublished] = useState(publishedOptions[0]);
	const [isTagsSheetOpen, setIsTagsSheetOpen] = useState(false);

	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		if (!auth.currentUser) return;

		const params: Record<string, any> = {};
		params.published = selectedPublished.value;

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (!searchValue && !selectedPublished) return;

		dispatch(
			shortsActions.getShortsList({
				params: {page_size: pagination.pageSize, page: pagination.pageIndex, ...params},
			}),
		);
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("order", {
				id: "Orden",
				header: ({column}) => <DataTableColumnHeader title="Orden" column={column} />,
				cell: info => <div className="ml-2 flex w-full text-left">{info.getValue()}</div>,
				size: Math.round(tableWidth * 0.075),
				enableResizing: false,
				enableSorting: false,
			}),
			columnHelper.accessor("title", {
				id: "Título / Breve descripción",
				header: ({column}) => <DataTableColumnHeader title="Título / Breve descripción" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.row.original.title}</span>
						<span className="overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.short_description}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("duration", {
				id: "Duración",
				header: ({column}) => <DataTableColumnHeader title="Duración" column={column} />,
				cell: info => (
					<div className="flex w-full flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.getValue()}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("long_description", {
				id: "Descripción",
				header: ({column}) => <DataTableColumnHeader title="Descripción" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden truncate text-ellipsis text-left">{info.row.original.long_description}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("published", {
				id: "Estado",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} />,
				cell: info =>
					info.getValue() ? (
						<div className="flex flex-1 justify-start">
							<Badge variant={"success"}>Activo</Badge>
						</div>
					) : (
						<div className="flex flex-1 justify-start">
							<Badge variant={"destructive"}>Inactivo</Badge>
						</div>
					),
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-start gap-3">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/short_videos/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, tableRef]);

	const handleSignedFilter = (value: string) => {
		const isPublished = publishedOptions.find(el => el.name === value) ?? publishedOptions[0];
		setSelectedPublished(isPublished);
		dispatch(
			shortsActions.getShortsList({
				params: {page_size: pagination.pageSize, page: pagination.pageIndex, published: isPublished.value},
			}),
		);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	const refresh = () => {
		setSelectedPublished(publishedOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(
			shortsActions.getShortsList({
				params: {page_size: pagination.pageSize, page: pagination.pageIndex},
			}),
		);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Listado de Shorts</h2>
				<div>
					<Button className="mr-2 gap-2" variant={"outline"} onClick={() => setIsTagsSheetOpen(true)}>
						<Tag />
						Tags
					</Button>
					<Button className="gap-2" asChild>
						<Link to="/short_videos/new">
							<ListPlus />
							Nuevo Short
						</Link>
					</Button>
				</div>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleSignedFilter} value={selectedPublished.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{publishedOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.name}`}>
										{sOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={shorts.results}
					pageCount={Math.ceil(shorts.totalResults / pagination.pageSize)}
					pagination={pagination}
					loading={shorts.loading}
					onPaginationChange={setPagination}
					rowHeight={57}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<TagsSheet
				open={isTagsSheetOpen}
				onOpenChange={o => {
					setIsTagsSheetOpen(o);
				}}
			/>
		</div>
	);
}

export default ShortsList;
