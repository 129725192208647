import axios, {AxiosResponse} from "axios";
import {z} from "zod";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface Advertisement {
	id: string;
	text: string;
	sponsor: string;
	name?: string;
	published: false;
	order: number;
	img_mobile: string;
	img_web: string;
	author: string;
	audit_created: string;
	audit_updated: string;
	type_publicity: number;
	sponsor_id?: string;
	prints?: number;
}

export const InsertAdvertisementBodySchema = z.object(
	{
		text: z.string().nonempty("El texto del anuncio no puede estar vacío."),
		sponsor: z.string().nonempty("El texto del anuncio no puede estar vacío."),
		published: z.boolean(),
		order: z.number({invalid_type_error: "Este campo requiere un numero."}),
		img_mobile: z.string().optional(),
		img_web: z.string().optional(),
		type_publicity: z.number(),
		sponsor_id: z.string().optional(),
	},
	{required_error: "Este campo es requerido."},
);
export type InsertAdvertisementBodyType = z.infer<typeof InsertAdvertisementBodySchema>;
export interface InsertAdvertisementResponse {
	id: string;
	text: string;
	sponsor: string;
	published: false;
	order: number;
	img_mobile: string;
	img_web: string;
	author: string;
	audit_created: string;
	audit_updated: string;
	type_publicity: number;
}
export async function insertAdvertisementData(token: string, data: InsertAdvertisementBodyType): Promise<AxiosResponse<InsertAdvertisementResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/insert_publicity_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export const EditAdvertisementBodySchema = z.object(
	{
		id: z.string(),
		text: z.string().nonempty("El texto del anuncio no puede estar vacío."),
		sponsor: z.string().nonempty("El texto del anuncio no puede estar vacío."),
		published: z.boolean(),
		order: z.number({invalid_type_error: "Este campo requiere un numero."}),
		img_mobile: z.string().optional(),
		img_web: z.string().optional(),
		sponsor_id: z.string().optional().nullable(),
	},
	{required_error: "Este campo es requerido."},
);
export type EditAdvertisementBodyType = z.infer<typeof EditAdvertisementBodySchema>;

export const EditAdvertisementPrintBodySchema = z.object(
	{
		id: z.string(),
		prints: z.number(),
	},
	{required_error: "Este campo es requerido."},
);
export type EditAdvertisementPrintBodyType = z.infer<typeof EditAdvertisementPrintBodySchema>;

interface EditAdvertisementResponse {
	id: string;
	text: string;
	published: false;
	sponsor: string;
	order: number;
	img_mobile: string;
	img_web: string;
	author: string;
	audit_created: string;
	audit_updated: string;
	type_publicity: number;
	sponsor_id?: string;
	prints?: number;
	name?: string;
}
export async function editAdvertisementData(token: string, data: EditAdvertisementBodyType): Promise<AxiosResponse<EditAdvertisementResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_publicity_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function editAdvertisementPrintData(
	token: string,
	data: EditAdvertisementPrintBodyType,
): Promise<AxiosResponse<EditAdvertisementResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/edit_publicity_data", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadPublicityImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_publicity_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}

export interface GetAdvertisementsListParams {
	page: number;
	page_size: number;
	searchby?: undefined | "publicityid" | "text" | string;
	searchvalue?: string;
	published?: boolean;
	publicity_type?: number;
}

interface GetAdvertisementsListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: Advertisement[];
}

export async function getAdvertisementsList(
	token: string,
	params?: GetAdvertisementsListParams,
): Promise<AxiosResponse<GetAdvertisementsListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_publicity_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export async function getAdvertisementSponsorsList(
	token: string,
	params?: GetAdvertisementsListParams,
): Promise<AxiosResponse<GetAdvertisementsListResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/admin_list_publicity_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export interface DeleteAdvertisementBody {
	publicityid: string;
}

interface DeleteAdvertisementResponse {
	result: "Publicity Eliminada";
}

export async function deleteAdvertisementData(token: string, data?: DeleteAdvertisementBody): Promise<AxiosResponse<DeleteAdvertisementResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.delete("/delete_publicity_data", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		data,
	});
}
