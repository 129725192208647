import {Table} from "@tanstack/react-table";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../Select";
import {Button} from "../Button";
import {Chevron, ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft} from "../icons";

interface DataTablePaginationProps<TData> {
	table: Table<TData>;
	showPagination?: boolean;
}

export function DataTablePagination<TData>({table, showPagination}: DataTablePaginationProps<TData>) {
	const PAGE_SIZES = new Set([10, 20, 30, 40, 50, table.getState().pagination.pageSize]);

	return (
		<div className="flex items-center justify-between">
			<div className="ml-auto flex items-center space-x-6 lg:space-x-8">
				<div className="flex items-center space-x-2">
					{showPagination && (
						<div className="flex flex-row items-center space-x-2">
							<p className="text-sm font-medium">Filas por página</p>
							<Select
								value={`${table.getState().pagination.pageSize}`}
								onValueChange={value => {
									table.setPageSize(Number(value));
								}}
							>
								<SelectTrigger className="h-8 w-[70px]">
									<SelectValue placeholder={table.getState().pagination.pageSize} />
								</SelectTrigger>
								<SelectContent side="top">
									{Array.from(PAGE_SIZES).map(pageSize => (
										<SelectItem key={pageSize} value={`${pageSize}`}>
											{pageSize}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
					)}
				</div>
				<div className="flex w-[100px] items-center justify-center whitespace-nowrap text-sm font-medium">
					Página {table.getState().pagination.pageIndex + 1} de {Math.round(table.getPageCount())}
				</div>
				<div className="flex items-center space-x-2">
					<Button
						variant="outline"
						type="button"
						className="hidden h-8 w-8 p-0 lg:flex"
						onClick={() => table.setPageIndex(0)}
						disabled={!table.getCanPreviousPage()}
					>
						<span className="sr-only">Ir a la primera página</span>
						<ChevronDoubleLeft className="h-4 w-4" />
					</Button>
					<Button
						variant="outline"
						className="h-8 w-8 p-0"
						type="button"
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						<span className="sr-only">Ir a la página anterior</span>
						<ChevronLeft className="h-4 w-4" />
					</Button>
					<Button
						variant="outline"
						className="h-8 w-8 p-0"
						type="button"
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						<span className="sr-only">Ir a la siguiente página</span>
						<Chevron className="h-4 w-4 rotate-90" />
					</Button>
					<Button
						variant="outline"
						className="hidden h-8 w-8 p-0 lg:flex"
						type="button"
						onClick={() => table.setPageIndex(table.getPageCount() - 1)}
						disabled={!table.getCanNextPage()}
					>
						<span className="sr-only">Ir a la última página</span>
						<ChevronDoubleRight className="h-4 w-4" />
					</Button>
				</div>
			</div>
		</div>
	);
}
