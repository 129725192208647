import {useState, useEffect} from "react";
import {auth} from "../../../firebase";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../components/primitives/Dialog";
import {Video, videosActions} from "../../../_store/features/videos/videos-slice";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import SponsorSelect from "../../advertisements/new/subcomponents/SponsorSelect";
import {Button} from "../../../components/primitives/Button";
import {Input} from "../../../components/primitives/Input";
import {Spinner} from "../../../components/primitives/icons";

interface EditVideoModalProps {
	onDismiss: () => void;
	onSuccess?: () => void;
	open: boolean;
	data: Video;
}

export function EditVideoModal({open, onDismiss, data, onSuccess}: EditVideoModalProps) {
	const [title, setTitle] = useState(data.title);
	const [sponsor, setSponsor] = useState(data.sponsor_id);
	const [loading, setLoading] = useState(false);

	const dispatch = useAppDispatch();
	const videos = useAppSelector(state => state.videos);
	const [saved, setSaved] = useState<string>("");

	useEffect(() => {
		setTitle(data.title);
		setSponsor(data.sponsor_id);
	}, [setTitle, setSponsor, data]);

	useEffect(() => {
		videos.loadingAction === true ? saved === "" && setSaved("up") : saved === "up" && handleDismiss();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos.loadingAction, saved, setSaved]);

	async function handleSubmit() {
		if (data.title !== title || data.sponsor_id !== sponsor) {
			if (!auth.currentUser) return;
			setLoading(true);

			if (data.sponsor_id !== sponsor)
				await dispatch(
					videosActions.editVideoData({
						GCPUser: auth.currentUser,
						params: {title: title, uploadid: data.uploadid, video_type: data.video_type, sponsor_id: sponsor},
					}),
				);
			else
				await dispatch(
					videosActions.editVideoData({
						GCPUser: auth.currentUser,
						params: {title: title, uploadid: data.uploadid},
					}),
				);
			setLoading(false);
			handleSuccess();
		} else handleDismiss();
	}

	function handleDismiss() {
		onDismiss?.();
	}

	function handleSuccess() {
		onSuccess?.();
	}

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				onDismiss?.();
				// resetStates();
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">Editar Video Short</DialogTitle>
				</DialogHeader>
				<div className="flex">
					<div className="w-1/2">
						<div className="mb-3 flex w-full">
							<div className="flex w-full">
								<label className="my-4 text-white" htmlFor="exampleFormControlInput1">
									*Título:
								</label>
								<Input
									className="form-control m-2 w-80 rounded bg-black px-3 py-2 text-white"
									type="text"
									name="title"
									placeholder="Título del video"
									value={title}
									onChange={e => setTitle(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="ml-3 w-1/2">
						<p className="my-4 text-white">Tipo: {data.signed ? "Firmado" : "Público"}</p>
					</div>
					<div className="ml-3 w-1/2">
						<p className="my-4 text-white">
							{data.video_type === 0 && "Tipo Video: Contenido"}
							{data.video_type === 2 && "Tipo Video: Publicidad"}
							{data.video_type === 3 && "Tipo Video: Short"}
						</p>
					</div>
				</div>
				{data.video_type === 2 && (
					<div className="flex w-full items-center text-white">
						<label className="mr-2">Sponsor:</label>
						<SponsorSelect selectedSponsor={sponsor} onSponsorChange={e => setSponsor(e)} />
					</div>
				)}

				<p className="text-white">
					<b>Playback ID:</b> {data.playbackid}
				</p>
				<p className="text-white">
					<b>Upload ID:</b> {data.uploadid}
				</p>
				<p className="text-white">
					<b>Asset ID:</b> {data.assetid}
				</p>
				<p className="text-white">
					<b>Autor:</b> {data.author}
				</p>
				<p className="text-white">
					<b>Duración:</b> {data.duration}
				</p>

				<div className="flex flex-row justify-end">
					<Button
						className="mr-2 w-32 text-white"
						size="sm"
						variant="outline"
						onClick={() => {
							onDismiss?.();
						}}
					>
						Cancelar
					</Button>
					<Button className="w-32 bg-black text-white" size="sm" variant="outline" onClick={handleSubmit}>
						{loading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Guardar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
