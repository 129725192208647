import {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {notificationsActions} from "../../_store/features/notify/notify-slice";

interface IFormInputs {
	message: string;
}
const initialState: IFormInputs = {
	message: "",
};

function GlobalNotifications() {
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(state => state.notifications.loading);
	const isSuccess = useAppSelector(state => state.notifications.success);
	const error = useAppSelector(state => state.notifications.error);
	const [isSubmitted, SetIsSubmitted] = useState(false);

	const {
		register,
		formState: {errors},
		handleSubmit,
		setValue,
	} = useForm<IFormInputs>({
		defaultValues: initialState,
	});

	useEffect(() => {
		if (!isSuccess || !isSubmitted) return;
		dispatch(notificationsActions.setSuccess(false));
		setValue("message", "");
		alert("NOTIFICACIÓN GLOBAL ENVIADA CORRECTAMENTE.");
	}, [isSuccess]);

	useEffect(() => {
		if (!isLoading && isSubmitted) {
			if (error) {
				alert("ERROR: " + error);
			}
		}
	}, [isLoading]);

	const onSubmit: SubmitHandler<IFormInputs> = data => {
		if (
			window.confirm(
				"Esta seguro que deseas enviar esta notificación a todos los usuarios de la plataforma?\nMensaje a enviar:\n" + data.message,
			)
		) {
			SetIsSubmitted(true);
			dispatch(notificationsActions.globalNotifications(data));
		}
	};

	return (
		<>
			<div className="container mx-auto px-6 py-8">
				<div className="mb-3 border-b-2 pb-2 text-2xl font-bold uppercase">Notificaciones Globales</div>
				<form className="rounded-lg bg-neutral-100 p-5" onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-3 border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700" role="alert">
						<p className="font-bold">¡Cuidado!</p>
						<p>Esta acción enviara una notificación a todos los usuarios de la plataforma.</p>
					</div>
					<div className="max-w-2xl">
						<div className="mb-3">
							<label htmlFor="description">Mensaje*</label>
							<textarea
								{...register("message", {required: "Campo requerido"})}
								rows={3}
								placeholder="Mensaje de Notificación"
								className={`mt-1 w-full rounded px-3 py-2 ring-1 ring-neutral-800 ${
									errors.message ? "ring-red-500 focus:outline-none" : ""
								}`}
							/>
							{errors.message && <span className="text-red-500">{errors.message.message}</span>}
						</div>
					</div>
					<div className="mt-12 flex w-full justify-end gap-4">
						<button
							className="col-start-2 flex items-center justify-center rounded bg-neutral-900 px-4 py-2 uppercase text-white transition-colors duration-300 hover:bg-neutral-700"
							type="submit"
							disabled={isLoading}
						>
							{isLoading ? <Spinner /> : "Enviar Notificación"}
						</button>
					</div>
				</form>
			</div>
		</>
	);
}

export default GlobalNotifications;
