import {PropsWithChildren, useEffect, useMemo, useState} from "react";
import {Line} from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions} from "chart.js";
import {getMetricsTimeSeries, getReferredViewsSeries} from "../../../../data-access/mux";
import {auth} from "../../../../firebase";
import {addDays, endOfToday, roundToNearestMinutes, startOfDay, subDays, formatISO9075} from "date-fns";
import {Spinner} from "../../../primitives/icons";
import {DatePickerWithRange} from "../../DateRangePicker";
import {DateRange} from "react-day-picker";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ViewsTimeSeriesProps {
	serieid?: string;
	contentid?: string;
	codeReferral?: string;
	title?: string;
	loading?: boolean;
}

function formatDate(dateStr: string) {
	const date = new Date(dateStr);
	return date.toLocaleDateString("es-US", {month: "short", day: "numeric"});
}

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "top" as const,
		},
	},
};

function ViewsTimeSeries(props: PropsWithChildren<ViewsTimeSeriesProps>) {
	const {serieid, contentid, codeReferral, title = "Visualizaciones diarias", children} = props;
	const [error, setError] = useState("");
	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(subDays(Date.now(), 30));
		return {
			from,
			to,
		};
	});
	const [timeSerie, setTimesSerie] = useState<string[][]>();
	const [referredViews, setReferredViews] = useState<string[][]>();
	const [innerLoading, setInnerLoading] = useState(false);
	let serieidList: string[];

	if (serieid?.includes(",")) {
		serieidList = serieid?.split(",");
	}

	const data = useMemo(
		() => ({
			labels: timeSerie?.map(item => {
				const date = new Date(item[0]);
				const format = addDays(date, 1);
				return formatDate(format.toISOString());
			}),
			datasets: [
				{
					label: "Visualizaciones totales",
					data: timeSerie?.map(item => Number(item[1])),
					borderColor: "rgba(242, 116, 116, 1)",
					backgroundColor: "rgba(242, 116, 116, 0.5)",
				},
				{
					label: "Visualizaciones por referido",
					data: referredViews?.map(item => Number(item[1])),
					borderColor: "rgba(240, 158, 16, 1)",
					backgroundColor: "rgba(240, 158, 16, 0.5)",
				},
			],
		}),
		[referredViews, timeSerie],
	);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const convertDateToTimestamp = (date: Date) => {
		return Math.floor(date.getTime() / 1000).toString();
	};

	useEffect(() => {
		if (!codeReferral) return;
		async function getAsyncViews() {
			try {
				setError("");
				setInnerLoading(true);
				const token = await auth.currentUser?.getIdToken();

				if (!token) {
					return setError("Invalid token");
				}

				let filters: string[] = [];

				if (serieidList) {
					serieidList.forEach(element => {
						filters.push(`video_series:${element}`);
					});
				} else {
					filters.push(`video_series:${serieid}`);
				}

				if (contentid) filters.push(`video_id:${contentid}`);

				const {
					data: {data},
				} = await getMetricsTimeSeries(token, "views", {
					measurement: "count",
					group_by: "day",
					timeframe: [convertDateToTimestamp(dates?.from!), convertDateToTimestamp(dates?.to!)],
					filters,
				});

				const {data: referred_views} = await getReferredViewsSeries(token, {
					referral_code: codeReferral || "",
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				});
				setReferredViews(referred_views.data);

				setTimesSerie(data);
				setInnerLoading(false);
			} catch (err) {
				setError("Error cargando las estadísticas " + err);
				setInnerLoading(false);
			}
		}

		getAsyncViews();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeReferral, contentid, serieid, dates]);

	return (
		<div className="mx-8 my-6 mt-3 rounded-lg border border-border bg-card shadow-sm">
			{!!error && <div className="rounded-sm bg-red-500 p-2 text-red-700">{error}</div>}
			<div className="mb-4 flex items-center justify-between pl-6 pt-6">
				<h3 className="text-lg font-semibold leading-none tracking-tight">{title}</h3>
				<div className="w-80 flex-row justify-end">
					<DatePickerWithRange
						onDateRangeChange={handleDateRangeChange}
						disabled={{before: subDays(new Date(), 35), after: new Date()}}
						date={dates}
					/>
				</div>
				{!!children && children}
			</div>
			{props.loading || innerLoading ? (
				<div className="flex h-[350px] items-center justify-center">
					<Spinner />
				</div>
			) : (
				<div className="p-6 pt-0">
					<Line height={350} options={options} data={data} />
				</div>
			)}
		</div>
	);
}

export default ViewsTimeSeries;
