import {Tab} from "@headlessui/react";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {cn} from "../../../../utils/classNames";
import {useAppSelector} from "../../../_store/hooks";
import UserConfiguration from "./Tabs/Configuration";
import Memberships from "./Tabs/Memberships";
import PasswordManagement from "./Tabs/PasswordManagement";
import UserData from "./Tabs/UserData";
import UserNotifications from "./Tabs/UserNotifications";
import useCurrentUserRole from "../../../components/hooks/useCurrentUserRole";
import useUserRole from "../../../components/hooks/useUserRole";
import useUserVinculated from "../../../components/hooks/useUserVinculated";

const tabsCls = ({selected}: {selected: boolean}) =>
	cn(
		"w-full py-4 text-xs font-medium uppercase leading-tight ",
		"focus:outline-none ",
		selected ? "border-b-2 border-b-black bg-white text-black shadow" : "text-neutral-500",
	);

export default function UserEdit() {
	let {id} = useParams();
	const currentUserRole = useCurrentUserRole();
	const user = useAppSelector(state => state.dbuser.results.find(el => el.userid === id));
	const selectedUserRole = useUserRole(user);
	const isVinculated = useUserVinculated(id);
	const navigate = useNavigate();

	useEffect(() => {
		if (!user) {
			return navigate(-1);
		}
	}, [navigate, selectedUserRole, user]);

	if (!id) {
		return <div>No Params Id</div>;
	}

	return (
		<Tab.Group>
			<Tab.List className="flex items-center justify-center space-x-1 border-b border-gray-200">
				<Tab className={tabsCls}>Editar Usuario</Tab>
				<Tab className={tabsCls}>Manejo de Contraseña</Tab>
				<Tab className={tabsCls} hidden={!!selectedUserRole || currentUserRole === "marketing" || isVinculated}>
					Membresías
				</Tab>

				<Tab className={tabsCls} hidden={selectedUserRole === "corporate" || isVinculated}>
					Notificaciones
				</Tab>
				{currentUserRole &&
					selectedUserRole &&
					["admin", "master_editor", "marketing"].includes(currentUserRole) &&
					["partner", "sponsor", "influencer"].includes(selectedUserRole) && <Tab className={tabsCls}>Configuración</Tab>}
				{currentUserRole && !selectedUserRole && ["marketing"].includes(currentUserRole) && <Tab className={tabsCls}>Configuración</Tab>}
			</Tab.List>
			<Tab.Panels>
				<Tab.Panel>
					<UserData />
				</Tab.Panel>
				<Tab.Panel>
					<PasswordManagement />
				</Tab.Panel>
				<Tab.Panel>
					<Memberships />
				</Tab.Panel>
				<Tab.Panel>
					<UserNotifications />
				</Tab.Panel>
				<Tab.Panel>
					<UserConfiguration />
				</Tab.Panel>
			</Tab.Panels>
		</Tab.Group>
	);
}
