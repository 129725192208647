import {useParams} from "react-router-dom";
import {useAppSelector} from "../../../../../_store/hooks";
import useUserRole from "../../../../../components/hooks/useUserRole";
import PartnerConfigurations from "./PartnerConfig";
import SponsorConfigurations from "./SponsorConfig";
import InfluencerConfigurations from "./InfluencerConfig";

export default function UserConfiguration() {
	let {id} = useParams();
	const initialUser = useAppSelector(state => state.dbuser.results.find(el => el.userid === id));
	const userActiveRole = useUserRole(initialUser);

	switch (userActiveRole) {
		case "partner":
			return <PartnerConfigurations />;
		case "sponsor":
			return <SponsorConfigurations />;
		case "influencer":
			return <InfluencerConfigurations />;
		default:
			return (
				<div className="px-5 py-4">
					<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
						No hay configuraciones disponibles para este usuario
					</h1>
					<p className="leading-7 [&:not(:first-child)]:mt-6">
						Este usuario no tiene disponible configuración o esta bajo construcción, si crees que esto es un error contacta con el equipo
						de desarrollo.
					</p>
				</div>
			);
	}
}
