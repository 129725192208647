import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

/*INSERT STRIPE*/
export interface InsertSerieProductStripeBody {
	productid: string;
	images?: [string];
}
interface InsertSerieProductStripeResponse {
	id: string;
	name: string;
	description: string;
	active: boolean;
	serieid: string;
	serie_title: string;
	membership_type: number;
	apple_id: string;
	google_id: string;
	stripe_productid: string;
	apple_price: number;
	apple_price_initial: number;
	google_price: number;
	google_price_initial: number;
	stripe_price: number;
	stripe_price_initial: number;
}

export async function insertSerieProductStripeData(
	token: string,
	data: InsertSerieProductStripeBody,
): Promise<AxiosResponse<InsertSerieProductStripeResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/create_stripe_product_series", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadProductStripeImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_product_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}
