import {ReactNode} from "react";
import {Modal} from "../../primitives/Modal";

export interface ActionConfirmModalProps {
	open: boolean;
	onCancel: () => void;
	onAccept: () => void;
	title: string;
	description: ReactNode;
}
function ActionConfirmModal({open, title, description, onCancel, onAccept}: ActionConfirmModalProps) {
	return (
		<Modal title={title} open={open} onDismiss={onCancel}>
			<p className="mb-4 text-lg">{description}</p>
			<div className="flex gap-4 	">
				<button
					className="text-md  mt-4 flex w-full items-center justify-center rounded-xl border-2 bg-white bg-opacity-10 px-4 py-3 font-bold uppercase tracking-wider text-white transition-colors duration-300 disabled:cursor-not-allowed disabled:border-neutral-800 disabled:bg-neutral-700 disabled:text-neutral-300 hover:bg-white hover:text-black active:bg-neutral-600"
					type="submit"
					onClick={onCancel}
				>
					Cancel
				</button>
				<button
					className="text-md  mt-4 flex w-full items-center justify-center rounded-xl border-2 bg-white px-4 py-3 font-bold uppercase tracking-wider text-black transition-colors duration-300 disabled:cursor-not-allowed  disabled:border-neutral-800 disabled:bg-neutral-700 disabled:text-neutral-300 hover:bg-opacity-10 hover:text-white active:bg-neutral-200"
					type="submit"
					onClick={onAccept}
				>
					Aceptar
				</button>
			</div>
		</Modal>
	);
}

export default ActionConfirmModal;
