import {useDeferredValue, useEffect, useState, useMemo} from "react";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {ListPlus, Pencil, Trash} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {auth} from "../../firebase";
import {Video, videosActions} from "../../_store/features/videos/videos-slice";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import ActionConfirmModal from "../../components/blocks/ActionConfirmModal";
import {NewVideoModal} from "./subcomponents/NewVideoModal";
import {EditVideoModal} from "./subcomponents/EditVideoModal";
import {DataTable} from "../../components/blocks/DataTable";
import {Badge} from "../../components/primitives/Badge";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";

const searchOptions = [
	{name: "Titulo", value: "title"},
	{name: "Upload ID", value: "uploadid"},
	{name: "Playback ID", value: "playbackid"},
];

const signedOptions = [
	{name: "Todos", value: undefined},
	{name: "Firmados", value: true},
	{name: "Públicos", value: false},
];

const initialStateVideo: Video = {
	uploadid: "",
	assetid: "",
	playbackid: "",
	status: "",
	duration: 0,
	signed: true,
	title: "",
	author: "",
	upload_url: "",
	video_type: 0,
};

const columnHelper = createColumnHelper<Video>();

function VideoPublicityList() {
	const dispatch = useAppDispatch();
	const videos = useAppSelector(state => state.videos);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [isOpen, setIsOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedSigned, setSelectedSigned] = useState(signedOptions[0]);
	const deferredSearchValue = useDeferredValue(searchValue);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [selectVideo, setSelectVideo] = useState<Video>(initialStateVideo);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		if (!auth.currentUser) return;

		const params: Record<string, any> = {};
		params.signed = selectedSigned.value;
		// publicidad
		params.video_type = 2;

		if (deferredSearchValue) {
			params.searchvalue = deferredSearchValue;
			params.searchby = selectedSearchBy.value;
		}

		if (!deferredSearchValue && !selectedSigned) return;

		!videos.loadingAction &&
			dispatch(
				videosActions.getVideosList({
					GCPUser: auth.currentUser,
					params: {page_size: pagination.pageSize, page: pagination.pageIndex, ...params},
				}),
			);
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("duration", {
				id: "Duración",
				header: ({column}) => <DataTableColumnHeader title="Duración" column={column} />,
				cell: info => info.getValue().toFixed(2),
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("title", {
				id: "Título",
				header: ({column}) => <DataTableColumnHeader title="Título / PlaybackID" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden truncate text-ellipsis text-left">{info.row.original.title}</span>
						<span className="ml-2 overflow-hidden truncate text-ellipsis text-left text-neutral-500">{info.row.original.playbackid}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("sponsor_id", {
				id: "Sponsor / Sponsor Id",
				header: ({column}) => <DataTableColumnHeader title="Sponsor / Sponsor Id" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden truncate text-ellipsis text-left">{info.row.original.name}</span>
						<span className="ml-2 overflow-hidden truncate text-ellipsis text-left text-neutral-500">{info.row.original.sponsor_id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.accessor("video_type", {
				id: "Tipo",
				header: ({column}) => <DataTableColumnHeader title="Tipo" column={column} />,
				cell: info =>
					info.row.original.video_type === 0 ? (
						<div className="ml-2 flex w-full gap-2">Contenido</div>
					) : (
						<div className="ml-2  flex w-full gap-2">Publicidad</div>
					),
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("status", {
				id: "Estado",
				header: ({column}) => <DataTableColumnHeader title="Estado" column={column} />,
				cell: info => (
					<div className="ml-2  flex w-full gap-2">
						<span>{info.getValue()}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("signed", {
				id: "Firmado",
				header: ({column}) => <DataTableColumnHeader title="Firmado" column={column} />,
				cell: info => (
					<div className="ml-2 flex w-full">
						{info.getValue() ? <Badge variant={"success"}>Signed</Badge> : <Badge variant={"destructive"}>Unsigned</Badge>}
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-3">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={async () => {
								if (!auth.currentUser) return;
								await dispatch(videosActions.refreshVideoData({GCPUser: auth.currentUser, item: info.row.original}));
								refresh();
							}}
						>
							<Refresh className="h-4 w-4" />
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setSelectVideo(info.row.original);
								setIsEdit(true);
							}}
						>
							<Pencil className="h-4 w-4" />
						</Button>
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setSelectVideo(info.row.original);
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.15),
				enableResizing: false,
			}),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, tableRef]);

	const handleSignedFilter = (value: string) => {
		const signed = signedOptions.find(el => el.name === value) ?? signedOptions[0];
		setSelectedSigned(signed);

		auth.currentUser &&
			dispatch(
				videosActions.getVideosList({
					GCPUser: auth.currentUser,
					params: {page_size: pagination.pageSize, page: pagination.pageIndex, signed: signed.value, video_type: 2},
				}),
			);
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	const refresh = () => {
		setSelectedSigned(signedOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(
			videosActions.getVideosList({
				GCPUser: auth.currentUser!,
				params: {page_size: pagination.pageSize, page: pagination.pageIndex, video_type: 2},
			}),
		);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Listado de Videos de Publicidad</h2>
				<Button className="gap-2" onClick={() => setIsOpen(true)}>
					<ListPlus />
					Insertar nuevo Video
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>
					<Select onValueChange={handleSignedFilter} value={selectedSigned.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{signedOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.name}`}>
										{sOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={videos.results}
					pageCount={Math.ceil(videos.totalResults / pagination.pageSize)}
					pagination={pagination}
					loading={videos.loading}
					onPaginationChange={setPagination}
					rowHeight={57}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<NewVideoModal
				onDismiss={() => setIsOpen(false)}
				open={isOpen}
				videoType="advertising"
				onSuccess={() => {
					setIsOpen(false);
					refresh();
				}}
			/>
			<EditVideoModal
				onDismiss={() => {
					setIsEdit(false);
					setSelectVideo(initialStateVideo);
				}}
				open={isEdit}
				data={selectVideo}
				onSuccess={refresh}
			/>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setSelectVideo(initialStateVideo);
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					if (!auth.currentUser) return;
					dispatch(videosActions.deleteVideoData({GCPUser: auth.currentUser, item: selectVideo}));
					setSelectVideo(initialStateVideo);
					setDeleteConfirmationModalOpen(false);
					refresh();
				}}
				title={"Eliminar Video"}
				description={<span className="text-white">Al confirmar eliminarás el video de publicidad, este proceso no se puede revertir.</span>}
			/>
		</div>
	);
}

export default VideoPublicityList;
