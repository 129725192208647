import {useEffect} from "react";
import Chart, {ChartData, ChartOptions} from "chart.js/auto";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useMemo} from "react";
import {format, isSameDay} from "date-fns";
import {Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";

interface IncomeVsProfitProps {
	dates: DateRange | undefined;
}

interface chartDataInterface {
	date: string;
	profit: number;
}

type MaxProfitByDate = Record<string, number>;

const IncomeVsProfit = ({dates}: IncomeVsProfitProps) => {
	const {data: statistics, isLoading} = useDashboardByNameQuery("generalIncome");

	function filterMaxProfitByDate(data: chartDataInterface[]): chartDataInterface[] {
		const maxProfitByDate: MaxProfitByDate = {};

		data.forEach(item => {
			const {date, profit} = item;

			if (maxProfitByDate[date] === undefined || profit > maxProfitByDate[date]) {
				maxProfitByDate[date] = profit;
			}
		});

		const filteredData = data.filter(item => item.profit === maxProfitByDate[item.date]);

		return filteredData;
	}

	const data: ChartData<"line", {date: string; profit: number}[]> = useMemo(() => {
		const mergedData = [
			...(statistics?.daily_profit_memberships || []),
			...(statistics?.daily_cost || []),
			...(statistics?.daily_profit_coins || []),
		];

		const statisticsDataSorted = mergedData?.slice().sort((a, b) => {
			const dateA = new Date(a.year, a.month - 1, a.day).getTime();
			const dateB = new Date(b.year, b.month - 1, b.day).getTime();

			return dateA - dateB;
		});

		const accumulatedProfits: number[] =
			statisticsDataSorted?.reduce((acc: number[], {profit, production_cost}: any) => {
				if (profit) {
					const updatedProfit = acc.length === 0 ? profit : acc[acc.length - 1] + profit;
					acc.push(updatedProfit);
				} else if (production_cost) {
					const updatedProfit = acc.length === 0 ? -production_cost : acc[acc.length - 1] - production_cost;
					acc.push(updatedProfit);
				}

				return acc;
			}, []) ?? [];

		const statisticsData =
			statisticsDataSorted?.map(({day, month, year}, index) => {
				const date = format(new Date(year, month - 1, day), "d MMM").toLowerCase();
				return {date, profit: accumulatedProfits[index] * 0.01};
			}) ?? [];

		const filteredData = filterMaxProfitByDate(statisticsData);

		const fillerData = Array(filteredData.length)
			.fill(0)
			.map((_, idx) => ({date: filteredData[idx].date, profit: 0}));

		const charData = fillerData.map(fillEl => {
			const el = filteredData.find(statEl => {
				return isSameDay(new Date(fillEl.date), new Date(statEl.date));
			});

			if (el) return el;

			return fillEl;
		});

		return {
			datasets: [
				{
					label: "Costo Vs Ganancia",
					data: charData,
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "profit",
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics?.daily_profit_memberships]);

	const options: ChartOptions<"line"> = {
		scales: {
			y: {
				beginAtZero: false,
			},
		},
		onHover: (event, activeElements) => {
			(event?.native?.target as HTMLElement).style.cursor = activeElements?.length > 0 ? "pointer" : "auto";
		},
		plugins: {
			tooltip: {
				enabled: true,
				callbacks: {
					label: function (context) {
						let label = context.dataset.label || "";

						if (label) {
							label += ": ";
						}
						if (context.parsed.y !== null) {
							label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
						}

						return label;
					},
				},
				mode: "index",
			},
			legend: {
				display: true,
				position: "top",
				align: "center",
				labels: {
					generateLabels(chart) {
						const legend = [
							{fillStyle: "red", text: "Relación de gastos e ingresos negativa"},
							{fillStyle: "green", text: "Relación de gastos e ingresos positiva"},
						];

						return legend;
					},
				},
				onClick: (e, legendItem, legend) => {},
			},
		},
		responsive: true,
	};

	useEffect(() => {
		if (!data || isLoading) return;

		const chart = new Chart(document.getElementById("myChart") as HTMLCanvasElement, {
			type: "line",
			data: data,
			options: options,
			plugins: [
				{
					id: "customLineColorPlugin",
					beforeRender: chart => {
						const ctx = chart.ctx;
						const yAxis = chart.scales["y"];
						const yPos = yAxis.getPixelForValue(0);
						const offset = yPos / chart.height;

						const gradientFill = ctx.createLinearGradient(0, 0, 0, chart.height);
						if (offset < 0) {
							gradientFill.addColorStop(0, "rgb(229,66,66)");
							gradientFill.addColorStop(1, "rgb(229,66,66)");
						} else if (offset > 1) {
							gradientFill.addColorStop(0, "rgb(86,188,77)");
							gradientFill.addColorStop(1, "rgb(86,188,77)");
						} else {
							gradientFill.addColorStop(0, "rgb(86,188,77)");
							gradientFill.addColorStop(offset, "rgb(86,188,77)");
							gradientFill.addColorStop(offset, "rgb(229,66,66)");
							gradientFill.addColorStop(1, "rgb(229,66,66)");
						}

						const datasets = chart.data.datasets;
						datasets.forEach(dataset => {
							dataset.borderColor = gradientFill;
						});
					},
				},
			],
		});

		return () => {
			chart.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, statistics]);

	return (
		<div>
			{isLoading ? (
				<div className="flex h-[400px] items-center justify-center rounded-lg border bg-card p-5">
					<Spinner />
				</div>
			) : (
				<>
					<h3 className="absolute ml-6 mt-6 text-lg font-semibold leading-none tracking-tight">Gráfica Costos Vs Ingresos Acumulados.</h3>
					<canvas id="myChart" width={350} height={110} className="h-[450px] w-full rounded-lg border bg-card p-8 pt-12"></canvas>
				</>
			)}
		</div>
	);
};

export default IncomeVsProfit;
