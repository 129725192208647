import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {EditSeriesDataBodySchema, EditSeriesDataBodyType, uploadSeriesImage} from "../../../../../data-access/series/series";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {Input} from "../../../../../components/primitives/Input";
import {Button} from "../../../../../components/primitives/Button";
import {ListPlus, Spinner} from "../../../../../components/primitives/icons";
import {Checkbox} from "../../../../../components/primitives/Checkbox";
import MuxVideoCombobox from "../../subcomponents/MuxVideoCombobox";
import {Textarea} from "../../../../../components/primitives/Textarea";
import {toast} from "react-hot-toast";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {auth} from "../../../../../firebase";
import {Image as ImageIcon} from "../../../../../components/primitives/icons";
import MultimediaImagePickerModal from "../../../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../../../_store/features/gallery/gallery-slice";
import {FOLDERS_NAMES} from "../../../../../../utils/folderNames";
import {DayPicker} from "../../../../../components/blocks/DatePicker";
import HourPicker from "../../../../../components/primitives/HourPicker/HourPicker";
import {convertToStringHour} from "../../../../../../utils/convertToStringHour";
import Select, {SingleValue} from "react-select";
import {SelectOptionType} from "../../../../../../utils/globalTypes";
import {Skeleton} from "../../../../../components/primitives/Skeleton";
import {selectGlobalStyles} from "../../../../../../utils/selectGlobalStyles";
import {fetchDbUsersData} from "../../../../../_store/features/user-db/user-db-actions";

const initialState: EditSeriesDataBodyType = {
	id: "",
	title: "",
	slug: "",
	description: "",
	ranking: 0,
	published: false,
	total_likes: 0,
	total_favorite: 0,
	total_dislikes: 0,
	video_mux_id: "",
	img_banner_web_desktop: "",
	img_banner_web_mobile: "",
	img_logo: "",
	img_thumbnail: "",
	position_order: 0,
	img_square_thumbnail: "",
	tags: "",
	membership_title: "",
	production_cost: "",
	account: null,
	link: null,
	release_date: null,
	whitelist_release_date: null,
	display_release_date: false,
};

type ImageFieldsTypes = keyof Pick<
	EditSeriesDataBodyType,
	"img_banner_web_desktop" | "img_banner_web_mobile" | "img_logo" | "img_square_thumbnail" | "img_thumbnail"
>;

export default function Details() {
	let {id} = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const serieData = useAppSelector(state => state.series.results.find(el => el.id === id));
	const users = useAppSelector(state => state.dbuser);
	const usersLoading = useAppSelector(state => state.dbuser.loading);
	const loading = useAppSelector(state => state.series.loading);
	const [usersList, setUsersList] = useState<SelectOptionType[]>([]);
	const [vinculatedUser, setVinculatedUser] = useState<SelectOptionType | null>(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [hourInMiami, setHourInMiami] = useState<string>("");
	const [whiteListHourInMiami, setWhiteListHourInMiami] = useState<string>("");
	const [hour, setHour] = useState<string>("00:00:00.000Z");
	const [whiteListHour, setWhiteListHour] = useState<string>("00:00:00.000Z");
	const [type, setType] = useState<ImageFieldsTypes>("img_logo");
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		control,
		formState: {errors, isLoading, isSubmitting},
	} = useForm<EditSeriesDataBodyType>({
		resolver: zodResolver(EditSeriesDataBodySchema),
		defaultValues: initialState,
	});
	const [fieldLoading, setFieldLoading] = useState<string[]>([]);

	useEffect(() => {
		reset(serieData);
	}, [reset, serieData]);

	useEffect(() => {
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: 999999,
					admin: false,
					role: undefined,
					active: true,
				}),
			);
		});
	}, [dispatch]);

	useMemo(() => {
		if (users.results) {
			setUsersList(
				users.results.map(user => ({
					label: user.name || "",
					value: user.userid || "",
				})),
			);
		}
	}, [users.results]);

	useEffect(() => {
		const vinculated = users.results.find(user => user.userid === serieData?.account);
		vinculated &&
			setVinculatedUser({
				label: vinculated.name,
				value: vinculated.userid,
			});
	}, [users.results, serieData?.account]);

	const onSubmit: SubmitHandler<EditSeriesDataBodyType> = data => {
		if (data.release_date) {
			const date = new Date(`${data.release_date.split("T")[0]}T${hour}Z`);
			data.release_date = date.toISOString();
		}

		if (data.whitelist_release_date) {
			const date = new Date(`${data.whitelist_release_date.split("T")[0]}T${whiteListHour}Z`);
			data.whitelist_release_date = date.toISOString();
		}
		return dispatch(seriesActions.updateSerie(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Serie actualizada correctamente");
				setTimeout(() => {
					navigate("/series/list");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	useEffect(() => {
		const release = getValues("release_date");
		const whiteList = getValues("whitelist_release_date");

		if (release) {
			const date = new Date(`${release.split("T")[0]}T${hour}Z`);
			const dateMiami = date.toLocaleString("en-US", {timeZone: "America/New_York"});
			setHourInMiami(dateMiami);
		}

		if (whiteList) {
			const date = new Date(`${whiteList.split("T")[0]}T${whiteListHour}Z`);
			const dateMiami = date.toLocaleString("en-US", {timeZone: "America/New_York"});
			setWhiteListHourInMiami(dateMiami);
		}
	}, [getValues, hour, whiteListHour]);

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("serieid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadSeriesImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	const handleSelectHour = (date: string) => {
		setHour(date);
	};

	const handleSelectWhiteListHour = (date: string) => {
		setWhiteListHour(date);
	};

	function handleSelectUser(newValue: SingleValue<SelectOptionType>) {
		if (newValue) {
			setVinculatedUser(newValue);
		}
	}

	return (
		<div className="px-4">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mt-6">
					<dl className="divide-y divide-border">
						<div className="px-4 py-6 pt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Titulo<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Insertar texto del anuncio..." {...register("title")} />
								{errors?.title?.message && <span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Slug<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Insertar nombre del anunciante..." {...register("slug")} />
								{errors?.slug?.message && <span className="text-sm font-medium text-destructive">{errors?.slug.message}</span>}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Público</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="items-top flex space-x-2">
									<Controller
										name="published"
										control={control}
										render={({field}) => <Checkbox id="terms1" checked={field.value} onCheckedChange={c => field.onChange(c)} />}
									></Controller>
									<div className="grid gap-1.5 leading-none">
										<label
											htmlFor="terms1"
											className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
										>
											Marcar serie como pública.
										</label>
										<p className="text-sm text-muted-foreground">
											Al marcar la serie como pública, se comenzara a mostrar en los espacios determinados de las plataformas.
										</p>
									</div>
								</div>
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Costo de Producción</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="number" placeholder="Costo de Producción de la serie..." {...register("production_cost")} />
								{errors?.production_cost?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.production_cost?.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
							<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<MuxVideoCombobox
									id="video_content_id"
									name="video_id"
									defaultValue={getValues("video_mux_id") ?? ""}
									onChangeSelected={selected => {
										setValue("video_mux_id", selected.playbackid);
									}}
								/>
								{errors?.video_mux_id?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.video_mux_id.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Descripción</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Textarea rows={5} placeholder="Descripción de la serie..." {...register("description")} />
								{errors?.description?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Usuario vinculado</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{!loading && !usersLoading ? (
									<div className="mt-1 flex flex-row items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
										<Controller
											name="account"
											control={control}
											render={({field}) => (
												<Select
													className="basic-multi-select mr-2 min-w-[200px] rounded"
													styles={selectGlobalStyles}
													options={usersList}
													isSearchable
													defaultValue={vinculatedUser}
													onChange={selectedOption => {
														handleSelectUser(selectedOption);
														field.onChange(selectedOption?.value);
													}}
													placeholder="Vincula un usuario"
												/>
											)}
										/>
										<Button className="ml-2 w-auto min-w-fit gap-2" asChild>
											<Link to="/users/new">
												<ListPlus />
												Nuevo usuario
											</Link>
										</Button>
									</div>
								) : (
									<Skeleton className="h-12 w-[350px] bg-slate-300" />
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Mostrar fecha de release</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="items-top flex space-x-2">
									<Controller
										name="display_release_date"
										control={control}
										render={({field}) => (
											<Checkbox id="display_release_date" checked={field.value} onCheckedChange={c => field.onChange(c)} />
										)}
									></Controller>
									<div className="grid gap-1.5 leading-none">
										<label
											htmlFor="display_release_date"
											className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
										>
											Mostrar cuenta atrás de le fecha de release.
										</label>
										<p className="text-sm text-muted-foreground">
											Al marcar el checkbox, el usuario podrá ver el tiempo que falta para el release.
										</p>
									</div>
								</div>
							</dd>
						</div>

						<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
							<dd className="mt-1 justify-start self-start sm:col-span-2 sm:mt-0">
								<div className="flex flex-row">
									<Controller
										name="release_date"
										control={control}
										render={({field}) => (
											<DayPicker
												selectedDay={serieData?.release_date ? new Date(serieData?.release_date) : undefined}
												{...field}
												onDayChange={date => {
													const dateFormatted = date.toISOString();
													field.onChange(dateFormatted);
												}}
												className="min-w-[150px] justify-start self-start"
											/>
										)}
									/>
									<p className="mx-2 self-center text-xl">-</p>
									<HourPicker
										hourSelected={convertToStringHour(serieData?.release_date)}
										onTimeChange={date => handleSelectHour(date)}
									/>
									{hourInMiami && (
										<p className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">{hourInMiami} - Miami</p>
									)}
								</div>
								{errors?.release_date?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
								)}
							</dd>
						</div>

						<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
								Fecha de release <p className="ml-2 text-xs font-bold text-destructive">*Whitelist</p>
							</dt>
							<dd className="mt-1 justify-start self-start sm:col-span-2 sm:mt-0">
								<div className="flex flex-row">
									<Controller
										name="whitelist_release_date"
										control={control}
										render={({field}) => (
											<DayPicker
												selectedDay={
													serieData?.whitelist_release_date ? new Date(serieData?.whitelist_release_date) : undefined
												}
												{...field}
												onDayChange={date => {
													const dateFormatted = date.toISOString();
													field.onChange(dateFormatted);
												}}
												className="min-w-[150px] justify-start self-start"
											/>
										)}
									/>
									<p className="mx-2 self-center text-xl">-</p>
									<HourPicker
										hourSelected={convertToStringHour(serieData?.whitelist_release_date)}
										onTimeChange={date => handleSelectWhiteListHour(date)}
									/>
									{whiteListHourInMiami && (
										<p className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">
											{whiteListHourInMiami} - Miami
										</p>
									)}
								</div>
								{errors?.release_date?.message && (
									<span className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">
										{errors?.release_date?.message}
									</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">
								Titulo en Membresía<span className="text-xs font-bold text-destructive">*</span>
							</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="text" placeholder="Titulo en Membresía..." {...register("membership_title")} />
								{errors?.membership_title?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.membership_title?.message}</span>
								)}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Palabras Clave</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Textarea placeholder="Palabras claves de la serie" rows={3} {...register("tags")} />
								<span className="text-sm text-muted-foreground">*Adicione palabras clave separada por comas</span>
								{errors?.tags?.message && <span className="text-sm font-medium text-destructive">{errors?.tags?.message}</span>}
							</dd>
						</div>

						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<Input type="number" placeholder="Orden de la serie..." {...register("position_order", {valueAsNumber: true})} />
								{errors?.position_order?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.position_order?.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Logo</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_logo") ? (
											fieldLoading.includes("img_logo") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_logo")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_logo");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_logo");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_logo?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_logo.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Thumbnail</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_thumbnail") ? (
											fieldLoading.includes("img_thumbnail") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_thumbnail")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_thumbnail");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_thumbnail");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_thumbnail?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_thumbnail.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Thumbnail Square</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_square_thumbnail") ? (
											fieldLoading.includes("img_square_thumbnail") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_square_thumbnail")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_square_thumbnail");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_square_thumbnail");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_square_thumbnail?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_square_thumbnail.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Web Desktop</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_banner_web_desktop") ? (
											fieldLoading.includes("img_banner_web_desktop") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_banner_web_desktop")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_banner_web_desktop");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_banner_web_desktop");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_banner_web_desktop?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_banner_web_desktop.message}</span>
								)}
							</dd>
						</div>
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-medium leading-6 text-gray-900">Imagen Web Mobile</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								<div className="grid w-full max-w-sm items-center gap-1.5">
									<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
										{!getValues("img_banner_web_mobile") ? (
											fieldLoading.includes("img_banner_web_mobile") ? (
												<Spinner />
											) : (
												<ImageIcon className="h-6 w-6 text-slate-400" />
											)
										) : (
											<img src={getValues("img_banner_web_mobile")} className="w-full object-contain" alt="Thumbnail" />
										)}
									</div>
									<Input
										type="file"
										onChange={e => {
											const [file] = e.target.files as any;
											handleUploadImage(file, "img_banner_web_mobile");
										}}
										placeholder="Elige un Archivo"
									/>
									<Button
										variant="outline"
										type="button"
										className="gap-2 whitespace-nowrap"
										onClick={() => {
											handleSelectImage("img_banner_web_mobile");
										}}
									>
										<ImageIcon className="h-4 w-4" />
										Seleccionar de multimedia
									</Button>
								</div>
								{errors?.img_banner_web_mobile?.message && (
									<span className="text-sm font-medium text-destructive">{errors?.img_banner_web_mobile.message}</span>
								)}
							</dd>
						</div>
					</dl>
				</div>
				<div className="flex justify-end gap-2 pb-4">
					<Button
						type="button"
						variant="secondary"
						onClick={() => {
							navigate("/series/list");
							dispatch(galleryActions.resetToInitial());
						}}
					>
						Cancelar
					</Button>
					<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
						{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Guardar cambios
					</Button>
				</div>
			</form>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={5}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setValue(type, url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</div>
	);
}
