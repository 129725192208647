import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import "chartjs-adapter-date-fns";
import {addDays, differenceInDays, endOfDay, endOfToday, format, formatISO9075, roundToNearestMinutes, startOfDay, subYears} from "date-fns";
import {useEffect, useMemo, useRef, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {useAppDispatch} from "../../../../_store/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import Loader from "./Loader";
import {stringToColor} from "../../../../../utils/stringToColor";
import {DatePickerWithRange} from "../../DateRangePicker";
import {DateRange} from "react-day-picker";
import {Spinner} from "../../../primitives/icons";
import {auth} from "../../../../firebase";
import {thirtyDays, transformedData} from "../../../../../utils/barGraphTransformData";
import {es} from "date-fns/locale";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface SponsorAdViewsProps {
	sponsorid?: string;
	adid?: string;
	isCorporate?: boolean;
	date_range?: DateRange;
}

export function SponsorAdViews({sponsorid, adid, isCorporate = false, date_range}: SponsorAdViewsProps) {
	const dispatch = useAppDispatch();
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; views: number}[]>>(null);
	const {data: statistics, isLoading: isStatisticsLoading, isUninitialized} = useDashboardByNameQuery("sponsorAdViews");
	const {data: series, isLoading: isSeriesLoading} = useDashboardByNameQuery("topSeries");
	const [showByMonths, setShowByMonths] = useState(false);

	const [dates, setSelectedDate] = useState<DateRange | undefined>(() => {
		const to = roundToNearestMinutes(endOfToday());
		const from = startOfDay(addDays(Date.now(), -30));
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		const durationSponsor = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		const durationCorporate = date_range ? date_range?.to!.getTime() - date_range?.from!.getTime() : 0;
		const durationInMilliseconds = isCorporate ? durationCorporate : durationSponsor;
		setShowByMonths(durationInMilliseconds > thirtyDays);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_range, dates]);

	useEffect(() => {
		dispatch(
			dashboardActions.getSponsorAdViews({
				initial_date: isCorporate && date_range ? formatISO9075(date_range?.from!) : formatISO9075(dates?.from!),
				final_date: isCorporate && date_range ? formatISO9075(date_range?.to!) : formatISO9075(dates?.to!),
				sponsorid: sponsorid || auth.currentUser?.uid,
				adid,
			}),
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, dispatch, adid, date_range]);

	useEffect(() => {
		dispatch(dashboardActions.getTopSeries());
	}, [dispatch]);

	const data: ChartData<"bar", {date: string; views: number}[]> | null = useMemo(() => {
		const startDate = isCorporate && date_range ? new Date(date_range?.from!) : new Date(dates?.from ?? 0);
		const endDate = isCorporate && date_range ? new Date(date_range?.to!) : new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;

		const fillerData = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), views: 0}));

		if (isStatisticsLoading || isSeriesLoading || !series?.length || !statistics?.daily_views?.length)
			return {
				datasets: [
					{
						label: "Impressions",
						data: fillerData,
						backgroundColor: stringToColor("empty"),
						borderRadius: 4,
						parsing: {
							xAxisKey: "date",
							yAxisKey: "views",
						},
					},
				],
			};

		const impressionsBySerie: Record<string, {date: string; views: number}[]> = {};

		series?.forEach(serie => {
			if (!serie.seriesid) return;
			impressionsBySerie[serie.seriesid] = [];
		});

		for (let i = 0; i < (statistics?.daily_views?.length ?? 0); i++) {
			const element = statistics?.daily_views[i];
			if (!element || !element?.seriesid) continue;

			{
				const {date, views, seriesid} = element;

				impressionsBySerie[seriesid]?.push({
					date,
					views,
				});
			}
		}

		const datasets = Object.entries(impressionsBySerie)
			.filter(([_, viewData]) => !!viewData.length)
			.map(([serieId, viewData]) => {
				const serieTitle = series.find(el => el.seriesid === serieId);

				return {
					label: serieTitle?.title,
					data: transformedData(fillerData, viewData, showByMonths),
					backgroundColor: stringToColor(serieId),
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "views",
					},
				};
			});

		return {
			datasets,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series, statistics?.daily_views, dates, date_range]);

	const handleDateRangeChange = (s: number, e: number) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	if ((isSeriesLoading || isStatisticsLoading) && isUninitialized) return <Loader title="Vistas de Anuncios/Series." />;

	return (
		<div className="grid grid-rows-[auto,min-content] md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex items-center justify-between space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Vistas de Anuncios/Series.</h3>
					{!isCorporate && (
						<DatePickerWithRange
							onDateRangeChange={handleDateRangeChange}
							disabled={{before: subYears(new Date(), 1), after: new Date()}}
							date={dates}
						/>
					)}
				</div>
				{isStatisticsLoading ? (
					<div className="flex h-[350px] flex-col items-center justify-center space-y-2">
						<Spinner />
						<p className="text-sm text-gray-800">Cargando datos</p>
					</div>
				) : (
					<div className="relative p-6 pt-0">
						{data && (
							<Bar
								height={350}
								ref={chartRef}
								options={{
									responsive: true,
									maintainAspectRatio: false,
									plugins: {
										tooltip: {
											callbacks: {
												title(tooltipItems) {
													const date = new Date(tooltipItems[0].label);
													if (showByMonths) {
														return format(date, "yyyy, MMM", {locale: es});
													}
													return format(date, "EE, dd MMMM", {locale: es});
												},
											},
										},
									},
									scales: {
										x: {
											ticks: {
												callback(tickValue) {
													const date = new Date(this.getLabelForValue(tickValue as any));

													const formattedDate = showByMonths
														? format(date, "MMM", {locale: es}).toLowerCase()
														: format(date, "d MMM", {locale: es}).toLowerCase();

													return formattedDate;
												},
											},
											grid: {
												color: "transparent",
											},
											stacked: true,
										},
										y: {
											stacked: true,
											beginAtZero: true,
										},
									},
								}}
								data={data}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
