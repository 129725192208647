import ContentSections from "./tabs/contents";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../../components/primitives/Tabs";
import {Separator} from "../../../components/primitives/Separator";
import Details from "./tabs/details";
import {CreditSection} from "./tabs/credits";
import {CastSection} from "./tabs/cast";
import {useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../_store/hooks";

function SeriesEdit() {
	const navigate = useNavigate();
	const location = useLocation();
	let {id} = useParams();
	const serieData = useAppSelector(state => state.series.results.find(el => el.id === id));

	useEffect(() => {
		if (!serieData) {
			return navigate("/series/list");
		}
	}, [navigate, serieData]);

	return (
		<>
			<div>
				<div className="space-y-0.5 px-6 pt-4">
					<h2 className="text-2xl font-bold tracking-tight">Editar Serie</h2>
					<p className="text-muted-foreground">Edita los datos de las series de la plataforma.</p>
				</div>
				<div className="mx-6">
					<Separator className="mb-4 mt-2" />
				</div>
				<Tabs defaultValue={location.state ? location.state.param : "details"}>
					<div className="sticky top-0 z-10 bg-background px-6 pt-2">
						<TabsList className="bg-primary">
							<TabsTrigger value="details">Detalles</TabsTrigger>
							<TabsTrigger value="contents">Contenidos</TabsTrigger>
							<TabsTrigger value="cast">Elenco</TabsTrigger>
							<TabsTrigger value="credits">Créditos</TabsTrigger>
						</TabsList>
					</div>
					<div className="px-6">
						<TabsContent value="details">
							<Details />
						</TabsContent>
						<TabsContent value="contents">
							<ContentSections />
						</TabsContent>
						<TabsContent value="cast">
							<CastSection />
						</TabsContent>
						<TabsContent value="credits">
							<CreditSection />
						</TabsContent>
					</div>
				</Tabs>
			</div>
		</>
	);
}

export default SeriesEdit;
