import "chartjs-adapter-date-fns";
import {useMemo} from "react";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {Card} from "../Card";
import {Spinner} from "../../../primitives/icons";
import CostEarnGraphic from "../CostEarnGraphic";
import {DateRange} from "react-day-picker";

interface GeneralIncomeProps {
	serieId: string | undefined;
	dates: DateRange | undefined;
}

export function GeneralIncome({serieId, dates}: GeneralIncomeProps) {
	const {data: statistics, isLoading: isStatisticsLoading} = useDashboardByNameQuery("generalIncome");

	const range_profit = serieId
		? statistics?.range_profit_memberships ?? 0
		: (statistics?.range_profit_coins ?? 0) + (statistics?.range_profit_memberships ?? 0);
	const total_profit = serieId
		? statistics?.total_profit_memberships ?? 0
		: (statistics?.total_profit_coins ?? 0) + (statistics?.total_profit_memberships ?? 0);

	const earnings = range_profit > statistics?.production_cost! ? (range_profit - statistics?.production_cost!) * 0.01 : 0;

	const profitPercentage = useMemo(() => {
		if (statistics?.production_cost && total_profit) {
			const relation = statistics.production_cost / total_profit;

			if (relation < 0) {
				return 100;
			} else {
				return Math.min(100, relation * 100);
			}
		}

		return 100;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics, serieId, dates]);

	const earnPercentage = useMemo(() => {
		if (statistics?.production_cost && total_profit) {
			const earnings = total_profit > statistics.production_cost ? total_profit - statistics.production_cost : 0;

			const relation = earnings / statistics.production_cost;

			if (relation === 1) {
				return 50;
			} else {
				return Math.min(100, relation * 50);
			}
		}

		return 100;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics, serieId, dates]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-3 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 grid grid-cols-2 gap-3 overflow-y-auto xl:grid-cols-3">
				<Card
					title="Total de costo de Producción"
					amount={statistics?.production_cost ? statistics?.production_cost / 100 : 0}
					tooltip="Total de costo de producción del contenido."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Total de ingresos Bruto"
					amount={range_profit ? range_profit / 100 : 0}
					tooltip="Total de ingresos de la plataforma."
					loading={isStatisticsLoading}
				/>
				<Card title="Total de ganancias" amount={earnings} tooltip="Total de ganancias de la plataforma." loading={isStatisticsLoading} />
			</div>
			{serieId && (
				<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
					<div className="flex flex-col space-y-1.5 p-6">
						<h3 className="text-lg font-semibold leading-none tracking-tight">Costos/Ganancias</h3>
					</div>
					{isStatisticsLoading ? (
						<div className="flex h-[400px] items-center justify-center">
							<Spinner />
						</div>
					) : (
						<>
							{statistics && (
								<CostEarnGraphic
									statistics={{...statistics, range_profit, total_profit}}
									earnPercentage={earnPercentage}
									profitPercentage={profitPercentage}
								/>
							)}
						</>
					)}
				</div>
			)}
		</div>
	);
}
