import axios, {AxiosResponse} from "axios";
import {
	DimensionGetResponse,
	DimensionQueryParams,
	MetricsComparisonQueryParams,
	MetricsComparisonResponse,
	MetricsTimeseriesQueryParams,
	MetricsTimeSeriesResponse,
	MetricsViewsSeriesResponse,
	ReferredViewsSeriesQueryParams,
	TopStatsResponse,
	VideoViewsListResponse,
	VideoViewsQueryParams,
} from "./types";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export function listViews(token: string, params?: VideoViewsQueryParams): Promise<AxiosResponse<VideoViewsListResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get("/stats/data/v1/video-views", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export function listAllMetricsValues(token: string, params?: MetricsComparisonQueryParams): Promise<AxiosResponse<MetricsComparisonResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get("/stats/data/v1/metrics/comparison", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export function getMetricsTimeSeries(
	token: string,
	metricId: string,
	params?: MetricsTimeseriesQueryParams,
): Promise<AxiosResponse<MetricsTimeSeriesResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get(`/stats/data/v1/metrics/${metricId}/timeseries`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export function getReferredViewsSeries(token: string, params?: ReferredViewsSeriesQueryParams): Promise<AxiosResponse<MetricsViewsSeriesResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get("/get_views_referred_influencer", {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export function getDimensions(token: string, dimensionId: string, params?: DimensionQueryParams): Promise<AxiosResponse<DimensionGetResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get(`/stats/data/v1/dimensions/${dimensionId}`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}

export function getTopContent(token: string, seriesid_list: string): Promise<AxiosResponse<TopStatsResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get(`/get_top_content`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
		params: {
			seriesid_list,
		},
	});
}

export function getTopSeries(token: string): Promise<AxiosResponse<TopStatsResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.get(`/get_top_series`, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface CreateLiveChapterResponse {
	stream_key: string;
	stream_id: string;
	stream_playback_id: string;
}
export function createLiveChapter(token: string): Promise<AxiosResponse<CreateLiveChapterResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.post(`/create_live_chapter`, undefined, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface AdminFinishLiveChapterBody {
	streamid: string;
	contentid: string;
}

export interface AdminFinishLiveChapterResponse {
	result: string;
}
export function adminFinishLiveChapter(token: string, body: AdminFinishLiveChapterBody): Promise<AxiosResponse<AdminFinishLiveChapterResponse>> {
	const idToken = `Bearer ${token}`;
	return axiosHandler.post(`/admin_finish_live_chapter`, body, {
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
