import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {dashboardActions, selectedPartnerSerieSelector, selectedPartnerSeries} from "../../../../_store/features/dashboard/dashboard-slice";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {LikesAndFavorites, MembershipUserViews, SerieIncomeAndProfit, TopViews} from "../../../../components/blocks/Stats";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {GetInfluencerAndPartnerTransactionsResponse} from "../../../../data-access/dashboard/statistics";
import {MEMBERSHIP_TYPES} from "../../../../constants";
import Select, {ActionMeta, MultiValue} from "react-select";
import {Spinner} from "../../../../components/primitives/icons";
import {Skeleton} from "../../../../components/primitives/Skeleton";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {DataTable} from "../../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import PartnerSeriesViews from "../../../../components/blocks/Stats/PartnerSeriesViews";
import {formattedDayAndHour} from "../../../../../utils/formattedDayAndHour";
import {SelectOptionType} from "../../../../../utils/globalTypes";

const columnHelper = createColumnHelper<GetInfluencerAndPartnerTransactionsResponse>();

export function SerieStatistics({disableRedirect = false}: {disableRedirect?: boolean}) {
	const selectedPartnerSerie = useAppSelector(selectedPartnerSerieSelector);
	const partnerSeries = useAppSelector(selectedPartnerSeries);
	const {isLoading} = useDashboardByNameQuery("topSeriesContents");
	const {isLoading: tableLoading, data} = useDashboardByNameQuery("latestTransactions");
	const [noSelection, setNoSelection] = useState(false);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [listContents, setListContents] = useState<SelectOptionType[]>();
	const [firstSelection, setFirstSelection] = useState<SelectOptionType[] | null>(null);
	const [seriesidList, setSeriesIdList] = useState<string>("");

	useEffect(() => {
		if (seriesidList === "") {
			return;
		}
		dispatch(
			dashboardActions.getInfluencerAndPartnerTransactions({
				seriesid_list: seriesidList,
			}),
		);
		dispatch(dashboardActions.getTopSeries());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, seriesidList]);

	useEffect(() => {
		if (partnerSeries && partnerSeries.length) {
			setListContents(
				partnerSeries.map(content => ({
					label: content.title || "",
					value: content.serieid || "",
				})),
			);
			if (seriesidList === "") {
				setSeriesIdList(partnerSeries.map(series => series.serieid).join(","));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partnerSeries?.length, seriesidList]);

	useEffect(() => {
		if (!selectedPartnerSerie && !disableRedirect) {
			navigate("/partner/series");
		}
	}, [disableRedirect, navigate, selectedPartnerSerie]);

	const handleClickOnContent = (contentId: string) => {
		dispatch(dashboardActions.setSelectedContentId(contentId));
		navigate(`/partner/contents/${selectedPartnerSerie?.serieid}/${contentId}`);
	};

	function handleOnChange(newValue: MultiValue<SelectOptionType>, actionMeta: ActionMeta<SelectOptionType>) {
		if (newValue.length === 0) {
			setNoSelection(true);
			setSeriesIdList("");
			setFirstSelection(null);
		} else {
			setNoSelection(false);
			setSeriesIdList(newValue.map(series => series.value).join(","));
			setFirstSelection([...newValue]);
		}
	}

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 5);

		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col ">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("membership_type", {
				id: "Membresía",
				header: ({column}) => <DataTableColumnHeader title="Membresía" column={column} />,
				cell: info => {
					const membershipValue = info.row.original.membership_type;
					const membership = MEMBERSHIP_TYPES.find(type => type.value === membershipValue);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis  text-left">{membership ? membership.label : ""}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("price", {
				id: "Precio",
				header: ({column}) => <DataTableColumnHeader title="Precio" column={column} />,
				cell: info => {
					const price = (info.row.original.price / 100).toFixed(2);

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">$ {price}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("title", {
				id: "Título",
				header: ({column}) => <DataTableColumnHeader title="Serie" column={column} />,
				cell: info => {
					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.title}</span>
						</div>
					);
				},
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	return (
		<>
			{!listContents ? (
				<div className="flex h-screen items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
						<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Partner Dashboard</h2>
					</div>

					{partnerSeries?.length === 1 && (
						<div className="flex items-center justify-between bg-background pl-6 pt-6">
							<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">{partnerSeries[0].title}</h2>
						</div>
					)}

					{partnerSeries && <SerieIncomeAndProfit seriesidList={seriesidList} partnerSeries={partnerSeries} />}

					<div>
						{partnerSeries && partnerSeries?.length > 1 ? (
							<>
								{isLoading ? (
									<Skeleton className="mx-7 my-7 h-10 bg-slate-300" />
								) : (
									<Select
										className="basic-multi-select mx-7 my-7"
										isSearchable={true}
										isMulti={true}
										options={listContents}
										defaultValue={firstSelection === null ? listContents : firstSelection}
										classNamePrefix="select"
										onChange={handleOnChange}
										placeholder="Selecciona las series para ver sus estadísticas"
									/>
								)}
							</>
						) : (
							<></>
						)}
					</div>

					{noSelection ? (
						<div className="mx-auto flex h-full max-w-lg flex-col items-center justify-center text-center">
							<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
								<InformationCircle />
							</p>
							<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series seleccionadas.</h1>
							<p className="leading-7 [&:not(:first-child)]:mt-6">Debes seleccionar al menos una serie para ver sus estadísticas.</p>
						</div>
					) : (
						<>
							<div className="mt-8 flex items-center justify-between px-6">
								<h3 className="text-lg font-semibold leading-none tracking-tight">Últimas membresías registradas.</h3>
							</div>
							{tableLoading ? (
								<Skeleton className="mx-7 my-7 h-[400px] bg-slate-300" />
							) : (
								<div className="flex h-full flex-col p-6 pt-4">
									<DataTable
										ref={ref => setTableRef(ref)}
										columns={columns}
										dataset={data!}
										pageCount={Math.round((data?.length ?? 0) / pagination.pageSize)}
										pagination={pagination}
										onPaginationChange={setPagination}
										manualPagination={false}
										showPagination={false}
									/>
								</div>
							)}

							<div className="px-8">
								<PartnerSeriesViews
									serieid={seriesidList}
									loading={isLoading}
									title={
										listContents.length > 1
											? "Vistas de las series seleccionadas"
											: `Vistas de la serie ${listContents![0].label}`
									}
								/>

								<LikesAndFavorites seriesIds={seriesidList} />
							</div>
							<div className="grid gap-6 px-8 pb-6 xl:grid-cols-[min-content,auto]">
								<div>
									<MembershipUserViews serieid={seriesidList} />
								</div>
								<div>
									<TopViews type="contents" serieid={seriesidList} onClickContent={handleClickOnContent} />
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}
