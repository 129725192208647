import {useEffect, useMemo, useState} from "react";
import {auth} from "../../../firebase";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {Spinner} from "../../../components/primitives/icons/Spinner";
import {serieProductsActions} from "../../../_store/features/products/serie-products-slice";
import {SerieIFormInputs} from "../SerieEdit";
import {toast} from "react-hot-toast";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../../components/primitives/Dialog";
import {Input} from "../../../components/primitives/Input";
import {Button} from "../../../components/primitives/Button";
import {Textarea} from "../../../components/primitives/Textarea";
import {FileUpload} from "../../../components/primitives/FileUpload";
import {uploadProductStripeImage} from "../../../data-access/products/coin-stripe";

interface SerieGoogleModalProps {
	onDismiss: () => void;
	onSuccess: (productUpdated: "apple" | "google" | "stripe") => void;
	productType: "apple" | "google" | "stripe";
	open: boolean;
	data?: SerieIFormInputs;
}

export function CreateUpdateMembershipDialog({open, onDismiss, data, onSuccess, productType}: SerieGoogleModalProps) {
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(state => state.serieProducts.loading);
	const error = useAppSelector(state => state.serieProducts.error);
	const [isUpdating, setIsUpdating] = useState(false);
	const [confirmUpdate, setConfirmUpdate] = useState(false);
	const [confirmText, setConfirmText] = useState("");
	const [textCoincides, setTextCoincides] = useState<boolean>();
	const [dataImage, setDataImage] = useState();
	const [fileLoading, setFileLoading] = useState(false);

	useEffect(() => {
		if (error) {
			toast.error("ERROR: " + error);
			handleDismiss();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		setIsUpdating(false);
		if ((data?.google_id && productType === "google") || (data?.stripe_productid && productType === "stripe") || productType === "apple") {
			setIsUpdating(true);
		}
	}, [data?.apple_id, data?.google_id, data?.stripe_productid, productType]);

	async function handleSubmitGoogle() {
		if (!auth.currentUser) return;
		if (data?.name !== confirmText && isUpdating) {
			setTextCoincides(false);
			return;
		}
		if (data && !data?.google_id) {
			dispatch(serieProductsActions.insertSerieGoogleProducts({productid: data?.id}));
		}
		data &&
			dispatch(serieProductsActions.editSerieProducts({...data, productid: data.id})).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					dispatch(serieProductsActions.setSuccess(false));
					resetStates();
					onSuccess?.("google");
				} else {
					handleDismiss();
				}
			});
	}

	async function handleSubmitApple() {
		if (!auth.currentUser) return;
		if (data?.name !== confirmText && isUpdating) {
			setTextCoincides(false);
			return;
		}

		data &&
			dispatch(serieProductsActions.editSerieProducts({...data, productid: data.id})).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					dispatch(serieProductsActions.setSuccess(false));
					resetStates();
					onSuccess?.("apple");
				} else {
					handleDismiss();
				}
			});
	}

	async function handleSubmitStripe() {
		if (!auth.currentUser) return;
		if (data?.name !== confirmText && isUpdating) {
			setTextCoincides(false);
			return;
		}
		if (data && !data?.stripe_productid) {
			dispatch(serieProductsActions.insertSerieStripeProducts({productid: data?.id, images: dataImage ? [dataImage] : undefined}));
		}
		data &&
			dispatch(serieProductsActions.editSerieProducts({...data, productid: data.id})).then(res => {
				if (res.meta.requestStatus === "fulfilled") {
					dispatch(serieProductsActions.setSuccess(false));
					resetStates();
					onSuccess?.("stripe");
				} else {
					handleDismiss();
				}
			});
	}

	function handleDismiss() {
		resetStates();
		onDismiss?.();
	}

	const handleUploadImage = (file: any, type: string) => {
		const body = new FormData();
		setFileLoading(true);

		body.append("file", file);
		body.append("productid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadProductStripeImage(token, body)
					.then(res => {
						setDataImage(res.data.result);
						setFileLoading(false);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const resetStates = () => {
		setConfirmUpdate(false);
		setConfirmText("");
		setTextCoincides(undefined);
		setDataImage(undefined);
		setFileLoading(false);
	};

	const valueNumber = useMemo(() => {
		switch (productType) {
			case "google":
				return data?.google_price;
			case "stripe":
				return data?.stripe_price;
			case "apple":
				return data?.apple_price;
		}
	}, [data?.apple_price, data?.google_price, data?.stripe_price, productType]);

	return (
		<Dialog
			open={open}
			onOpenChange={() => {
				handleDismiss();
			}}
		>
			<DialogContent className="sm:max-h-3/4 bg-neutral-700 sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle className="font-medium text-white">
						{!isUpdating && productType === "google" && "Crear producto en Google"}
						{isUpdating && productType === "google" && "Actualizar producto de Google"}
						{!isUpdating && productType === "stripe" && "Crear producto en Stripe"}
						{isUpdating && productType === "stripe" && "Actualizar producto de Stripe"}
						{productType === "apple" && "Actualizar producto de Apple"}
					</DialogTitle>
				</DialogHeader>
				<div className="flex flex-col">
					<div className="flex w-full">
						<label className="my-4 text-white" htmlFor="labelName">
							Nombre:
						</label>
						<Input
							id="labelName"
							className="form-control m-2 w-80 rounded bg-black px-3 py-2 text-white"
							type="text"
							name="title"
							placeholder="Nombre del producto"
							value={data?.name}
							disabled
						/>
					</div>

					<div className="flex w-full">
						<label className="my-4 text-white" htmlFor="description">
							Descripción:
						</label>
						<Textarea
							id="description"
							className="form-control m-2 w-full rounded bg-black px-3 py-2 text-white"
							name="description"
							placeholder="Descripción del producto membresía en Google"
							value={data?.description}
							disabled
						/>
					</div>

					<div className="flex w-full">
						<label className="my-4 text-white" htmlFor="labelPrice">
							Precio:
						</label>
						<Input
							id="labelPrice"
							className="form-control m-2 w-80 rounded bg-black px-3 py-2 text-white"
							type="text"
							name="price"
							placeholder="Precio del producto"
							value={valueNumber ? "$" + (valueNumber / 100).toFixed(2) : "0"}
							disabled
						/>
					</div>
				</div>

				{productType === "stripe" && (
					<div className="flex w-full">
						<label className=" text-white">Imagen:</label>
						<div className="ml-2 flex items-end gap-3 text-white">
							{!dataImage ? (
								<div className="flex h-40 w-40 items-center justify-center bg-neutral-300">{fileLoading && <Spinner />}</div>
							) : (
								<img src={dataImage} className="h-40 w-40 bg-neutral-300 object-contain" alt="Logo" />
							)}
							<FileUpload
								name="img_product"
								onChange={e => {
									const [file] = e.target.files as any;
									handleUploadImage(file, e.target.name);
								}}
								accept="image/png, image/jpeg"
							/>
						</div>
					</div>
				)}

				{isUpdating && confirmUpdate && (
					<div className="flex w-full flex-col">
						<label className="mt-4 text-white" htmlFor="confirmLabel">
							Confirma la acción:
						</label>
						<p className="my-2 inline text-white">
							Al confirmar actualizaras el precio del producto en la información que se le muestra a los usuarios de la plataforma, pero
							debes actualizarlo también en la pasarela de pagos correspondiente. Si estas de acuerdo escribe{" "}
							<p className="inline font-bold">{`"${data?.name}"`}</p> para confirmar.
						</p>

						{textCoincides === false && (
							<p className="my-2 font-bold text-destructive">*El texto escrito no coincide con el nombre del producto!</p>
						)}
						<div className="flex w-full flex-row items-center justify-between">
							<Input
								id="confirmLabel"
								className="form-control m-2 w-full rounded bg-black px-3 py-2 text-white"
								type="text"
								name="price"
								placeholder="Precio del producto"
								onChange={e => {
									setConfirmText(e.target.value);
									setTextCoincides(e.target.value === data?.name ? true : false);
								}}
							/>
							<Button
								className="w-40"
								size="sm"
								variant="success"
								onClick={
									productType === "google" ? handleSubmitGoogle : productType === "stripe" ? handleSubmitStripe : handleSubmitApple
								}
								type="submit"
								disabled={isLoading}
							>
								{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
								Confirmar
							</Button>
						</div>
					</div>
				)}

				<div className="flex flex-row justify-end">
					<Button className="mr-2 w-32 text-white" size="sm" variant="outline" onClick={handleDismiss}>
						Cancelar
					</Button>
					<Button
						className="w-32 bg-black text-white"
						size="sm"
						variant="outline"
						onClick={() => {
							if (isUpdating) {
								setConfirmUpdate(true);
								return;
							}
							switch (productType) {
								case "google":
									handleSubmitGoogle();
									break;
								case "stripe":
									handleSubmitStripe();
									break;
								case "apple":
									handleSubmitApple();
									break;
							}
						}}
						type="submit"
						disabled={isLoading}
					>
						{isLoading && !isUpdating && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
						Guardar
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
