import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {cn} from "../../../../utils/classNames";
import {Serie, seriesActions} from "../../../_store/features/series/series-slice";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import ActionConfirmModal from "../../../components/blocks/ActionConfirmModal";
import {Badge} from "../../../components/primitives/Badge";
import {Button} from "../../../components/primitives/Button";
import {DataTableColumnHeader} from "../../../components/primitives/DataTable";
import {ListPlus, Pencil, Trash} from "../../../components/primitives/icons";
import {Refresh} from "../../../components/primitives/icons/Refresh";
import {deleteSeries} from "../../../data-access/series/series";
import {auth} from "../../../firebase";
import {Input} from "../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/primitives/Select";
import {DataTable} from "../../../components/blocks/DataTable";

const searchOptions = [
	{name: "Titulo", value: "title"},
	{name: "Slug", value: "slug"},
	{name: "Description", value: "description"},
];

const publishedOptions = [
	{name: "Todas", value: undefined},
	{name: "Solo Publicas", value: true},
	{name: "Solo Privadas", value: false},
];

const columnHelper = createColumnHelper<Serie>();

function SeriesList() {
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series);
	const [page, setPage] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [searchValue, setSearchValue] = useState("");
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedPublished, setSelectedPublished] = useState(publishedOptions[0]);
	const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
	const [deleteSerieID, setDeleteSerieID] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const handleSearch = () => {
		const params: Record<string, any> = {};
		params.published = selectedPublished.value;

		if (searchValue) {
			params.searchvalue = searchValue;
			params.searchby = selectedSearchBy.value;
		}

		dispatch(seriesActions.getSeriesList({params: {page: page.pageIndex, page_size: page.pageSize, ...params}}));
	};

	const refresh = () => {
		setSelectedPublished(publishedOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setSearchValue("");
		dispatch(seriesActions.getSeriesList({params: {page: page.pageIndex, page_size: page.pageSize}}));
	};

	useEffect(() => {
		if (!page.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const ORDER_COL_WIDTH = 34;
		const LOGO_COL_WIDTH = 64;
		const CONTROL_COL_WIDTH = 112;

		if (tableWidth > 0) {
			tableWidth = tableWidth - ORDER_COL_WIDTH - LOGO_COL_WIDTH - CONTROL_COL_WIDTH;
		}

		return [
			columnHelper.accessor("position_order", {
				id: "Orden",
				header: ({column}) => <DataTableColumnHeader title="#" column={column} />,
				cell: info => info.getValue(),
				size: ORDER_COL_WIDTH,
				enableSorting: false,
				enableResizing: false,
			}),
			columnHelper.accessor("img_logo", {
				id: "Logo",
				header: ({column}) => <DataTableColumnHeader title="Logo" column={column} />,
				cell: info => (
					<img
						alt="Banner"
						src={info.getValue()}
						className={cn(
							"relative mx-auto aspect-square w-full object-contain text-center",
							"before:absolute before:flex before:h-full before:w-full before:items-center before:justify-center before:border before:border-border before:bg-background before:text-xs before:font-medium before:content-['404']",
						)}
					/>
				),
				size: LOGO_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("title", {
				id: "Título / Id",
				header: ({column}) => <DataTableColumnHeader title="Titulo / Id" column={column} />,
				cell: info => (
					<div className="flex flex-col text-left">
						<span className="truncate font-medium">{info.row.original.title}</span>
						<span className="truncate text-muted-foreground">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.6),
			}),
			columnHelper.accessor("published", {
				id: "Publicado",
				header: ({column}) => <DataTableColumnHeader title="Publicado" column={column} />,
				cell: info =>
					info.getValue() ? (
						<div className="flex flex-1 justify-start">
							<Badge variant={"success"}>Publica</Badge>
						</div>
					) : (
						<div className="flex flex-1 justify-start">
							<Badge variant={"destructive"}>Privada</Badge>
						</div>
					),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("slug", {
				id: "Slug",
				header: ({column}) => <DataTableColumnHeader title="Slug" column={column} />,
				cell: info => <div className="flex flex-1 justify-start">{info.getValue()}</div>,
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="flex w-full justify-center gap-2">
						<Button
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								setDeleteSerieID(info.row.original.id);
								setDeleteConfirmationModalOpen(true);
							}}
						>
							<Trash className="h-4 w-4" />
						</Button>
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/series/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: CONTROL_COL_WIDTH,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const handleVisibilityFilter = (value: string) => {
		const options = publishedOptions.find(el => el.name === value) ?? publishedOptions[0];
		setSelectedPublished(options);
		dispatch(seriesActions.getSeriesList({params: {page: page.pageIndex, page_size: page.pageSize, published: options.value}}));
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.name === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Lista de Series</h2>
				<Button className="gap-2" asChild>
					<Link to="/series/new">
						<ListPlus />
						Nueva Serie
					</Link>
				</Button>
			</div>
			<div className="flex gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.name}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.name}`}>
											{sOption.name}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleVisibilityFilter} value={selectedPublished.name}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{publishedOptions.map((pOption, idx) => (
									<SelectItem key={idx} value={`${pOption.name}`}>
										{pOption.name}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={series.results}
					pageCount={Math.ceil(series.totalResults / series.pageSize)}
					pagination={page}
					loading={series.loading}
					onPaginationChange={setPage}
					rowHeight={65}
					withDynamicPageSize
					showPagination={false}
				/>
			</div>
			<ActionConfirmModal
				open={deleteConfirmationModalOpen}
				onCancel={() => {
					setDeleteConfirmationModalOpen(false);
				}}
				onAccept={() => {
					auth.currentUser
						?.getIdToken()
						.then(token => {
							deleteSeries(token, {id: deleteSerieID})
								.then(() => {
									setDeleteConfirmationModalOpen(false);
									setDeleteSerieID("");
									if (!auth.currentUser) return;

									const params: Record<string, any> = {};

									if (searchValue) {
										params.searchvalue = searchValue;
										params.searchby = selectedSearchBy.value;
									}

									dispatch(seriesActions.getSeriesList({params: {page: page.pageIndex, ...params}}));
								})
								.catch(err => {
									console.log(err);
								});
						})
						.catch(() => {});
				}}
				title={"Eliminar Serie"}
				description={<span className="text-white">Al confirmar eliminaras la serie, este proceso no se puede revertir.</span>}
			/>
		</div>
	);
}

export default SeriesList;
