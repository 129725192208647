import Select, {CSSObjectWithLabel} from "react-select";
import {Button} from "../../../../components/primitives/Button";
import {ChevronRight, DownloadList, Lock, Refresh} from "../../../../components/primitives/icons";
import {Checkbox} from "../../../../components/primitives/Checkbox";
import {MarketingFiltersController} from "./controller";
import {Unlock} from "../../../../components/primitives/icons/Unlock";
import {MEMBERSHIP_TYPES} from "../../../../constants";
import {createColumnHelper} from "@tanstack/react-table";
import {MarketingListResult} from "../../../../data-access/marketing/marketing";
import {useEffect, useMemo, useRef, useState} from "react";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {DataTable} from "../../../../components/blocks/DataTable";
import UncheckVerifiedDialog from "./_subcomponents/UncheckVerifiedDialog";
import {Popover, PopoverContent, PopoverTrigger} from "../../../../components/primitives/Popover/popover";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {notificationsActions} from "../../../../_store/features/notify/notify-slice";
import toast from "react-hot-toast";
import ActionConfirmModal from "../../../../components/blocks/ActionConfirmModal";
import {selectStyles} from "../../../../../utils/SelectStyles";

const columnHelper = createColumnHelper<MarketingListResult>();

export function MarketingDashboard() {
	const {
		interactionList,
		dateFrameList,
		isDisabledSelectionFilters,
		validationItems,
		isLocked,
		open,
		options,
		isContentLoading,
		contentOptions,
		refresh,
		handleSelectInteraction,
		handleSelectSerie,
		handleSelectDateFrame,
		handleCheckPhone,
		handleCheckEmail,
		handleUnlockCheckBox,
		handleIsLocked,
		handleIsOpen,
		handleSelectMembership,
		handleSelectContent,
		usersListLoading,
		usersList,
		apply,
		isApplyDisabled,
		handleExcelDownload,
		sameInteractionCount,
		pagination,
		setPagination,
	} = MarketingFiltersController();

	const dispatch = useAppDispatch();
	const {success, loading, error} = useAppSelector(state => state.notifications);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const selectInputRefs = useRef<any[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState<string>("");

	const onClear = (indices: number[]) => {
		indices.forEach(index => {
			selectInputRefs.current[index].clearValue();
		});
	};

	useEffect(() => {
		if (sameInteractionCount <= 1) {
			onClear([1, 2, 3, 4]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		validationItems.series_like,
		validationItems.series_dislike,
		validationItems.membership_purchase,
		validationItems.content_like,
		validationItems.content_dislike,
		validationItems.content_views,
		validationItems.series_views,
		validationItems.trailer_views,
	]);

	useEffect(() => {
		if (!success) return;
		dispatch(notificationsActions.setSuccess(false));
		toast.success("Notificaciones enviadas correctamente.");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success]);

	useEffect(() => {
		if (!loading && error) {
			if (error) {
				toast.error("ERROR: " + error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 3);

		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("email", {
				id: "Correo",
				header: ({column}) => <DataTableColumnHeader title="Correo" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.email}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("phone", {
				id: "Teléfono",
				header: ({column}) => <DataTableColumnHeader title="Teléfono" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.phone}</span>
					</div>
				),
				size: colWidth,
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const onSendNotification = () => {
		if (usersList.results.length <= 0) return;
		// @ts-ignore
		const data = usersList.results.map(({userid}) => userid).join(",");
		if (notificationMessage.length < 3) {
			toast.error("El mensaje no es valido");
		} else {
			dispatch(notificationsActions.userNotifications({message: notificationMessage, userid: data}));
		}
		setNotificationMessage("");
		setIsModalOpen(false);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="flex flex-col">
				<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
					<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Marketing Dashboard</h2>
				</div>
				<div className="flex flex-row justify-between">
					<Select
						ref={ref => {
							selectInputRefs.current[0] = ref;
						}}
						className="basic-select mx-4 my-7 w-full text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={interactionList}
						classNamePrefix="select"
						onChange={handleSelectInteraction}
						placeholder="Interacciones"
					/>

					<Select
						ref={ref => {
							selectInputRefs.current[1] = ref;
						}}
						className="basic-select mx-4 my-7 w-full text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={options}
						classNamePrefix="select"
						onChange={handleSelectSerie}
						placeholder="Serie"
						isDisabled={isDisabledSelectionFilters.serie}
					/>

					<Select
						ref={ref => {
							selectInputRefs.current[2] = ref;
						}}
						className="basic-select mx-4 my-7 w-full text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={contentOptions}
						classNamePrefix="select"
						onChange={handleSelectContent}
						placeholder="Contenido"
						isDisabled={isDisabledSelectionFilters.content}
						isLoading={isContentLoading}
					/>

					<Select
						ref={ref => {
							selectInputRefs.current[3] = ref;
						}}
						className="basic-select mx-4 my-7 w-full text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={MEMBERSHIP_TYPES}
						classNamePrefix="select"
						onChange={handleSelectMembership}
						placeholder="Membresía"
						isDisabled={MEMBERSHIP_TYPES.length === 0}
					/>

					<Select
						ref={ref => {
							selectInputRefs.current[4] = ref;
						}}
						className="basic-select mx-4 my-7 w-full text-sm"
						styles={selectStyles}
						isSearchable={true}
						options={dateFrameList}
						classNamePrefix="select"
						onChange={handleSelectDateFrame}
						placeholder="Rango de Fechas"
						isDisabled={isDisabledSelectionFilters.date}
						isOptionDisabled={option => option.isdisabled ?? false}
					/>
				</div>
				<div className="flex flex-row justify-between">
					<div className="flex flex-row">
						<div className="mx-4 flex max-h-10 flex-row items-center space-x-3 space-y-0 rounded-md border p-3">
							<Checkbox checked={validationItems.phone} onCheckedChange={handleCheckPhone} disabled={isLocked} />
							<div className="space-y-1 leading-none">
								<div className="text-sm">Teléfono validado</div>
							</div>
						</div>

						<div className="mx-4 flex max-h-10 flex-row items-center space-x-3 space-y-0 rounded-md border p-3">
							<Checkbox checked={validationItems.email} onCheckedChange={handleCheckEmail} disabled={isLocked} />
							<div className="space-y-1 leading-none">
								<div className="text-sm">Correo validado</div>
							</div>
						</div>

						<Button className="mx-4 h-9" size={"sm"} variant={"outline"} onClick={handleUnlockCheckBox}>
							{isLocked ? <Lock className="h-5 w-5" /> : <Unlock />}
						</Button>

						{!isLocked && (
							<span className="self-center text-center text-sm font-semibold uppercase text-red-700">
								Opción desbloqueada no recomendada
							</span>
						)}
					</div>
					<div className="mr-4 flex flex-row gap-4">
						<Popover>
							<PopoverTrigger asChild>
								<Button className="h-9 bg-black text-white" size={"sm"} variant={"outline"}>
									Enviar notificaciones
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-fit p-0" align="end">
								<div className="rounded-lg bg-neutral-100 p-5">
									<div className="mb-2 border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700" role="alert">
										<p className="font-bold">¡Cuidado!</p>
										<p>Esta acción enviara una notificación a todos los usuarios filtrados.</p>
									</div>
									<div className="max-w-2xl">
										<div className="mb-3">
											<label htmlFor="description">Mensaje*</label>
											<textarea
												name="notification_message"
												rows={3}
												placeholder="Mensaje de Notificación"
												className={`mt-1 w-full rounded px-3 py-2 ring-1 ring-neutral-800 `}
												onChange={e => setNotificationMessage(e.target.value)}
											/>
										</div>
									</div>
									<div className="mt-2 flex w-full justify-end gap-4">
										<button
											className="col-start-2 flex items-center justify-center rounded bg-neutral-900 px-4 py-2 uppercase text-white transition-colors duration-300 hover:bg-neutral-700"
											disabled={usersList.results.length <= 0 || loading}
											onClick={() => setIsModalOpen(true)}
										>
											Enviar
											<ChevronRight />
										</button>
									</div>
								</div>
							</PopoverContent>
						</Popover>
						<Button className="h-9 w-32 bg-black text-white" size={"sm"} variant={"outline"} onClick={apply} disabled={isApplyDisabled}>
							Aplicar
						</Button>
						<Button
							className="h-9"
							size={"sm"}
							variant={"outline"}
							onClick={() => {
								refresh();
								onClear([0, 1, 2, 3, 4]);
							}}
						>
							<Refresh className="h-4 w-4" />
						</Button>
					</div>
				</div>
				<div className="flex items-center justify-between border-b border-b-border bg-background p-4"></div>

				<div className="mt-7 flex flex-row justify-between">
					<div className="mx-4 flex flex-row items-start">
						<span className="text-ellipsis text-center font-bold uppercase">Total de registros: </span>
						<span className="ml-2 text-ellipsis text-center font-bold uppercase">{usersList.totalResults}</span>
					</div>

					<Button variant="outline" size="sm" className="mx-4 h-9 whitespace-nowrap font-normal lg:flex" onClick={handleExcelDownload}>
						<DownloadList className="mr-2 h-4 w-4" />
						Exportar Excel
					</Button>
				</div>
			</div>

			<div className="flex h-full  grow-0 flex-col p-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={usersList.results}
					pageCount={Math.ceil((usersList?.totalResults ?? 0) / pagination.pageSize)}
					pagination={pagination}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={40}
					showPagination={false}
					loading={usersListLoading}
				/>
			</div>

			<UncheckVerifiedDialog
				open={open}
				onDismiss={handleIsOpen}
				onSuccess={() => {
					handleIsLocked();
					handleIsOpen();
				}}
			/>
			<ActionConfirmModal
				title="Enviar notificación"
				description={<span className="text-white">Al confirmar se enviara una notificación a los usuarios filtrados.</span>}
				open={isModalOpen}
				onAccept={() => onSendNotification()}
				onCancel={() => {
					setIsModalOpen(false);
					setNotificationMessage("");
				}}
			/>
		</div>
	);
}
