import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	Advertisement,
	deleteAdvertisementData,
	EditAdvertisementBodyType,
	editAdvertisementData,
	EditAdvertisementPrintBodyType,
	editAdvertisementPrintData,
	getAdvertisementsList,
	GetAdvertisementsListParams,
	InsertAdvertisementBodyType,
	insertAdvertisementData,
} from "../../../data-access/advertisers";
import {auth} from "../../../firebase";

export interface advertisementsInterface {
	loading: boolean;
	results: Advertisement[];
	totalResults: number;
	current: number;
	pageSize: number;
	success: boolean;
}

const initialState: advertisementsInterface = {
	loading: false,
	results: [],
	totalResults: 0,
	current: 0,
	pageSize: 0,
	success: false,
};

const getAdvertisements = createAsyncThunk("advertisements/list", async (params: GetAdvertisementsListParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getAdvertisementsList(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const insertAdvertisement = createAsyncThunk("advertisements/insert", async (data: InsertAdvertisementBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await insertAdvertisementData(token, data);

		if (!res.data) return thunkAPI.rejectWithValue("Problem inserting advertisement, try again.");

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("No se pudo insertar el anuncio, intenta nuevamente.");
	}
});

const deleteAdvertisement = createAsyncThunk("advertisements/delete", async (id: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		await deleteAdvertisementData(token, {publicityid: id});

		return id;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editAdvertisement = createAsyncThunk("advertisements/edit", async (data: EditAdvertisementBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editAdvertisementData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const editAdvertisementPrint = createAsyncThunk("advertisements/edit-print", async (data: EditAdvertisementPrintBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await editAdvertisementPrintData(token, data);

		if (!res.data) {
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}

		return {
			item: res.data,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const advertisementsSlice = createSlice({
	name: "advertisements",
	initialState,
	reducers: {
		setSuccess(state, action: PayloadAction<boolean>) {
			state.success = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAdvertisements.pending, state => {
				state.loading = true;
			})
			.addCase(getAdvertisements.fulfilled, (state, action) => {
				state.loading = false;
				state.results = action.payload?.results ?? [];
				state.totalResults = action.payload?.totalResults!;
				state.pageSize = action.payload?.pageSize!;
			})
			.addCase(getAdvertisements.rejected, (state, action) => {});
		builder
			.addCase(editAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(editAdvertisement.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editAdvertisement.rejected, (state, action) => {});
		builder
			.addCase(editAdvertisementPrint.pending, state => {
				state.loading = true;
			})
			.addCase(editAdvertisementPrint.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.results = state.results.map(el => {
					if (el.id === action.payload?.item.id!) {
						return action.payload?.item!;
					}

					return el;
				});
			})
			.addCase(editAdvertisementPrint.rejected, (state, action) => {});
		builder
			.addCase(insertAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(insertAdvertisement.fulfilled, (state, action) => {
				state.loading = false;
				state.results = [...state.results, action.payload?.item!];
				state.success = true;
			})
			.addCase(insertAdvertisement.rejected, (state, action) => {});
		builder
			.addCase(deleteAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(deleteAdvertisement.fulfilled, (state, action) => {
				state.loading = false;
				state.results = state.results.filter(el => el.id !== action.payload);
			})
			.addCase(deleteAdvertisement.rejected, (state, action) => {});
	},
});

export const advertisementsActions = {
	...advertisementsSlice.actions,
	getAdvertisements,
	insertAdvertisement,
	deleteAdvertisement,
	editAdvertisement,
	editAdvertisementPrint,
};

export default advertisementsSlice;
