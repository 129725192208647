import {Listbox, Portal, Transition} from "@headlessui/react";
import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FileUpload} from "../../../../components/primitives/FileUpload";
import {Close} from "../../../../components/primitives/icons";
import {CheckCircle} from "../../../../components/primitives/icons/CheckCircle";
import {Spinner} from "../../../../components/primitives/icons/Spinner";
import {Modal} from "../../../../components/primitives/Modal";
import {MEMBERSHIP_TYPES, CONTENT_TYPES, PREMIERE_TYPES} from "../../../../constants";
import {Content, InsertContentType, uploadContentImage} from "../../../../data-access/series/content";
import {auth} from "../../../../firebase";
import {serieContentsActions} from "../../../../_store/features/serie-content/serie-content-slice";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import MuxVideoCombobox from "./MuxVideoCombobox";
import {Button} from "../../../../components/primitives/Button";
import {FOLDERS} from "../../../../data-access/multimedia/gallery";
import MultimediaImagePickerModal from "../../../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../../../_store/features/gallery/gallery-slice";
import {DayPicker} from "../../../../components/blocks/DatePicker";
import HourPicker from "../../../../components/primitives/HourPicker/HourPicker";
import {convertToStringHour} from "../../../../../utils/convertToStringHour";

interface NewContentModalProps {
	onDismiss: () => void;
	open: boolean;
}

const initialState: InsertContentType = {
	seriesid: "",
	title: "",
	order: 0,
	estreno: false,
	new: false,
	published: false,
	slugs: "",
	membership_type: 0,
	content_type: 0,
	short_description: "",
	long_description: "",
	video_id: "",
	duration: "",
	lowres_image: "",
	square_image: "",
	rectangular_image: "",
	release_date: null,
	whitelist_release_date: null,
	premiere_type: 0,
	display_release_date: false,
};

type ImageFieldsTypes = keyof Pick<InsertContentType, "lowres_image" | "square_image" | "rectangular_image">;

const FOLDERS_NAMES: {name: string; value: (typeof FOLDERS)[number]}[] = [{name: "Fotos de Contenidos", value: "content"}];

export function NewContentModal({open, onDismiss}: NewContentModalProps) {
	const {id: serieId} = useParams();
	const dispatch = useAppDispatch();
	const serieContent = useAppSelector(state => state.serieContent);
	const [data, setData] = useState<Content | InsertContentType>({...initialState});
	const [selectedMembership, setSelectedMembership] = useState(MEMBERSHIP_TYPES[0]);
	const [selectedContentType, setSelectedContentType] = useState(CONTENT_TYPES[0]);
	const [selectedPremiereType, setSelectedPremiereType] = useState(PREMIERE_TYPES[0]);
	const [fieldLoading, setFieldLoading] = useState<string[]>([]);
	const [hour, setHour] = useState<string>("00:00:00.000Z");
	const [whiteListHour, setWhiteListHour] = useState<string>("00:00:00.000Z");
	const [hourInMiami, setHourInMiami] = useState<string>("");
	const [whiteListHourInMiami, setWhiteListHourInMiami] = useState<string>("");
	const [error, setError] = useState("");
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("lowres_image");

	useEffect(() => {
		if (serieContent.isEditingContent) {
			setData({...initialState, ...serieContent.editingContent!});
			setSelectedContentType(CONTENT_TYPES.find(el => el.value === serieContent.editingContent?.content_type)!);
			setSelectedMembership(MEMBERSHIP_TYPES.find(el => el.value === serieContent.editingContent?.membership_type)!);
			setSelectedPremiereType(PREMIERE_TYPES.find(el => el.value === serieContent.editingContent?.premiere_type)!);
			return;
		}

		setData({...initialState});
	}, [serieContent.editingContent, serieContent.isEditingContent, serieContent.results.length]);

	const handleUploadImage = (file: any, type: string) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);
		setData(prev => ({...prev, [type]: ""}));

		body.append("file", file);
		body.append("type", type);
		body.append("contentid", crypto.randomUUID());

		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadContentImage(token, body)
					.then(res => {
						setData(prev => ({...prev, [type]: res.data.result}));
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (data.release_date) {
			const date = new Date(`${data.release_date.split("T")[0]}T${hour}Z`);
			const dateMiami = date.toLocaleString("en-US", {timeZone: "America/New_York"});
			setHourInMiami(dateMiami);
		}

		if (data.whitelist_release_date) {
			const date = new Date(`${data.whitelist_release_date.split("T")[0]}T${whiteListHour}Z`);
			const dateMiami = date.toLocaleString("en-US", {timeZone: "America/New_York"});
			setWhiteListHourInMiami(dateMiami);
		}
	}, [data.release_date, data.whitelist_release_date, hour, whiteListHour]);

	const handleSubmit = () => {
		if (!data.title) {
			return setError("El titulo es requerido");
		}

		if (!data.slugs) {
			return setError("El titulo es requerido");
		}

		if (data.release_date) {
			const date = new Date(`${data.release_date.split("T")[0]}T${hour}Z`);
			data.release_date = date.toISOString();
		}

		if (data.whitelist_release_date) {
			const date = new Date(`${data.whitelist_release_date.split("T")[0]}T${whiteListHour}Z`);
			data.whitelist_release_date = date.toISOString();
		}

		const slugs = data.slugs.split(",");
		const slugsSet = new Set(slugs);
		if (slugsSet.size !== slugs.length) {
			return setError("No se puede repetir slugs");
		}

		if (!data) {
			return;
		}

		data.estreno = data.new;

		if (serieContent.isEditingContent) {
			const contentData = {
				...data,
				membership_type: selectedMembership.value,
				content_type: selectedContentType.value,
				seriesid: serieId!,
				premiere_type: selectedPremiereType.value,
			};
			if (serieContent.editingContent?.content_type !== selectedContentType.value)
				contentData.order = serieContent.results.filter(x => x.content_type === selectedContentType.value).length + 1;
			dispatch(serieContentsActions.editSerieContent(contentData as InsertContentType));
			return handleDismiss();
		}

		const contentData: InsertContentType = {
			...data,
			order: serieContent.results.filter(x => x.content_type === selectedContentType.value).length + 1,
			membership_type: selectedMembership.value,
			content_type: selectedContentType.value,
			seriesid: serieId!,
			duration: parseInt(data.duration.toString()).toFixed(0),
			premiere_type: selectedPremiereType.value,
		};
		dispatch(serieContentsActions.insertSerieContent(contentData));
		handleDismiss();
	};

	const handleDismiss = () => {
		if (serieContent.isEditingContent) {
			dispatch(serieContentsActions.setEditing({data: null, editing: false}));
		}

		setData({...initialState});
		onDismiss?.();
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	const handleSelectHour = (date: string) => {
		setHour(date);
	};

	const handleSelectWhiteListHour = (date: string) => {
		setWhiteListHour(date);
	};

	return (
		<Modal title="Nuevo Contenido" open={open} onDismiss={handleDismiss} big>
			{error && (
				<Portal>
					<div className="items fixed right-0 top-0 z-[700] mx-8 mt-4 flex items-center justify-between rounded bg-red-700 px-6 py-3 text-white shadow">
						{error}
						<button className="ml-2" onClick={() => setError("")} title="Cerrar">
							<Close />
						</button>
					</div>
				</Portal>
			)}
			<div className="container px-0.5 pr-4 md:w-[60vw]">
				<div className="mb-3 flex w-full">
					<div className="mr-3 w-full">
						<label className="text-white" htmlFor="exampleFormControlInput1">
							Título *
						</label>
						<input
							className="form-control w-full rounded bg-black px-3 py-2 text-white"
							type="text"
							name="title"
							placeholder="Título del capítulo"
							value={data.title}
							onChange={e => {
								setData(prev => ({...prev, [e.target.name]: e.target.value}));
							}}
						/>
					</div>
				</div>
				<div className="mb-3 flex w-full">
					<div className="mr-3 w-full">
						<label className="text-white" htmlFor="exampleFormControlInput1">
							Slugs *
						</label>
						<input
							className="form-control w-full rounded bg-black px-3 py-2 text-white"
							type="text"
							name="slugs"
							placeholder="Slugs del capítulo"
							value={data.slugs}
							onChange={e => {
								setError("");
								setData(prev => ({...prev, [e.target.name]: e.target.value}));
							}}
						/>
					</div>
				</div>
				<div className="form-check">
					<input
						id="new"
						name="new"
						type="checkbox"
						className="form-check-input"
						checked={data.new}
						onChange={e => {
							setData(prev => ({...prev, [e.target.name]: e.target.checked}));
						}}
						title="Contenido nuevo"
					/>
					<label className="ml-1 text-white" htmlFor="estreno">
						Es estreno
					</label>
				</div>
				<div className="form-check">
					<input
						id="published-content"
						name="published"
						type="checkbox"
						className="form-check-input"
						checked={data.published}
						onChange={e => {
							setData(prev => ({...prev, [e.target.name]: e.target.checked}));
						}}
					/>
					<label className="ml-1 text-white" htmlFor="published-content">
						Publicado
					</label>
				</div>

				<div className="my-3 flex gap-3">
					<div className="flex w-full flex-col">
						<label className="text-white" htmlFor="exampleFormControlInput1">
							Tipo de Contenido
						</label>
						<Listbox value={selectedContentType} onChange={setSelectedContentType}>
							<div className="relative">
								<Listbox.Button className="relative w-full cursor-default rounded-lg bg-black py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:border-slate-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-slate-300 sm:text-sm">
									{selectedContentType.label}
								</Listbox.Button>
								<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
									<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-black py-1 text-base text-white shadow-lg ring-1 ring-neutral-300 focus:outline-none sm:text-sm">
										{CONTENT_TYPES.map((type, typeIndex) => (
											<Listbox.Option
												key={typeIndex}
												className={({active}) =>
													`relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-neutral-800" : "text-white"}`
												}
												value={type}
											>
												{({selected}) => (
													<>
														<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
															{type.label}
														</span>
														{selected ? (
															<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
																<CheckCircle className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
					<div className="flex w-full flex-col">
						<label className="text-white" htmlFor="exampleFormControlInput1">
							Tipo de membresía
						</label>
						<Listbox value={selectedMembership} onChange={setSelectedMembership}>
							<div className="relative">
								<Listbox.Button className="relative w-full cursor-default rounded-lg bg-black py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:border-slate-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-slate-300 sm:text-sm">
									{selectedMembership.label}
								</Listbox.Button>
								<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
									<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-black py-1 text-base text-white shadow-lg ring-1 ring-neutral-300 focus:outline-none sm:text-sm">
										{MEMBERSHIP_TYPES.map((type, typeIndex) => (
											<Listbox.Option
												key={typeIndex}
												className={({active}) =>
													`relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-neutral-800" : "text-white"}`
												}
												value={type}
											>
												{({selected}) => (
													<>
														<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
															{type.label}
														</span>
														{selected ? (
															<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
																<CheckCircle className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
					<div className="flex w-full flex-col">
						<label className="text-white" htmlFor="exampleFormControlInput1">
							Tipo de Estreno
						</label>
						<Listbox value={selectedPremiereType} onChange={setSelectedPremiereType}>
							<div className="relative">
								<Listbox.Button className="relative w-full cursor-default rounded-lg bg-black py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:border-slate-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-slate-300 sm:text-sm">
									{selectedPremiereType.label}
								</Listbox.Button>
								<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
									<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-black py-1 text-base text-white shadow-lg ring-1 ring-neutral-300 focus:outline-none sm:text-sm">
										{PREMIERE_TYPES.map((type, typeIndex) => (
											<Listbox.Option
												key={typeIndex}
												className={({active}) =>
													`relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-neutral-800" : "text-white"}`
												}
												value={type}
											>
												{({selected}) => (
													<>
														<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
															{type.label}
														</span>
														{selected ? (
															<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
																<CheckCircle className="h-5 w-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
				</div>
				<div className="mb-3 flex flex-col">
					<label className="text-white" htmlFor="exampleFormControlInput1">
						Descripción corta
					</label>
					<textarea
						className="rounded bg-black p-2 text-white"
						id="shortDescription"
						rows={3}
						placeholder="Descripción corta"
						name="short_description"
						value={data.short_description}
						onChange={e => {
							setData(prev => ({...prev, [e.target.name]: e.target.value}));
						}}
					></textarea>
				</div>
				<div className="mb-3 flex flex-col">
					<label className="text-white" htmlFor="exampleFormControlInput1">
						Descripción larga
					</label>
					<textarea
						className="rounded bg-black p-2 text-white"
						id="longDescription"
						rows={3}
						placeholder="Descripción larga"
						name="long_description"
						value={data.long_description}
						onChange={e => {
							setData(prev => ({...prev, [e.target.name]: e.target.value}));
						}}
					></textarea>
				</div>
				<div className="mb-3 flex">
					<div className="mr-2 flex w-full flex-col">
						<div>
							<label className="text-white" htmlFor="video_content_id">
								Video ID
							</label>
							{data.premiere_type !== 1 ? (
								<MuxVideoCombobox
									id="video_content_id"
									name="video_id"
									defaultValue={data.video_id}
									onChangeSelected={selected => {
										const videoDuration = `${selected.duration}`;
										setData(prev => ({
											...prev,
											video_id: selected.playbackid,
											duration: videoDuration,
										}));
									}}
								/>
							) : (
								<input
									className="form-control w-full rounded bg-black px-3 py-2 text-white"
									type="text"
									name="video_id"
									placeholder="Id del video"
									value={data.video_id}
									onChange={e => {
										setData(prev => ({...prev, [e.target.name]: e.target.value}));
									}}
								/>
							)}
						</div>
						<div>
							<label className="text-white" htmlFor="content_duration">
								Duración
							</label>
							<input
								id="content_duration"
								className="form-control w-full rounded bg-black px-3 py-2 text-white"
								type="number"
								min="0"
								placeholder="Duración en Segundos"
								name="duration"
								value={data.duration}
								onChange={e => {
									setData(prev => ({...prev, [e.target.name]: e.target.value}));
								}}
							/>
						</div>

						<div className="form-check">
							<input
								id="display_release_date"
								name="display_release_date"
								type="checkbox"
								className="form-check-input"
								checked={data.display_release_date}
								onChange={e => {
									setData(prev => ({...prev, [e.target.name]: e.target.checked}));
								}}
							/>
							<label className="ml-1 text-white" htmlFor="display_release_date">
								Mostrar fecha de release.
							</label>
						</div>

						<div className="mt-2 flex w-full flex-row justify-evenly">
							<p className="text-sm font-medium leading-6 text-white">Fecha de release</p>
							<div className="mt-1 flex w-full flex-row justify-evenly self-start">
								<DayPicker
									selectedDay={data?.release_date ? new Date(data?.release_date) : undefined}
									onDayChange={date => {
										const dateFormatted = date.toISOString();
										setData(prev => ({
											...prev,
											release_date: dateFormatted,
										}));
									}}
									className="min-w-[150px] justify-start self-start"
									color="white"
								/>
								<p className="mx-2 self-center text-xl">-</p>
								<HourPicker
									hourSelected={convertToStringHour(data?.release_date)}
									onTimeChange={date => handleSelectHour(date)}
									className="text-white"
								/>
								{hourInMiami && (
									<p className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">{hourInMiami} - Miami</p>
								)}
							</div>
						</div>
						<div className="mt-2 flex w-full flex-row justify-evenly">
							<p className="text-sm font-medium leading-6 text-white">Fecha de Whitelist</p>
							<div className="mt-1 flex w-full flex-row justify-evenly self-start">
								<DayPicker
									selectedDay={data?.whitelist_release_date ? new Date(data?.whitelist_release_date) : undefined}
									onDayChange={date => {
										const dateFormatted = date.toISOString();
										setData(prev => ({
											...prev,
											whitelist_release_date: dateFormatted,
										}));
									}}
									className="min-w-[150px] justify-start self-start"
									color="white"
								/>
								<p className="mx-2 self-center text-xl">-</p>
								<HourPicker
									hourSelected={convertToStringHour(data?.whitelist_release_date)}
									onTimeChange={date => handleSelectWhiteListHour(date)}
									className="text-white"
								/>
								{whiteListHourInMiami && (
									<p className="mx-2 flex w-auto self-start whitespace-nowrap text-xs text-gray-400">
										{whiteListHourInMiami} - Miami
									</p>
								)}
							</div>
						</div>
					</div>
					<video className="my-2 h-44 w-80 rounded bg-neutral-300" />
				</div>
				<div className="mb-3 text-white">
					<div className="mb-2">Image Low Resolution</div>
					<div className="flex items-end gap-3">
						{!data.lowres_image ? (
							<div className="flex h-40 w-40 items-center justify-center bg-neutral-300">
								{fieldLoading.includes("lowres_image") && <Spinner />}
							</div>
						) : (
							<img src={data.lowres_image} className="h-40 w-40 bg-neutral-300 object-contain" alt="Thumbnail" />
						)}
						<div className="flex w-full flex-col">
							<FileUpload
								name="lowres_image"
								onChange={e => {
									const [file] = e.target.files as any;
									handleUploadImage(file, e.target.name);
								}}
								accept="image/png, image/jpeg"
							/>
							<Button
								variant="outline"
								type="button"
								className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-white transition-all duration-300 hover:bg-white hover:text-black"
								onClick={() => {
									handleSelectImage("lowres_image");
								}}
							>
								Seleccionar de multimedia
							</Button>
						</div>
					</div>
				</div>
				<div className="mb-3 text-white">
					<div className="mb-2">Image Square</div>
					<div className="flex items-end gap-3">
						{!data.square_image ? (
							<div className="flex h-40 w-40 items-center justify-center bg-neutral-300">
								{fieldLoading.includes("square_image") && <Spinner />}
							</div>
						) : (
							<img src={data.square_image} className="h-40 w-40 bg-neutral-300 object-contain" alt="Thumbnail" />
						)}
						<div className="flex w-full flex-col">
							<FileUpload
								name="square_image"
								onChange={e => {
									const [file] = e.target.files as any;
									handleUploadImage(file, e.target.name);
								}}
								accept="image/png, image/jpeg"
							/>
							<Button
								variant="outline"
								type="button"
								className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-white transition-all duration-300 hover:bg-white hover:text-black"
								onClick={() => {
									handleSelectImage("square_image");
								}}
							>
								Seleccionar de multimedia
							</Button>
						</div>
					</div>
				</div>
				<div className="mb-3 text-white">
					<div className="mb-2">Image Rectangular</div>
					<div className="flex items-end gap-3">
						{!data.rectangular_image ? (
							<div className="flex h-40 w-40 items-center justify-center bg-neutral-300">
								{fieldLoading.includes("rectangular_image") && <Spinner />}
							</div>
						) : (
							<img src={data.rectangular_image} className="h-40 w-40 bg-neutral-300 object-contain" alt="Thumbnail" />
						)}
						<div className="flex w-full flex-col">
							<FileUpload
								name="rectangular_image"
								onChange={e => {
									const [file] = e.target.files as any;
									handleUploadImage(file, e.target.name);
								}}
								accept="image/png, image/jpeg"
							/>
							<Button
								variant="outline"
								type="button"
								className="py-3tracking-wider mt-2 w-fit items-center justify-start rounded-xl border-2 bg-opacity-10 px-4 text-white transition-all duration-300 hover:bg-white hover:text-black"
								onClick={() => {
									handleSelectImage("rectangular_image");
								}}
							>
								Seleccionar de multimedia
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="mr-4 mt-4 flex justify-end gap-4">
				<button
					className="rounded bg-neutral-50 px-4 py-2 uppercase text-black transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
					type="button"
					onClick={handleSubmit}
				>
					Completar
				</button>
				<button
					className="rounded border-2 bg-white bg-opacity-10 px-4 py-2 uppercase text-white transition-colors duration-300 hover:bg-neutral-300 active:bg-neutral-100"
					type="button"
					onClick={onDismiss}
				>
					Cancelar
				</button>
			</div>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={1}
				folderNames={FOLDERS_NAMES}
				onSuccess={url => {
					setData({...data, [type]: url});
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</Modal>
	);
}
