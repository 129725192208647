import {SeriePartnershipType} from "../../../../../data-access/role/configuration/partner";

interface CostEarnGraphProps {
	data?: SeriePartnershipType;
	loading?: boolean;
}

export default function CostEarnGraph({data, loading}: CostEarnGraphProps) {
	const total_profit = data?.series?.reduce((acc, item) => acc + (item?.series_profit || 0), 0) ?? 0;
	const total_production_cost = data?.series?.reduce((acc, item) => acc + (item?.production_cost || 0), 0) ?? 0;
	const total_personal_profit = data?.series?.reduce((acc, item) => acc + (item?.personal_profit || 0), 0) ?? 0;
	const total_platform_profit = total_profit - total_personal_profit - total_production_cost;

	// Percentages
	const partner_percentage_profit = (total_personal_profit * 100) / (total_profit || 1);
	const platform_percentage_profit = total_platform_profit > 0 ? (total_platform_profit * 100) / (total_profit || 1) : 0;
	const production_percentage_cost = (total_production_cost * 100) / total_profit;

	const MiniCardsSkeleton = () => <div className="mt-2 h-5 w-28 animate-pulse rounded-md bg-slate-300"></div>;
	if (!loading && !data) return <></>;
	return (
		<div className="col-span-7 rounded-lg border bg-card p-6 text-card-foreground shadow-sm">
			<div className="flex flex-col space-y-1.5">
				<h3 className="mb-2 text-lg font-semibold leading-none tracking-tight">Costos/Ganancias</h3>
				<p className="text-md my-1 leading-none tracking-tight">Partner: {!loading ? partner_percentage_profit.toPrecision(3) : "..."}%</p>
				<p className="text-md my-1 leading-none tracking-tight">
					Plataforma: {!loading ? platform_percentage_profit.toPrecision(3) : "..."}%
				</p>
			</div>
			<div className="relative flex flex-col py-6">
				<div className="flex w-full flex-row justify-between">
					<div className="flex items-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="mt-1 h-3 w-8 rounded-sm bg-lime-600" />
							{loading ? (
								<MiniCardsSkeleton />
							) : (
								<p className="text-center text-sm font-medium tracking-tight">{`Costo de producción $${(
									total_production_cost * 0.01
								).toFixed(2)}`}</p>
							)}
						</div>
					</div>
					<div className="flex items-center justify-center">
						<div className="flex h-14 w-40 flex-col items-center justify-center rounded-lg border px-2">
							<div className="h-3 w-8 rounded-sm bg-[#005096]" />
							{loading ? (
								<MiniCardsSkeleton />
							) : (
								<p className="text-center text-sm font-medium tracking-tight">{`Ganancia del Partner $${(
									total_personal_profit * 0.01
								).toFixed(2)}`}</p>
							)}
						</div>
					</div>
					<div className="flex items-center justify-center">
						<div className="flex h-14 w-44 flex-col items-center justify-center rounded-lg border px-2">
							<div className={`h-3 w-8 rounded-sm ${Number(total_platform_profit) >= 0 ? "bg-[#4385BE]" : "bg-red-500"}`} />
							{loading ? (
								<MiniCardsSkeleton />
							) : (
								<p className="text-center text-sm font-medium tracking-tight">{`Ganancia de la Plataforma $${
									total_platform_profit > 0 ? (total_platform_profit * 0.01).toFixed(2) : 0
								}`}</p>
							)}
						</div>
					</div>
				</div>
			</div>
			{!data || loading ? (
				<div className="flex flex-row pt-0">
					<div className="min-w-1 h-14 w-full animate-pulse rounded-lg rounded-ss-lg bg-slate-300 p-5" />
				</div>
			) : (
				<>
					<div className="flex w-full flex-row">
						<div
							className={`h-14 min-w-[40px] rounded-es-lg rounded-ss-lg bg-lime-600 p-5 transition-all duration-300`}
							style={{width: `${platform_percentage_profit >= 0 ? production_percentage_cost : 100}%`}}
						/>
						<div
							className={`h-14 min-w-[${partner_percentage_profit > 0 ? "40px" : 0}] bg-[#005096] transition-all duration-300`}
							style={{width: `${partner_percentage_profit}%`}}
						/>
						<div
							className={`h-14 min-w-[40px] rounded-ee-lg rounded-se-lg ${
								Number(total_platform_profit) >= 0 ? "bg-[#4385BE]" : "bg-red-500"
							} w-[${platform_percentage_profit > 0 ? 100 : platform_percentage_profit * -1}%] p-5 transition-all duration-300`}
						/>
					</div>
				</>
			)}
		</div>
	);
}
