import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

interface AdminNotifyViaEmailBody {
	recipient: string;
	subject: string;
	notification: string;
	send_by_console?: boolean;
}

export async function adminNotifyViaEmail(token: string, data: AdminNotifyViaEmailBody) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/admin_notify_user", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export interface Notification {
	message: string;
}

/*GLOBAL NOTIFICATION*/
export interface InsertGlobalNotificationBody {
	message: string;
}
interface InsertGlobalNotificationResponse {
	message: string;
}
export async function adminGlobalNotificationData(
	token: string,
	data: InsertGlobalNotificationBody,
): Promise<AxiosResponse<InsertGlobalNotificationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_global_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*USER NOTIFICATION*/
export interface InsertUserNotificationBody {
	message: string;
	userid: string;
}
interface InsertUserNotificationResponse {
	message: string;
}
export async function adminUserNotificationData(
	token: string,
	data: InsertUserNotificationBody,
): Promise<AxiosResponse<InsertUserNotificationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_user_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*LIVE CHAPTER NOTIFICATION*/
export interface LiveChapterNotificationBody {
	seriesid: string;
	contentid: string;
	title: string;
}
interface LiveChapterNotificationResponse {
	message: string;
}
export async function sendLiveChapterNotification(
	token: string,
	data: LiveChapterNotificationBody,
): Promise<AxiosResponse<LiveChapterNotificationResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.post("/send_live_chapter_notification", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}
