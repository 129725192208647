import {Film, Archive, Queue, FaceSmile, Photo, User, ChartBar, Comment, VideoCamera, Contact} from "../../../../primitives/icons";
import Shorts from "../../../../primitives/icons/Shorts";
import {Versions} from "../../../../primitives/icons/Versions";
import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function AdminNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavGroup title="Series" route="/series" icon={<Film />}>
				<NavLink to="/series/list" label="Listar Series" />
				<NavLink to="/series/new" label="Nueva Serie" />
			</NavGroup>
			<NavGroup title="Reparto" route="/crew" icon={<VideoCamera />}>
				<NavLink to="/crew/cast" label="Actores" />
				<NavLink to="/crew/staff" label="Staff" />
				<NavLink to="/crew/professions" label="Profesiones" />
			</NavGroup>
			<NavGroup title="Productos" route="/products" icon={<Archive />}>
				<NavLink to="/products/coin-list" label="Listar Productos Coins" />
				<NavLink to="/products/serie-list" label="Listar Productos Series" />
			</NavGroup>
			<NavGroup title="Anuncios" route="/advertisements" icon={<Queue />}>
				<NavLink to="/advertisements/list" label="Generales" />
				<NavLink to="/advertisements/sponsor-list" label="Sponsor" />
				<NavLink to="/advertisements/new" label="Nuevo Anuncio" />
			</NavGroup>
			<NavGroup title="Sticker" route="/stickers" icon={<FaceSmile />}>
				<NavLink to="/stickers/series-stickers-list" label="Stickers de Series" />
				<NavLink to="/stickers/global-stickers-list" label="Stickers Globales" />
				<NavLink to="/stickers/new" label="Nuevo Sticker" />
			</NavGroup>
			<NavGroup title="Multimedia" route="/multimedia" icon={<Photo />}>
				<NavLink to="/multimedia/list" label="Galería de Imágenes" />
				<NavLink to="/multimedia/video-list" label="Videos Contenido" />
				<NavLink to="/multimedia/video-publicity-list" label="Videos Publicidad" />
				<NavLink to="/multimedia/video-short-list" label="Videos Shorts" />
				<NavLink to="/multimedia/lives-streams/list" label="Listar Lives" />
			</NavGroup>
			<NavGroup title="Shorts" route="/short_videos" icon={<Shorts />}>
				<NavLink to="/short_videos/list" label="Listar Shorts" />
				<NavLink to="/short_videos/new" label="Nuevo Short" />
			</NavGroup>
			<NavGroup title="Comentarios" route="/comments" icon={<Comment />}>
				<NavLink to="/comments/list" label="Listar Comentarios" />
				<NavLink to="/comments/reported" label="Reportados" />
				<NavLink to="/comments/deleted" label="Eliminados" />
			</NavGroup>
			<NavLink to="/customer-service/contact" label="Contacto" icon={<Contact />} className="mx-2" />
			<NavGroup title="Usuarios" route="/users" icon={<User />}>
				<NavLink to="/users/list" label="Listar Usuarios" />
				<NavLink to="/users/admins" label="Administrativos" />
				<NavLink to="/users/new" label="Nuevo Usuario" />
				<NavLink to="/users/global-notifications" label="Notificaciones Globales" />
			</NavGroup>
			<NavGroup title="Estadísticas" route="/statistics" icon={<ChartBar />}>
				<NavLink to="/statistics/series" label="Series" />
				<NavLink to="/statistics/subscriptions" label="Suscripciones" />
			</NavGroup>
			<NavGroup title="Versiones" route="/versions" icon={<Versions />}>
				<NavLink to="/versions/list" label="Listar Versiones" />
			</NavGroup>
		</div>
	);
}
