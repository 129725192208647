import {useEffect, useState, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {Avatar, AvatarFallback, AvatarImage} from "../../../../components/primitives/Avatar";
import {Refresh} from "../../../../components/primitives/icons/Refresh";
import {BarsPlus} from "../../../../components/primitives/icons";
import {Link} from "react-router-dom";
import {Button} from "../../../../components/primitives/Button";
import {Input} from "../../../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../../components/primitives/Select";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {seriesActions} from "../../../../_store/features/series/series-slice";
import {getPartnerConfig} from "../../../../_store/features/user-db/user-db-actions";
import {GetPartnerConfigResponse, SeriePartnershipType} from "../../../../data-access/role/configuration/partner";

const searchOptions: {display: string; searchby: "partner_name" | "serie_name"}[] = [
	{display: "Nombre", searchby: "partner_name"},
	{display: "Serie", searchby: "serie_name"},
];

interface seriesOptions {
	display: string;
	value: string | undefined;
}

const seriesInitialState = [{display: "Todos", value: undefined}];

interface orderByType {
	display: string;
	value?: "profits" | "name";
}

const orderBy: orderByType[] = [
	{display: "Todos", value: undefined},
	{display: "Mayor Ganancia", value: "profits"},
	{display: "Alfabéticamente", value: "name"},
];

const columnHelper = createColumnHelper<SeriePartnershipType>();

function removeDuplicates(arr: seriesOptions[]) {
	const baseObject: any = {};
	return arr.filter(item => {
		if (baseObject[String(item.value)]) return false;
		baseObject[String(item.value)] = true;
		return baseObject[String(item.value)];
	});
}

function PartnerList() {
	const dispatch = useAppDispatch();
	const series = useAppSelector(state => state.series.results);
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState<{display: string; searchby: "partner_name" | "serie_name"} | undefined>(
		searchOptions[0],
	);
	const [seriesList, setSeriesList] = useState<seriesOptions[]>(seriesInitialState);
	const [selectedSerie, setSelectedSerie] = useState<seriesOptions>(seriesInitialState[0]);
	const [selectedOrder, setSelectedOrder] = useState(orderBy[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [filteredPartners, setFilteredPartners] = useState<SeriePartnershipType[]>([]);
	const [responseData, setResponseData] = useState<GetPartnerConfigResponse | undefined>();
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState<string>("");

	const handleSearch = () => {
		dispatch(
			getPartnerConfig({
				searchby: searchValue ? selectedSearchBy?.searchby : undefined,
				searchvalue: searchValue || undefined,
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				order: "desc",
				orderby: selectedOrder.value,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "fulfilled" && res.payload) {
					const data = res.payload as GetPartnerConfigResponse;
					setResponseData(data);
					setFilteredPartners(data.results);
				}
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		setIsLoading(true);
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	useEffect(() => {
		setSeriesList(prev => {
			const data = prev.concat(
				series.map(serie => ({
					display: serie.title,
					value: serie.id,
				})),
			);
			return removeDuplicates(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [series.length]);

	useEffect(() => {
		dispatch(seriesActions.getSeriesList({}));
	}, [dispatch]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}

		return [
			columnHelper.accessor("photo_url", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => (
					<Avatar>
						<AvatarImage src={info.row.original?.photo_url} alt="User profile" />
						<AvatarFallback>{info.row.original?.name?.slice(0, 2)}</AvatarFallback>
					</Avatar>
				),
				size: AVATAR_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
						<span className="overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.userid}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("series", {
				id: "Serie / Contenido",
				header: "Serie / Contenido",
				cell: info => (
					<div className="flex w-full flex-col justify-start">
						{info.row.original.series?.slice(0, 3).map(serie => (
							<span className="overflow-hidden text-ellipsis text-left">{serie?.series_name}</span>
						))}
					</div>
				),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("series", {
				id: "Ganancia Total",
				header: "Ganancia Total",
				cell: info => {
					const total_profit = info.row.original.total_profit;
					return <div className="w-full truncate text-left">$ {(Number(total_profit) * 0.01).toFixed(2)}</div>;
				},
				size: Math.floor(tableWidth * 0.12),
			}),
			columnHelper.accessor("series", {
				id: "Ganancia Personal",
				header: "Ganancia Personal",
				cell: info => {
					const personal_profit = info.row.original.series?.reduce((acc, prev) => acc + (prev?.personal_profit || 0), 0);
					return <div className="w-full truncate text-left">$ {(Number(personal_profit) * 0.01).toFixed(2)}</div>;
				},
				size: Math.floor(tableWidth * 0.13),
			}),
			columnHelper.accessor("series", {
				id: "Ganancia Plataforma",
				header: "Ganancia Plataforma",
				cell: info => {
					const total_profit = info.row.original?.series?.reduce((acc, prev) => acc + (prev?.series_profit || 0), 0);
					const personal_profit = info.row.original?.series?.reduce((acc, prev) => acc + (prev?.personal_profit || 0), 0);
					return <div className="w-full truncate text-left">$ {((Number(total_profit) - Number(personal_profit)) * 0.01).toFixed(2)}</div>;
				},
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.display({
				header: "Detalles",
				cell: info => (
					<div className="flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/corporate/partner/" + info.row.original.name + "/" + info.row.original.userid}>
								<BarsPlus className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const refresh = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSelectedOrder(orderBy[0]);
		setSelectedSerie(seriesInitialState[0]);
		setSearchValue("");
		setIsLoading(true);
		dispatch(
			getPartnerConfig({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "fulfilled" && res.payload) {
					const data = res.payload as GetPartnerConfigResponse;
					setResponseData(data);
					setFilteredPartners(data.results);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const handleOrderByFilter = (value: string) => {
		const orderOption = orderBy.find(el => el.display === value) ?? orderBy[0];
		setSelectedOrder(orderOption);
		setIsLoading(true);
		dispatch(
			getPartnerConfig({
				searchby: selectedSerie.value ? "serie_id" : undefined,
				searchvalue: selectedSerie.value || undefined,
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				order: orderOption.value === "profits" ? "desc" : "asc",
				orderby: orderOption.value,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "fulfilled" && res.payload) {
					const data = res.payload as GetPartnerConfigResponse;
					setResponseData(data);
					setFilteredPartners(data.results);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const handleSearchByFilter = (value: string) => {
		const search = searchOptions.find(el => el.searchby === value) ?? searchOptions[0];
		setSelectedSearchBy(search);
	};

	const handleSerieFilter = (value: string) => {
		const serie = seriesList.find(el => el.display === value) ?? seriesInitialState[0];
		setSelectedSerie(serie);
		setIsLoading(true);
		dispatch(
			getPartnerConfig({
				searchby: serie.value ? "serie_id" : undefined,
				searchvalue: serie.value,
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				order: "desc",
				orderby: selectedOrder.value,
			}),
		)
			.then(res => {
				if (res.meta.requestStatus === "fulfilled" && res.payload) {
					const data = res.payload as GetPartnerConfigResponse;
					setResponseData(data);
					setFilteredPartners(data.results);
				}
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">Listado de Partners</h2>
			</div>
			<div className="flex w-full justify-between gap-2 px-6 pt-4">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value as "partner_name" | "series_name")}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy?.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>
				</div>

				<div className="flex gap-5">
					<Select onValueChange={handleSerieFilter} value={selectedSerie.display}>
						<SelectTrigger className="h-8 w-full  max-w-2xl gap-1 whitespace-nowrap">
							<span>Serie:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent className="h-44">
							<SelectGroup className="h-44 scrollbar">
								{seriesList.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>

					<Select onValueChange={handleOrderByFilter} value={selectedOrder.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{orderBy.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={filteredPartners || []}
					pageCount={responseData ? Math.ceil(responseData.totalResults / responseData.pageSize) : 1}
					pagination={pagination}
					loading={isLoading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
				/>
			</div>
		</div>
	);
}

export default PartnerList;
