import {TopViews} from "../../../../components/blocks/Stats";
import {SponsorAdViews} from "../../../../components/blocks/Stats/SponsorAdViews";
import {SponsorAdViewsByAds} from "../../../../components/blocks/Stats/SponsorAdViewsByAds";
import {SponsorGoogleWorldChart} from "../../../../components/blocks/Stats/SponsorGoogleWorldChart";
/* import {SponsorWorldChart} from "../../../../components/blocks/Stats/SponsorWorldChart"; */

export function SponsorDashboard() {
	return (
		<>
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Sponsor Dashboard</h2>
			</div>
			<div className="grid gap-6 px-8 py-6">
				<SponsorAdViews />
				<SponsorAdViewsByAds />
				<TopViews type={"series"} />
				<SponsorGoogleWorldChart />
				{/* <SponsorWorldChart /> */}
			</div>
		</>
	);
}
