import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {dashboardActions, selectedPartnerSeries} from "../../../../_store/features/dashboard/dashboard-slice";
import {addDays, endOfDay, formatISO9075} from "date-fns";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {SerieStatistics} from "../../../partner-pages";

export function PartnerDashboard() {
	const partnerSeries = useAppSelector(selectedPartnerSeries);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			dashboardActions.getPartnerSeriesIncomeAndProfit({
				initial_date: formatISO9075(addDays(Date.now(), -30)),
				final_date: formatISO9075(endOfDay(new Date())),
			}),
		);
		dispatch(dashboardActions.getTopSeries());
	}, [dispatch]);

	useEffect(() => {
		if (partnerSeries?.length === 1) {
			dispatch(dashboardActions.setSelectedSerieId(partnerSeries[0].serieid));
		}
	}, [dispatch, partnerSeries, partnerSeries?.length]);

	if (!partnerSeries)
		return (
			<div className="mx-auto flex h-full max-w-lg flex-col items-center justify-center text-center">
				<p className="mb-4 rounded-full bg-card-foreground p-3 text-sm font-medium text-secondary">
					<InformationCircle />
				</p>
				<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">No hay series.</h1>
				<p className="leading-7 [&:not(:first-child)]:mt-6">
					No perteneces a ninguna serie, por favor contacta con un administrador o espera a ser añadido a una.
				</p>
			</div>
		);

	return <SerieStatistics disableRedirect />;
}
